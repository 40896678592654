import React, { useContext } from "react";

import clsx from "clsx";
import settingsStyle from "assets/jss/components/settingsStyle";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import { updateInitialSetting } from "redux/publicSite/actions";
import Accordion from "atlas/components/Accordion/Accordion";
import accordionStyle from "assets/jss/components/accordionStyle";
import Typography from "@mui/material/Typography";
import { Checkbox } from "@mui/material";
import { SettingsContext } from "contexts/Settings/SettingsContext";

const useStyles = makeStyles(settingsStyle);

const PublicSiteDisplayOptions = () => {
	const classes = useStyles();
	const useAccordionStyles = makeStyles(accordionStyle);
	const accordionClasses = useAccordionStyles();
	const { t } = useTranslation("settings");
	const dispatch = useDispatch();
	const { makePortalPublic, displayVideoTile, displayAttendanceAndVotingRecords, ShowMeetingsWithoutPublishedDocuments, lite } =
		useSelector((state) => state.publicSiteReducer);
	const { communityMeetings } = useContext(SettingsContext);

	const handleToggle = (e, name) => {
		const {
			target: { checked },
		} = e;
		dispatch(updateInitialSetting({ [name]: checked }));
	};

	return (
		<>
			<div className={clsx(classes.section, classes.sectionAccordion)}>
				<Accordion
					classes={accordionClasses}
					className={classes.bold}
					label={t("titles.displayOptions")}
					scrollContentIntoView
					overflow={false}
					dataCy="public-site-display-option"
				>
					<div className={classes.checkbox}>
						<div className={classes.insideCheckbox}>
							<Checkbox
								checked={makePortalPublic}
								onChange={(e) => handleToggle(e, "makePortalPublic")}
								color="primary"
								dataCy="portal-public"
								inputProps={{
									"aria-label": t("checkbox.public"),
									"aria-labelledby": "portalPublic",
								}}
							/>
							<Typography id="portalPublic">{t("checkbox.public")}</Typography>
						</div>
						{!lite && communityMeetings && (
							<div className={classes.insideCheckbox}>
								<Checkbox
									color="primary"
									checked={displayVideoTile}
									onChange={(e) => handleToggle(e, "displayVideoTile")}
									dataCy="video-title"
									inputProps={{
										"aria-label": t("checkbox.videoTitle"),
										"aria-labelledby": "videoTitle",
									}}
								/>
								<Typography id="videoTitle">{t("checkbox.videoTitle")}</Typography>
							</div>
						)}
						{/* because of MAP-5105-AC2 */}
						{/* {!lite && (
							<div className={classes.insideCheckbox}>
								<Checkbox
									color="primary"
									checked={displayAttendanceAndVotingRecords}
									onChange={(e) => handleToggle(e, "displayAttendanceAndVotingRecords")}
									dataCy="voting-records"
									inputProps={{
										"aria-label": t("checkbox.attendanceDisplay"),
										"aria-labelledby": "votingRecords",
									}}
								/>
								<Typography>{t("checkbox.attendanceDisplay")}</Typography>
							</div>
						)} */}
						{communityMeetings && (
							<div className={classes.insideCheckbox}>
								<Checkbox
									color="primary"
									checked={ShowMeetingsWithoutPublishedDocuments}
									onChange={(e) => handleToggle(e, "ShowMeetingsWithoutPublishedDocuments")}
									dataCy="show-meeting-without-documents"
									inputProps={{
										"aria-label": t("checkbox.meetingsDisplay"),
										"aria-labelledby": "showMeetingWithoutDocuments",
									}}
								/>
								<Typography id="showMeetingWithoutDocuments">{t("checkbox.meetingsDisplay")}</Typography>
							</div>
						)}
					</div>
				</Accordion>
			</div>
		</>
	);
};
export default PublicSiteDisplayOptions;
