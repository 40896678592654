export default {
	sessionTimedOutSignIn: "Session timed out - Sign in",
	signIn: "Sign in",
	resetPasswordLink: "Reset Password",
	username: "Username",
	password: "Password",
	showPasswordIcon: "Show password",
	hidePasswordIcon: "Hide password",
	forgotPassword: {
		checkInbox: "Check your inbox!",
		emailAddress: "Email",
		instruction: "Enter your registered email address and we’ll send you instructions to reset your password.",
		sentRequest:
			"If <1>{{email}}</1> is associated with a {{productName}} account, we’ll send an email with instructions to reset your password.",
		sentRequest2: "Don't forget to check you spam folder.",
		tooltip: "Enter a valid email address to proceed",
	},
	resetPassword: {
		enterPassword: "Enter your new password",
		newPassword: "New password",
		confirmNewPassword: "Confirm new password",
		helper8Characters: "Eight characters minimum",
		helperPasswordMatch: "Must match new password",
		tooltip: "Fix the errors above to proceed",
		tokenExpired: "Your password reset link expired or has already been used.",
	},
	buttons: {
		signIn: "Sign in",
		forgotPassword: "Forgot your password?",
		sendInstructions: "Send instructions",
		returnSignIn: "Return to sign in",
		resetPassword: "Reset your password",
	},
	notifications: {
		passwordUpdated: "Password updated successfully",
	},
	errors: {
		wrongUsernamePassword: "We couldn’t find an account matching the username and password you entered. Please try again.",
		emailRequired: "Email is required",
		invalidEmail: "That isn't a valid email address",
		passwordMatch: "Passwords don't match",
		password8Characters: "Must be at least eight characters",
		passwordComplexity: "Must contain a mix of upper and lower case letters, numbers, and special characters",
		passwordContainsPii: "Must not contain your name, user name, or email address",
		passwordCommon: "Must not be a commonly used password",
		required: "{{fieldName}} can't be empty",
	},
};
