import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { setSnackbarOptions } from "../../redux/snackBar/actions";
import Notification from "atlas/components/Notification/Notification";
import { v4 as uuid } from "uuid";

const GetNotifier = ({}) => {
	const { t } = useTranslation("snackbarButton");
	const option = useSelector((state) => state.snackbarReducer.snackbarOption);
	const dispatch = useDispatch();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const dismissSnackbar = (key) => {
		closeSnackbar(key);
		dispatch(setSnackbarOptions({}));
	};
	const onCloseSnackBar = () => {
		dispatch(setSnackbarOptions({}));
	};

	useEffect(() => {
		if (Object.keys(option).length === 0 && option.constructor === Object) {
			return;
		}
		enqueueSnackbar(option.message, {
			key: uuid(),
			preventDuplicate: true,
			onClose: onCloseSnackBar,
			autoHideDuration: option.autoHideDuration,
			content: (key, message) => (
				<Notification
					message={message}
					key={key}
					severity={option.severity}
					iconName={option.iconName}
					action={option.action}
					actionLabel={t("UNDO")}
					dismissAction={() => dismissSnackbar(key)}
				/>
			),
		});
	}, [option]);
	return null;
};

export default GetNotifier;
