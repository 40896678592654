export default {
	actionRecommended: "Recommended Action",
	addConsentSection: "+ Consent",
	addConsentSectionMenu: "Add consent section",
	addDescription: "Add description",
	addHeader: "+ Section",
	addHeaderMenu: "Add section",
	addItem: "+ Item",
	addItemMenu: "Add agenda item",
	addMemberOnlySection: "+ Members only",
	addMemberOnlySectionMenu: "Add members only section",
	addPublicCommentSection: "+ Public comment",
	addPublicCommentSectionMenu: "Add public comment section",
	addRecommendedAction: "+ Action",
	addRecommendedActionMenu: "Add recommended action",
	addSubHeading: "+ Subheading",
	addSubHeadingMenu: "Add subheading",
	addText: "Add Text",
	additionalText: "Additional text",
	agenda: "Agenda",
	boardNotes: "Members notes",
	consentSection: "CONSENT SECTION",
	copyTooltip: "Copy items to another meeting",
	delete: "Delete",
	deleteText: "Delete text",
	duplicate: "Duplicate",
	editBoardNotes: "Edit members briefing notes",
	help: "Help",
	insert: "Insert",
	item: "Item",
	lastSaved: "Last saved",
	meetingFooter: "Meeting Footer",
	meetingHeader: "Meeting Header",
	member: "Member",
	memberOnlySection: "MEMBER ONLY SECTION",
	members: "Members",
	membersBriefingNotes: "Members briefing notes",
	menu: "Menu",
	moveDown: "Move down",
	moveTooltip: "Move items to another meeting",
	moveUp: "Move up",
	motion: "Motion",
	openItem: "Open item",
	outline: "Outline",
	preview: "Preview",
	previewTooltip: "Preview the agenda",
	print: "Print",
	public: "Public",
	publicCommentSection: "PUBLIC COMMENT SECTION",
	publish: "Publish",
	redo: "Redo",
	reject: "Reject to submitter",
	removeDescription: "Remove description",
	saveTooltip: "Saving your changes now",
	saved: "Saved",
	saveError: "Save Error",
	saveErrorTooltip: "There was an error saving your changes.",
	scratchpad: "Scratchpad",
	scratchpadTooltip: "Toggle the scratchpad",
	section: "Section",
	sectionConsent: "Section (Consent)",
	setMemberOnlySection: "Set section as members only",
	setPublicSection: "Set as public section",
	share: "Share",
	subHeading: "Subheading",
	textDescription: "Text (Description)",
	transformSection: "Transform to section",
	transformSectionConsent: "Transform to section (consent)",
	transformSectionPublicComment: "Turn section into public comment",
	transformSubHeading: "Transform to subheading",
	trash: "Delete attachment",
	undo: "Undo",
	unpublish: "Unpublish",
	unpublishTooltip: "Unpublish the agenda",
	uploaded: "Uploaded",
	uploading: "Uploading",
	view: "View",
	viewTooltip: "View outline or scratchpad",
	web: "Web",
	saving: "Saving",
	errorMsg: {
		publish: "Unable to Publish Policy. Please try again",
		commonMsg: "Something went wrong. Please try again",
		publishrescind: "Unable to Publish and Rescind Policy. Please try again",
	},
	successMsg: {
		publish: "Publish successful",
		publishrescind: "Rescinded successful",
	},
	publish: "Publish successful",
	tooltips: {
		addNewMotion: "Add a new motion",
	},
	policyrescind: "Publish & Rescind",
	motions: {
		panel: {
			title: "Motion",
			selectItem: "First select an agenda item on the left and then press the add motion button to add a new motion.",
		},
		snackbar: {
			saved: "Motion data saved",
		},
	},
};
