import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import telemetryAddEvent from "utils/telemetryAddEvent";

import formatTimer from "../../LiveMeeting/utils/formatTimer";

import { updateAppbarTools, updateToolbar } from "../../../redux/app/actions";

import { useWidthUp } from "atlas/utils/useWidth";
import TimerPresetEditDialog from "components/Dialogs/TimerPresetEditDialog";
import TimerCustomDialog from "components/Dialogs/TimerCustomDialog";
import SupportRequestDialog from "components/Dialogs/SupportRequestDialog";
import MeetingPreviewDialog from "components/Dialogs/MeetingPreviewDialog";
import { getTimers, updateTimers } from "../../../redux/liveMeeting/actions";

const AdminLiveMeetingTopBar = (props) => {
	const {
		presenting,
		setPresenting,
		panels = {},
		broadcastIntermissionData = {},
		rollCallClick,
		intermissionClick,
		toggleTableOfContent,
		saveStatus,
		saveTooltip = "",
		uploadingStatus,
		uploadingTooltip = "",
		showSignIn,
	} = props;
	const widthUpSm = useWidthUp("sm");
	const widthUpMd = useWidthUp("md");
	const { t } = useTranslation("meetings");
	const dispatch = useDispatch();

	const meeting = useSelector((state) => state.meetingsReducer.meeting);
	const timers = useSelector((state) => state.liveMeetingReducer.timers);

	const [showEditPresetTimersDialog, setShowEditPresetTimersDialog] = useState(false);
	const [showCustomTimerDialog, setShowCustomTimerDialog] = useState(false);
	const [showMeetingPreviewDialog, setShowMeetingPreviewDialog] = useState(false);
	const [showSupportRequestDialog, setShowSupportRequestDialog] = useState(false);
	const [preview, setPreview] = useState({});

	const openSupportRequestDialog = () => {
		setShowEditPresetTimersDialog(false);
		setShowCustomTimerDialog(false);
		setShowAddToAgendaDialog(false);
		setShowMeetingPreviewDialog(false);
		setShowSupportRequestDialog(true);
	};

	const closeSupportRequestDialog = () => setShowSupportRequestDialog(false);

	const openMeetingPreviewDialog = (agenda, members) => {
		setShowMeetingPreviewDialog(true);
		setPreview({
			agenda,
			members,
		});
	};

	const closeMeetingPreviewDialog = () => {
		setShowMeetingPreviewDialog(false);
		setPreview({});
	};

	const previewMemberMinutes = () => {
		telemetryAddEvent("Live meeting - Preview member minutes");

		openMeetingPreviewDialog(false, true);
	};

	const previewPublicMinutes = () => {
		telemetryAddEvent("Live meeting - Preview public minutes");

		openMeetingPreviewDialog(false, false);
	};

	const getMinutesPreviewOptions = () => [
		{
			label: t("memberMinutes"),
			ariaLabel: t("tooltips.previewMemberMinutes"),
			actionFunction: previewMemberMinutes,
			"data-cy": "previewMemberMinutes",
		},
		{
			label: t("publicMinutes"),
			ariaLabel: t("tooltips.previewPublicMinutes"),
			actionFunction: previewPublicMinutes,
			"data-cy": "previewPublicMinutes",
		},
	];

	const setupVideoClick = () => {};

	const togglePresentingClick = () => {
		setPresenting(!presenting);
	};

	const openTimerClick = () => {};

	const loadTimers = async () => {
		try {
			dispatch(getTimers());
		} catch (err) {
			console.log(err);
		}
	};

	const handleTimer = (options) => {
		const { timer, edit, custom } = options;

		if (timer) {
			dispatch(
				updateAppbarTools({
					appbarTools: {
						timer: {
							timer,
							meetingId: parseInt(meeting.id, 10),
							startTime: custom ? new Date() : undefined,
						},
					},
				}),
			);
			telemetryAddEvent(`Live meeting - Timer used`);
		}

		if (edit) {
			setShowEditPresetTimersDialog(true);
		}

		if (custom) {
			if (timer) {
				let { custom: customTimers = [] } = timers;
				customTimers.push(timer);
				if (customTimers.length > 2) {
					customTimers = customTimers.slice(-2); // Maximum of two custom timers
				}
				dispatch(
					updateTimers({
						...timers,
						custom: customTimers,
					}),
				);
			} else {
				setShowCustomTimerDialog(true);
			}
		}
	};

	// Display tools
	useEffect(() => {
		const { presets, custom } = timers;

		dispatch(
			updateToolbar({
				display: true,
				left: {
					tools: [
						{
							id: "setup-video",
							label: t("buttons.setupVideo"),
							tooltipText: t("tooltips.setupVideo"),
							onClick: setupVideoClick,
							dataCy: "setup-video",
							overflow: !widthUpSm,
							hidden: true,
						},
						{
							id: "toggle-table-of-content",
							icon: "split-pane-white",
							label: widthUpSm ? t("buttons.toggleTableOfContent") : "",
							tooltipText: t("tooltips.toggleTableOfContent"),
							onClick: () => toggleTableOfContent(),
							dataCy: "toggle-table-of-content",
						},
						{
							id: "toggle-presenting",
							icon: "bullhorn",
							label: widthUpSm ? (presenting ? t("buttons.stopPresenting") : t("buttons.startPresenting")) : "",
							tooltipText: presenting ? t("tooltips.stopPresenting") : t("tooltips.startPresenting"),
							onClick: togglePresentingClick,
							dataCy: "toggle-presenting",
						},
						{
							id: "timers",
							icon: "timer",
							label: widthUpSm ? t("buttons.timer") : "",
							tooltipText: t("tooltips.timer"),
							onClick: openTimerClick,
							dataCy: "timers",
							menu: {
								position: "bottom-start",
								options: presets
									.concat(custom)
									.flatMap((timer, index) => {
										if (timer) {
											return {
												key: `timer-${index + 1}`,
												label: formatTimer(timer),
												actionFunction: () => {
													handleTimer({ timer });
												},
												dataCy: `menu-timer-preset-${index + 1}`,
											};
										}
										return [];
									})
									.concat([
										{
											label: t("menu.customTimer"),
											actionFunction: () => {
												handleTimer({ custom: true });
											},
											dataCy: `menu-timer-custom`,
										},
										{
											label: t("menu.editPresetTimers"),
											actionFunction: () => {
												handleTimer({ edit: true });
											},
											dataCy: `menu-timer-preset-edit`,
										},
									]),
							},
						},
						{
							id: "roll-call",
							icon: "group",
							label: t("buttons.rollCall"),
							tooltipText: t("tooltips.rollCall"),
							onClick: rollCallClick,
							dataCy: "roll-call",
							overflow: !widthUpMd,
							highlight: panels.rollCall,
						},
						{
							id: "broadcast-intermission",
							icon: broadcastIntermissionData.intermission ? "play-button" : "pause-button",
							label: broadcastIntermissionData.intermission
								? t("buttons.broadcastReenablePublicBroadcast")
								: t("buttons.broadcastGoToIntermission"),
							tooltipText: broadcastIntermissionData.intermission
								? t("tooltips.broadcastReenablePublicBroadcast")
								: t("tooltips.broadcastGoToIntermission"),
							onClick: intermissionClick,
							disabled: broadcastIntermissionData.disabled,
							hidden: broadcastIntermissionData.hidden,
							dataCy: "broadcast-intermission",
						},
						{
							id: "preview",
							icon: "visible",
							label: widthUpSm ? t("buttons.preview") : "",
							tooltipText: t("tooltips.previewMinutes"),
							onClick: openTimerClick,
							dataCy: "preview",
							menu: {
								position: "bottom-start",
								options: [
									{
										label: t("memberMinutes"),
										ariaLabel: t("tooltips.previewMemberMinutes"),
										actionFunction: previewMemberMinutes,
										"data-cy": "previewMemberMinutes",
									},
									{
										label: t("publicMinutes"),
										ariaLabel: t("tooltips.previewPublicMinutes"),
										actionFunction: previewPublicMinutes,
										hidden: !(meeting && !meeting.closed),
										"data-cy": "previewPublicMinutes",
									},
								],
							},
						},
					],
				},
				right: {
					tools: [
						{
							overflowMenu: true,
						},
						{
							id: "uploading-status",
							label: uploadingStatus,
							tooltipText: uploadingTooltip,
							hidden: !uploadingStatus,
						},
						{
							id: "saving-status",
							label: saveStatus,
							tooltipText: saveTooltip,
							hidden: !saveStatus,
						},
					],
				},
			}),
		);
	}, [presenting, timers, panels, widthUpSm, widthUpMd, broadcastIntermissionData, uploadingStatus, saveStatus]);

	useEffect(() => {
		loadTimers();
	}, []);

	return (
		<>
			{showSupportRequestDialog && <SupportRequestDialog show={showSupportRequestDialog} onClose={closeSupportRequestDialog} />}
			{showMeetingPreviewDialog && (
				<MeetingPreviewDialog
					show={showMeetingPreviewDialog}
					meeting={meeting}
					agenda={preview.agenda}
					members={preview.members}
					onClose={closeMeetingPreviewDialog}
					showSignIn={showSignIn}
					location={location}
					openSupportRequestDialog={openSupportRequestDialog}
				/>
			)}
			{showEditPresetTimersDialog && (
				<TimerPresetEditDialog
					show
					timers={timers}
					onClose={() => setShowEditPresetTimersDialog(false)}
					afterSave={(newPresets) =>
						dispatch(
							updateTimers({
								...timers,
								presets: newPresets,
							}),
						)
					}
				/>
			)}
			{showCustomTimerDialog && (
				<TimerCustomDialog
					show
					onClose={() => setShowCustomTimerDialog(false)}
					afterSave={(timer) =>
						handleTimer({
							timer,
							custom: true,
						})
					}
				/>
			)}
		</>
	);
};

export default AdminLiveMeetingTopBar;
