/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
/* eslint-disable no-param-reassign */
import React, { Component } from "react";
import { connect } from "react-redux";
import request from "superagent";
import { withTranslation } from "react-i18next";
import { matchPath } from "react-router-dom";
import { withRouter } from "utils/router";

import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";

import forEach from "lodash/fp/forEach";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { ACTION_CLOSE_MIDDLE_SMALL } from "atlas/components/Cards/NoticeCard";
import { STATUS_INFO } from "atlas/assets/jss/utils/statusIndicators";
import { BOTTOM } from "atlas/assets/jss/utils/placement";
import withErrorHandling from "components/ErrorHOC";
import UploadErrorDialog from "components/Dialogs/UploadErrorDialog";
import { API_HOST } from "config/env";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { formatDate } from "utils/date";
import telemetryAddEvent from "utils/telemetryAddEvent";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import AgendaTopBar from "./components/AgendaTopBar";
import MeetingEditorV2 from "./MeetingEditorV2";
import { checkAttachmentsAll } from "./functions/utils";

import { mapStateToProps } from "../../redux/app/mapStateToProps";
import { resetPageConfigs, updatePageConfigs, updateNotice, updateBottomNotice } from "redux/app/actions";
import { getAgendaBuilderItem, setChangeSetId } from "redux/agendaBuilder/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { whiteColor } from "atlas/assets/jss/shared";
import UpdateDraftPolicyDialog from "components/Dialogs/UpdateDraftPolicyDialog";

const styles = () => ({
	noEditMessage: {
		flexGrow: "1",
	},
	liveMeetingLink: {
		margin: "-8px auto",
	},
	overlay: {
		position: "absolute",
		top: "0",
		bottom: "0",
		left: "0",
		right: "0",
		backgroundColor: "#000000",
		opacity: "0.4",
		zIndex: "1200",
	},
	switchLabel: {
		...typographyStyle.fieldLabel,
		color: "#fff",
		marginRight: "8px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	switchInstructions: {
		...typographyStyle.fieldLabel,
		color: "#fff",
	},
	switch: {
		"& .MuiSwitch-thumb": {
			backgroundColor: whiteColor,
		},
	},
});

class MeetingEditorContainerV2 extends Component {
	constructor(props) {
		super(props);
		const query = new URLSearchParams(props.location.search);
		const itemGuid = query.get("itemGuid");
		const { params: { id = "0" } = {} } = matchPath({ path: "/meeting/detailsV2/:id", end: true }, props.location.pathname) || {};
		this.state = {
			// fields read onLoad, passed once to MeetingEditor, which then maintains in its own state
			id,
			requestedItemGuid: itemGuid || undefined,
			items: null,
			agendaNumbering: {
				headingStyle: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
				headingMask: ["", "{style}", "({style})", "", "", "", "", "", "", ""],
				itemStyle: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
				itemMask: ["{style})", "({style})", "", "", "", "", "", "", "", ""],
				recommendationStyle: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
				recommendationMask: ["[{style}]", "", "", "", "", "", "", "", "", ""],
			},
			agendaHeader: "",
			agendaFooter: "",
			uploadingStatus: null,
			uploadingTooltip: "",
			badAttachmentsErrors: undefined,
			meetingStatus: {
				readOnly: true,
				showOverlay: false,
			},
			dialogs: {},
		};

		// holds a queue of save requests, so they can be sent sequentially, in the order received.
		this.isSaving = false;
		this.saveQueue = [];

		// local functions
		this.loadItems = this.loadItems.bind(this);
		this.updateMeeting = this.updateMeeting.bind(this);
		this.addFile = this.addFile.bind(this);
		this.sendFiletoAPI = this.sendFiletoAPI.bind(this);
		this.updateTopBar = this.updateTopBar.bind(this);
		//
		// inline File Attachment
		this.uploadQueue = [];
		this.queueFileUploads = this.queueFileUploads.bind(this);
		this.completeFileUpload = this.completeFileUpload.bind(this);
		this.checkItemFileUpload = this.checkItemFileUpload.bind(this);
		this.checkUploadStatus = this.checkUploadStatus.bind(this);
		this.queueFileUploadsV2 = this.queueFileUploadsV2.bind(this);
		this.container = {
			queueFileUploads: this.queueFileUploads,
			checkItemFileUpload: this.checkItemFileUpload,
			addFile: this.addFile,
		};

		// post-mount, the API build MeetingEditor
		// this causes it to be a static instance variable, which prevents re-render from the container, only re-rendering from its internal state changes.
		this.editorComponent = null;
	}

	queueFileUploadsV2(itemGuid, fileUploads, fileData) {
		const { agendaBuilderReducer } = this.props;

		for (let i = 0; i < fileUploads.length; i++) {
			this.uploadQueue.push({
				itemGuid,
				fileGuid: fileUploads[i].guid,
				file: fileUploads[i],
				complete: false,
				error: null,
			});
		}

		// Add change set ID to file data
		const data = JSON.parse(fileData.get("data"));
		data.changeSetId = agendaBuilderReducer?.agenda?.changeSetId || 0;
		fileData.set("data", JSON.stringify(data));

		this.setState({ dialogs: {} });
		this.checkUploadStatus();
		this.sendFiletoAPI(fileData);
	}

	loadItems = (initialLoad) => {
		const { dispatch, t, classes, showSignIn } = this.props;
		const { id, requestedItemGuid } = this.state;
		const { policyEnabled } = this.context;

		// Force a re-render
		delete this.editorComponent;
		this.editorComponent = null;

		this.setState({
			items: null,
		});

		dispatch(getAgendaBuilderItem(id))
			.then((response) => {
				const { items, agendaHeader, agendaFooter, agendaScratchpad, agendaNumbering, meeting, errors, updatedPolicyAttachments } =
					response;
				const meetingDate = formatDate(null, meeting.startTime, meeting.endTime, t("app:at"), t("from"), t("to"), false);
				const meetingStatus = {
					readOnly: false, // meeting.minutesOutOfSync,
					showOverlay: false, // meeting.minutesOutOfSync,
				};
				updatedPolicyAttachments &&
					updatedPolicyAttachments.length > 0 &&
					this.setState((prev) => ({
						dialogs: {
							...prev.dialogs,
							policyUpdate: updatedPolicyAttachments,
						},
					}));

				// request meeting name, to populate top bar
				if (initialLoad) {
					dispatch(
						updatePageConfigs({
							title: t("editAgenda", { meetingName: meeting.name }),
							telemetryPage: "Agenda builder",
						}),
					);
					dispatch(
						updatePageHeader({
							additionalText: [meetingDate],
						}),
					);

					dispatch(
						updateBottomNotice({
							persistentData: {
								fields: [
									{
										name: "hiddenMeetings",
										action: "pop",
										value: parseInt(id, 10),
									},
								],
							},
							update: true,
						}),
					);
				}

				if (meetingStatus.readOnly) {
					dispatch(
						updateNotice({
							status: STATUS_INFO,
							icon: "status-info",
							label: <div className={classes.noEditMessage}>{t("meetings:agendaEdit.minutesOutOfSync")}</div>,
							complexLabel: true,
							placement: BOTTOM,
							actions: [ACTION_CLOSE_MIDDLE_SMALL],
							onDismiss: () => {
								this.setState((prev) => ({
									meetingStatus: {
										...prev.meetingStatus,
										showOverlay: false,
									},
								}));
							},
						}),
					);
				}

				checkAttachmentsAll(items);
				this.editorComponent = (
					<MeetingEditorV2
						id={id}
						items={items}
						dispatch={dispatch}
						requestedItemGuid={requestedItemGuid}
						agendaNumbering={agendaNumbering}
						agendaHeader={agendaHeader}
						agendaFooter={agendaFooter}
						agendaScratchpad={agendaScratchpad}
						updateTopBar={this.updateTopBar}
						updateMeeting={this.updateMeeting}
						linkTopBarToEditorFunctions={this.linkTopBarToEditorFunctions}
						container={this.container}
						isClosedMeeting={meeting.closed}
						disabled={meetingStatus.readOnly}
						queueFileUploads={this.queueFileUploadsV2}
						showSignIn={showSignIn}
					/>
				);

				this.setState({
					meeting,
					items,
					agendaNumbering,
					badAttachmentsErrors: errors ? errors.attachments : undefined,
					meetingStatus,
				});
			})
			.catch((err) => {
				this.setState({ requestError: err });
				console.log(err);
			});
	};

	componentDidMount() {
		const { dispatch } = this.props;
		const { id } = this.state;

		dispatch(resetPageConfigs({}));
		dispatch(
			updatePageConfigs({
				title: "",
				back: { action: this.backToMeetingDetails },
				telemetryPage: "Agenda builder",
				contentMaxWidth: "xl",
			}),
		);

		if (!id) return;

		// request everything else
		this.loadItems(true);

		telemetryAddEvent("Agenda Edit - Load");

		window.addEventListener("beforeunload", this.checkUnsavedChangesBeforeClosing);
		window.addEventListener("beforeunload", this.checkUploadingBeforeClosing);
	}

	componentWillUnmount() {
		const { dispatch } = this.props;

		dispatch(updateNotice({}));

		window.removeEventListener("beforeunload", this.checkUnsavedChangesBeforeClosing);
		window.removeEventListener("beforeunload", this.checkUploadingBeforeClosing);
	}

	backToMeetingDetails = () => {
		const { t, dispatch, agendaBuilderReducer } = this.props;

		const { id } = this.state;
		if (agendaBuilderReducer?.agenda?.saving) {
			if (confirm(t("unsavedChanges"))) {
				this.props.navigate(`/meeting/${id}`);
				return;
			} else return false;
		} else if (this.isUploading) {
			if (confirm(t("unsavedUploading"))) {
				this.props.navigate(`/meeting/${id}`);
				return;
			} else return false;
		} else {
			this.props.navigate(`/meeting/${id}`);
			return;
		}

		// If the back event is canceled, reset the back button, as it gets automatically removed otherwise
		dispatch(
			updatePageConfigs({
				title: "",
				back: { action: this.backToMeetingDetails },
				telemetryPage: "Agenda builder",
				contentMaxWidth: "xl",
			}),
		);
	};

	checkUnsavedChangesBeforeClosing = (event) => {
		if (this.isSaving) {
			event.preventDefault();
			event.returnValue = "";
		}
	};

	checkUploadingBeforeClosing = (event) => {
		if (this.isUploading) {
			event.preventDefault();
			event.returnValue = "";
		}
	};

	linkTopBarToEditorFunctions = (editorFunctions) => {
		// Not using context because the Providers/Consumers are in the wrong order, and this is a one-time link up after MeetingEditor has loaded and bound the functions.
		this.setState({
			editorFunctions,
		});
	};

	updateTopBar(newData) {
		// The top menu needs to know which items are selected in the MeetingEditor, and whether or not undo is available...
		// MeetingEditor will update via this function on its agendaUpdate(), allowing us to update the props to AgendaTopBar
		this.setState({
			topBarData: newData,
		});
	}

	addFile(fieldData, agendaItems) {
		this.sendFiletoAPI(fieldData, agendaItems);
	}

	sendFiletoAPI(fileData, agendaItems, isRetry = false) {
		const { meetingStatus } = this.state;

		if (!meetingStatus.readOnly) {
			const { showSignIn, dispatch } = this.props;
			this.isUploading = true;
			request
				.post(`${API_HOST}/api/documents/uploadattachments`)
				.withCredentials()
				.send(fileData)
				.then((res) => {
					if (res.status === 200) {
						this.setState((prev) => ({
							dialogs: {
								failedUploads: [
									...(prev.dialogs.failedUploads || []).concat(
										(res.body.invalidFiles || []).map((name) => ({
											name,
										})),
									),
								],
							},
						}));

						const { ChangeSetId: changeSetId } = res.body || {};
						dispatch(setChangeSetId(changeSetId));

						this.isUploading = false;
						const itemIdsToUpdate = [];
						forEach((attachment) => {
							this.completeFileUpload(attachment.itemGuid, attachment.guid);
							itemIdsToUpdate.push(attachment.itemGuid);
						}, res.body.Attachments);
					}
				})
				.catch((err) => {
					const fileError = err.status === 400 || err.status === 500 || isRetry;
					this.setState((prev) => ({
						dialogs: {
							failedUploads: [
								...(prev.dialogs.failedUploads || []).concat(
									fileError
										? (err.response.body.invalidFiles || []).map((name) => ({
												name,
										  }))
										: [],
								),
							],
						},
					}));

					this.isUploading = false;
					if (!fileError) {
						showSignIn(
							err,
							() => {
								this.sendFiletoAPI(fileData, agendaItems);
							},
							!isRetry
								? () => {
										// Something went wrong, so wait 5 seconds and try again
										setTimeout(() => {
											this.sendFiletoAPI(fileData, agendaItems, true);
										}, 5000);
								  }
								: undefined,
						);
					}
					this.setState({ ckeUpdating: false });
				});
		}
	}

	updateMeeting(meeting) {
		this.setState({ meeting });
	}

	queueFileUploads(itemGuid, fileUploads) {
		for (let i = 0; i < fileUploads.length; i++) {
			this.uploadQueue.push({
				itemGuid,
				fileGuid: fileUploads[i].guid,
				file: fileUploads[i],
				complete: false,
				error: null,
			});
		}

		this.setState({ dialogs: {} });
		this.checkUploadStatus();
	}

	completeFileUpload(itemGuid, fileGuid, error) {
		for (let i = 0; i < this.uploadQueue.length; i++) {
			if (this.uploadQueue[i].itemGuid === itemGuid && this.uploadQueue[i].fileGuid === fileGuid) {
				this.uploadQueue[i].complete = true;
				this.uploadQueue[i].error = error;
			}
		}
		this.checkUploadStatus();
	}

	checkItemFileUpload(itemGuid, fileGuid) {
		for (let i = 0; i < this.uploadQueue.length; i++) {
			if (this.uploadQueue[i].itemGuid === itemGuid && this.uploadQueue[i].fileGuid === fileGuid) {
				return this.uploadQueue[i].complete;
			}
		}
		return false;
	}

	checkUploadStatus() {
		const { t } = this.props;
		let status = null;
		let tooltip = null;
		for (let i = 0; i < this.uploadQueue.length; i++) {
			if (!this.uploadQueue[i].complete) {
				if (tooltip) {
					tooltip += ` / ${this.uploadQueue[i].file.name}`;
				} else {
					tooltip = this.uploadQueue[i].file.name;
				}
			}
		}
		if (tooltip) {
			status = t("agendaMenu:uploading");
		}
		this.setState({
			uploadingStatus: status,
			uploadingTooltip: tooltip,
		});
	}

	closeDialogs = () => {
		this.setState({ dialogs: {} });
	};

	render() {
		const { showSignIn, classes } = this.props;
		const {
			id,
			meeting,
			items,
			agendaNumbering,
			meetingStatus,
			topBarData = { canUndo: false, canRedo: false },
			saveStatus,
			saveTooltip,
			requestError,
			editorFunctions,
			uploadingStatus,
			uploadingTooltip,
			badAttachmentsErrors,
			dialogs,
		} = this.state;

		if (requestError) {
			const errormsg =
				requestError.response.body && requestError.response.body.Message
					? requestError.response.body.Message
					: `${requestError.status} ${requestError.message}`;
			return (
				<Typography variant="h3" style={{ padding: "24px" }}>
					{errormsg}
				</Typography>
			);
		}

		return (
			<>
				{dialogs.failedUploads && dialogs.failedUploads.length > 0 && (
					<UploadErrorDialog failedUploads={dialogs.failedUploads} onClose={this.closeDialogs} />
				)}
				{dialogs.policyUpdate && dialogs.policyUpdate.length > 0 && (
					<UpdateDraftPolicyDialog
						onClose={this.closeDialogs}
						show={true}
						draftPolicyList={dialogs.policyUpdate}
						uploadPayloadKey="attachmentsMeetingId"
						itemId={id}
					/>
				)}
				<AgendaTopBar
					id={id}
					showSignIn={showSignIn}
					meeting={meeting}
					items={items}
					agendaNumbering={agendaNumbering}
					saveStatus={saveStatus}
					saveTooltip={saveTooltip}
					canUndo={topBarData.canUndo}
					canRedo={topBarData.canRedo}
					editorFunctions={editorFunctions}
					uploadingStatus={uploadingStatus}
					uploadingTooltip={uploadingTooltip}
					reloadItems={() => this.loadItems(false)}
					badAttachmentsErrors={badAttachmentsErrors}
					showUndoRedo={false}
				/>
				<ComponentContainer noPaddingDown="lg" noPaddingTopUp="xs" paddingLeft={true}>
					{this.editorComponent || <CircularProgressIndicator />}
					{meetingStatus.showOverlay && <div className={classes.overlay}></div>}
				</ComponentContainer>
			</>
		);
	}
}
MeetingEditorContainerV2.contextType = SettingsContext;

export default withRouter(
	withStyles(styles)(withTranslation(["meetings", "agendaMenu"])(withErrorHandling(connect(mapStateToProps)(MeetingEditorContainerV2)))),
);
