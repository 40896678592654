import React, { useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { ListItem, ListItemText, Typography } from "@mui/material";

import tableOfContentsStyle from "assets/jss/components/tableOfContentsStyle";
import telemetryAddEvent from "utils/telemetryAddEvent";
import TOCAttachments from "./TOCAttachments";
import TOCPrimaryText from "./TOCPrimaryText";
import useAttachmentsHighlight from "../PdfViewerNew/utils/useAttachmentsHighlight";

const useTableOfContentsStyles = makeStyles(tableOfContentsStyle);

const TOCMeetingItem = (props) => {
	const {
		item: { fields },
		item,
		isMemberOnlySection,
		isConsentSection,
		isPublicCommentSection,
		toggleTableOfContent = () => {},
		isSmallDevice,
		parserOptions,
		isClosedMeeting,
		idPrefix = "outline",
		button,
		selected,
		setActive,
		telemetryPage,
		setDataForAttachmentDocument,
		setDataForOutlineItem,
		isPdfSidebar,
		selectedId,
		openGoalsPopup,
	} = props;
	const dispatch = useDispatch();
	const tableOfContentsClasses = useTableOfContentsStyles({ lineLimit: 2 });
	const [clickedOnAttachment, setClickedOnAttachment, clickedOnAttachmentInSidePanel] = useAttachmentsHighlight(false);

	return (
		!item.deleted && (
			<>
				<ListItem
					id={`${idPrefix}-${item.guid}`}
					data-cy={`${idPrefix}-${item.guid}`}
					className={clsx(tableOfContentsClasses.listItem, {
						[tableOfContentsClasses.closedChanged]: item.original && item.original.closed && !item.fields.Closed.Value,
					})}
					button={button}
					role=""
					component={"li"}
					disableGutters
					divider
					classes={{
						root: clsx("outline-item-secondary", {
							"member-only-section": isMemberOnlySection,
							"consent-section-outline": isConsentSection,
							"public-comment-section-outline-pdfsidebar": isPublicCommentSection && isPdfSidebar,
							"public-comment-section-outline": isPublicCommentSection && !isPdfSidebar,
							"closed-meeting": isClosedMeeting,
							pdfSideBar: isPdfSidebar,
							hyperlinkAttachment: isPdfSidebar && selectedId && selectedId.includes(item.guid) && !clickedOnAttachment,
							[tableOfContentsClasses.selected]: selected && selected.includes(item.guid),
						}),
					}}
					onClick={
						button && isPdfSidebar
							? (e) => {
									setDataForOutlineItem(item);
									setClickedOnAttachment(false);
									dispatch(setActive(item.guid, true));
							  }
							: button
							? () => {
									telemetryAddEvent(`${telemetryPage} - Outline navigation`);

									dispatch(setActive(item.guid, true));
									if (isSmallDevice) {
										toggleTableOfContent();
									}
							  }
							: undefined
					}
					selected={selected && selected.includes(item.guid)}
				>
					<>
						<Typography variant="h5" component="span" className="outline-number">
							{fields.Number.Value}
						</Typography>
						<ListItemText
							className={clsx({
								[tableOfContentsClasses.limitLines]: true,
							})}
							primaryTypographyProps={{ variant: "h5" }}
							primary={
								<TOCPrimaryText
									text={fields.Name.Value}
									parserOptions={parserOptions}
									showTruncationTooltip
									truncationThreshold={isPdfSidebar ? 23 : 50}
									isPdfSidebar={isPdfSidebar}
								></TOCPrimaryText>
							}
						/>
					</>
				</ListItem>

				<TOCAttachments
					attachments={item.attachments}
					item={item}
					itemGuid={item.guid}
					isMemberOnlySection={isMemberOnlySection}
					isConsentSection={isConsentSection}
					isPublicCommentSection={isPublicCommentSection}
					toggleTableOfContent={toggleTableOfContent}
					telemetryPage={telemetryPage}
					setDataForAttachmentDocument={setDataForAttachmentDocument}
					isPdfSidebar={isPdfSidebar}
					setActive={setActive}
					clickedOnAttachmentInSidePanel={clickedOnAttachmentInSidePanel}
					isAgendaItem
					selectedId={selectedId}
					openGoalsPopup={openGoalsPopup}
				/>
			</>
		)
	);
};

export default React.memo(TOCMeetingItem);
