/* eslint-disable no-param-reassign */
import {
	RESET_PAGE_CONFIG,
	UPDATE_PAGE_CONFIG,
	UPDATE_APPBAR_TOOLS,
	UPDATE_PAGE_TABS,
	UPDATE_LEFT_NAV,
	UPDATE_NOTICE,
	UPDATE_BOTTOM_NOTICE,
	UPDATE_SIGNALR,
	UPDATE_CURRENT_TIMESTAMP_ITEM,
	UPDATE_SIGN_IN_DIALOG,
	UPDATE_PENDING_APPROVALS_COUNT,
	UPDATE_REQUESTS_TO_SPEAK_COUNT,
	POLL_LIVE_DATA,
	SET_PROGRESS_IDS,
} from "./types";

export const appReducer = (
	state = {
		appbarTools: {},
		title: "",
		backStack: [],
		showLeftNav: true,
		openLeftNav: false,
		pageHeaderTitle: "",
		overflowHidden: false,
		contentMaxWidth: "lg",
		contentPaper: {
			transparent: false,
		},
		telemetryPage: "",
		tabs: {
			display: false,
		},
		notice: {},
		bottomNotice: {},
		signalR: {},
		signIn: {},
		meetingName: "",
		pendingAgendaItemApprovalsCount: 0,
		requestsToSpeakCount: 0,
		liveDataPolling: {
			intervalId: 0,
			endPollingDate: 0,
		},
		progressIds: {},
	},
	action,
) => {
	switch (action.type) {
		case RESET_PAGE_CONFIG: {
			if (action.payload.updateBackStackOnly) {
				state = {
					...state,
					backStack: action.payload.resetBack ? [] : action.payload.backStack || state.backStack,
				};
			} else {
				state = {
					...state,
					appbarTools: { updated: Date.now() },
					backStack: action.payload.resetBack ? [] : action.payload.backStack || state.backStack,
					showLeftNav: true,
					contentMaxWidth: "lg",
					contentPaper: {
						transparent: false,
					},
					tabs: {
						display: false,
					},
					signIn: {},
					meetingName: "",
				};
			}
			break;
		}
		case UPDATE_PAGE_CONFIG: {
			if (action.payload.back) {
				const back = {
					url: (state.preferedBack ? state.preferedBack.url : undefined) || action.payload.back.url,
					action: (state.preferedBack ? state.preferedBack.action : undefined) || action.payload.back.action,
					preferedTo:
						state.preferedBack && state.preferedBack.url && state.preferedBack.url !== action.payload.back.url
							? action.payload.back.url
							: undefined,
				};

				// Avoid adding duplicate back urls to the stack
				const stackLength = state.backStack.length;
				if (
					stackLength === 0 ||
					(state.backStack[stackLength - 1].url !== back.url &&
						(!state.backStack[stackLength - 1].preferedTo || state.backStack[stackLength - 1].preferedTo !== back.url))
				) {
					state.backStack.push(back);
				}
			}

			state = {
				...state,
				title: action.payload.title,
				backStack: state.backStack,
				preferedBack: action.payload.preferedBack,
				telemetryPage: action.payload.telemetryPage || "",
				overflowHidden: action.payload.overflowHidden ? action.payload.overflowHidden : false,
				contentMaxWidth: action.payload.contentMaxWidth || state.contentMaxWidth,
				contentPaper: action.payload.contentPaper || state.contentPaper,
			};
			break;
		}
		case UPDATE_APPBAR_TOOLS: {
			state = {
				...state,
				appbarTools: action.payload.appbarTools,
			};
			break;
		}
		case UPDATE_PAGE_TABS: {
			state = {
				...state,
				tabs: {
					display: typeof action.payload.display !== "undefined" ? action.payload.display : state.tabs.display,
					selectedTab: typeof action.payload.selectedTab !== "undefined" ? action.payload.selectedTab : state.tabs.selectedTab,
					scrollButtons: typeof action.payload.scrollButtons !== "undefined" ? action.payload.scrollButtons : state.tabs.scrollButtons,
					tabsOptions: action.payload.tabsOptions || state.tabs.tabsOptions,
					onChange: action.payload.onChange || state.tabs.onChange,
				},
			};
			break;
		}
		case UPDATE_LEFT_NAV: {
			state = {
				...state,
				showLeftNav: action.payload.showLeftNav !== undefined ? action.payload.showLeftNav : state.showLeftNav,
				openLeftNav: action.payload.openLeftNav !== undefined ? action.payload.openLeftNav : state.openLeftNav,
				reloadLeftNav: action.payload.reloadLeftNav !== undefined ? action.payload.reloadLeftNav : state.reloadLeftNav,
				meetingName: action.payload.meetingName !== undefined ? action.payload.meetingName : state.meetingName,
			};
			break;
		}
		case UPDATE_NOTICE: {
			state = {
				...state,
				notice: {
					status: action.payload.status || (action.payload.update ? state.notice.status : undefined),
					icon: action.payload.icon || (action.payload.update ? state.notice.icon : undefined),
					iconSize: action.payload.iconSize || (action.payload.update ? state.notice.iconSize : undefined),
					iconColor: action.payload.iconColor || (action.payload.update ? state.notice.iconColor : undefined),
					iconPosition: action.payload.iconPosition || (action.payload.update ? state.notice.iconPosition : undefined),
					label: action.payload.label || (action.payload.update ? state.notice.label : undefined),
					complexLabel: action.payload.complexLabel || (action.payload.update ? state.notice.complexLabel : undefined),
					placement: action.payload.placement || (action.payload.update ? state.notice.placement : undefined),
					actions: action.payload.actions || (action.payload.update ? state.notice.actions : undefined),
					onDismiss: action.payload.onDismiss || (action.payload.update ? state.notice.onDismiss : undefined),
					maxWidth: action.payload.maxWidth || (action.payload.update ? state.notice.maxWidth : undefined),
				},
			};
			break;
		}
		case UPDATE_BOTTOM_NOTICE: {
			// Check if the bottom notice should be updated with this new data, or if the old data has priority
			if (
				(typeof action.payload.updateIf === "function" && action.payload.updateIf(state.bottomNotice.data, action.payload.data)) ||
				typeof action.payload.updateIf !== "function"
			) {
				// Update the persistent data
				const { persistentData = {} } = state.bottomNotice;
				const { fields = [] } = action.payload.persistentData || {};
				fields.forEach((field) => {
					if (field.action === "push") {
						// Add a value to an array field
						persistentData[field.name] = persistentData[field.name] || [];
						persistentData[field.name].push(field.value);
					} else if (field.action === "pop") {
						// Remove a value from an array field
						const index = (persistentData[field.name] || []).findIndex((current) => current === field.value);
						if (index >= 0) {
							persistentData[field.name].splice(index, 1);
						}
					}
				});

				state = {
					...state,
					bottomNotice: {
						show: action.payload.show || (action.payload.update ? state.bottomNotice.show : undefined),
						icon: action.payload.icon || (action.payload.update ? state.bottomNotice.icon : undefined),
						label: action.payload.label || (action.payload.update ? state.bottomNotice.label : undefined),
						preText: action.payload.preText || (action.payload.update ? state.bottomNotice.preText : undefined),
						postText: action.payload.postText || (action.payload.update ? state.bottomNotice.postText : undefined),
						action: action.payload.action || (action.payload.update ? state.bottomNotice.action : undefined),
						actionAfterNavigate:
							action.payload.actionAfterNavigate || (action.payload.update ? state.bottomNotice.actionAfterNavigate : undefined),
						canClose:
							// eslint-disable-next-line no-nested-ternary
							typeof action.payload.canClose === "boolean"
								? action.payload.canClose
								: action.payload.update
									? state.bottomNotice.canClose
									: undefined,
						onClose: action.payload.onClose || (action.payload.update ? state.bottomNotice.onClose : undefined),
						data: action.payload.data || (action.payload.update ? state.bottomNotice.data : undefined),
						persistentData: { ...persistentData },
						updateIf: action.payload.updateIf,
					},
				};
			}
			break;
		}
		case UPDATE_SIGNALR: {
			// Stop the previous client / Start the new client
			if (action.payload.client) {
				if (state.signalR.client) {
					state.signalR.client.stop();
				}
				action.payload.client.start().then(action.payload.done || (() => {}));
			}

			// Copy new handlers onto original object to maintain the same reference in the SignalRClient
			const handler = state.signalR.handler || {};
			if (action.payload.handler) {
				Object.keys(action.payload.handler).forEach((key) => {
					if (!handler[key]) {
						handler[key] = action.payload.handler[key]; // A new hub
					} else {
						Object.keys(action.payload.handler[key]).forEach((subKey) => {
							handler[key][subKey] = action.payload.handler[key][subKey] || undefined; // Add/update/remove the handler methods
						});
					}
				});
			}

			state = {
				...state,
				signalR: {
					client: action.payload.client || state.signalR.client,
					handler,
				},
			};
			break;
		}
		case UPDATE_CURRENT_TIMESTAMP_ITEM: {
			state = {
				...state,
				currentTimestampItem: action.payload,
			};
			break;
		}

		case UPDATE_SIGN_IN_DIALOG:
			const { show, after } = action.payload || {};
			const { show: currentShow, after: currentAfter = [] } = state.signIn || {};

			// If the dialog was previously showing, add the new after function to the after function array
			state = {
				...state,
				signIn: show
					? {
							show: true,
							after: currentShow ? [...new Set(currentAfter.concat[after])] : [after],
						}
					: {},
			};
			break;

		case UPDATE_PENDING_APPROVALS_COUNT: {
			const { count = 0 } = action.payload || {};
			const { pendingAgendaItemApprovalsCount = 0 } = state;

			// Only update the state if the live data changed
			if (count !== pendingAgendaItemApprovalsCount) {
				state = {
					...state,
					pendingAgendaItemApprovalsCount: count,
				};
			}
			break;
		}

		case UPDATE_REQUESTS_TO_SPEAK_COUNT: {
			const { count = 0 } = action.payload || {};
			const { requestsToSpeakCount = 0 } = state;

			// Only update the state if the live data changed
			if (count !== requestsToSpeakCount) {
				state = {
					...state,
					requestsToSpeakCount: count,
				};
			}
			break;
		}

		case POLL_LIVE_DATA: {
			const { outstandingAgendaItemApprovalsCount = 0, submittedRequestToSpeakItemsCount = 0 } = action.payload || {};
			const { pendingAgendaItemApprovalsCount = 0, requestsToSpeakCount = 0 } = state;

			// Only update the state if the live data changed
			if (
				outstandingAgendaItemApprovalsCount !== pendingAgendaItemApprovalsCount ||
				submittedRequestToSpeakItemsCount !== requestsToSpeakCount
			) {
				state = {
					...state,
					pendingAgendaItemApprovalsCount: outstandingAgendaItemApprovalsCount,
					requestsToSpeakCount: submittedRequestToSpeakItemsCount,
				};
			}
			break;
		}

		case SET_PROGRESS_IDS: {
			const { progressGuid, resultId, type } = action.payload || {};
			const { progressIds = {} } = state;

			// Only update the state if the progressGuid or resultId changed
			if (progressIds.progressGuid !== progressGuid || progressIds.resultId !== resultId || progressIds.type !== type) {
				state = {
					...state,
					progressIds: {
						progressGuid,
						resultId,
						type,
					},
				};
			}
			break;
		}

		default:
			break;
	}

	return state;
};

export default appReducer;
