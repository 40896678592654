/* eslint-disable no-param-reassign */
import findIndex from "lodash/fp/findIndex";
import includes from "lodash/fp/includes";
import uniq from "lodash/fp/uniq";
import forEach from "lodash/fp/forEach";

import getNumbering from "utils/numbering";
import AgendaItemTypesEnum from "utils/enums/AgendaItemTypes";
import { getLinkFileHref } from "../../../components/Editor/plugins/InlineFile/inlineFileUtils";

import { moveSectionItemCustomNumbering, moveAgendaItemCustomNumbering } from "utils/customNumberingConversions";

export function clearUndoPointer(agenda) {
	if (agenda.undoPointer > -1) {
		agenda.undoStack.slice(agenda.undoPointer + 1);
		agenda.redoStack.slice(agenda.undoPointer + 1);
		agenda.undoPointer = -1;
	}
}

export function setMeetingEditorData(name, value) {
	this[name] = value;
}

export function getAgenda() {
	// there are a handful of places where we want to get access to MeetingEditor's state without writing a new utils function
	// this gives us a way to pass MeetingEditor via context instead of props.
	return this;
}

export function findItemByID(guid, arr) {
	if (!arr || !arr.length) return null; // if no data, we're done.

	// if the guid is in the current level, return it.
	const foundIndex = findIndex((item) => item.guid === guid, arr);
	if (foundIndex > -1) {
		return arr[foundIndex];
	}

	return null;
}

export function updateClosedAttachments(item) {
	if (item) {
		if (item.fields.Closed.Value) {
			if (item.fields.Name.Value) {
				item.fields.Name.Value = item.fields.Name.Value.replace('class="inlineFile"', 'class="inlineFile closed"');
			}
			if (item.fields.Text.Value) {
				item.fields.Text.Value = item.fields.Text.Value.replace('class="inlineFile"', 'class="inlineFile closed"');
			}
		}
	}
}

export function reOrderItemArray(array, agenda, customNumbering = {}) {
	/*
	This function is called whenever items in the agenda are re-ordered in any way.
	It re-numbers the items, re-calculates relationship attributes and indents, and makes the IDstoUpdate array unique.
	Doing it all here lets us keep the rest of the code simple:
	We just set the order of items, and this function handles the rest.
	*/
	const undeletedArray = array.filter((item) => !item.deleted, array);

	const { agendaNumbering } = agenda.props;

	let currentSubheadingNumberOrder = 1;
	let currentItemNumberOrder = 1;
	let nextSectionOrder = 1;
	let nextItemOrder = 1;
	const last = {
		heading: null,
		headingNumber: "",
		subheading: null,
		subheadingNumber: "",
		item: null,
		itemNumber: "",
		recommendation: null,
		closed: false,
	};

	for (let i = 0; i < undeletedArray.length; i += 1) {
		const currentItem = undeletedArray[i];
		currentItem.moveDownDisabled = false; // it will be set on correct items after the full array is processed

		const nextItem = undeletedArray[i + 1];
		currentItem.isLastItem =
			nextItem === undefined || (nextItem.itemType === AgendaItemTypesEnum().HEADING.value && !nextItem.attributes.relationshipGuid);

		if (currentItem.itemType === AgendaItemTypesEnum().HEADING.value && !currentItem.attributes.relationshipGuid) {
			// section headings get the next section number. New section resets item numbering.
			if (currentItem.fields.Order.Value !== nextSectionOrder) {
				currentItem.fields.Order.Value = nextSectionOrder;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}

			currentSubheadingNumberOrder = 1;
			currentItemNumberOrder = 1;
			nextSectionOrder += 1;
			nextItemOrder = 1;

			// set relationship / section data
			last.heading = currentItem.guid;

			last.subheading = null;
			last.subheadingNumber = "";
			last.item = null;
			last.itemNumber = "";
			last.recommendation = null;
			last.closed = currentItem.fields.Closed.Value;
			last.consent = currentItem.fields.Consent.Value;
			last.publicComment = currentItem.fields.PublicComment.Value;

			if (i === 0) {
				currentItem.moveUpDisabled = true;
			} else {
				currentItem.moveUpDisabled = false;
			}

			last.headingNumber = getNumbering(
				agendaNumbering,
				currentItem.itemType,
				parseInt(currentItem.fields.Indent.Value, 10),
				parseInt(currentItem.fields.Order.Value, 10),
			);

			if (customNumbering) {
				currentItem.number = moveSectionItemCustomNumbering(customNumbering, last.headingNumber);
			} else {
				currentItem.number = last.headingNumber;
			}
			if (currentItem.fields.Number.Value !== currentItem.number) {
				currentItem.fields.Number.Value = currentItem.number;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
		} else if (currentItem.itemType === AgendaItemTypesEnum().HEADING.value) {
			if (currentItem.fields.Order.Value !== nextItemOrder) {
				currentItem.fields.Order.Value = nextItemOrder;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
			nextItemOrder += 1;
			if (currentItem.fields.Indent.Value !== 1) {
				currentItem.fields.Indent.Value = 1;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
			if (currentItem.fields.Closed.Value !== last.closed) {
				currentItem.fields.Closed.Value = last.closed;
				updateClosedAttachments(currentItem);
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
			if (currentItem.fields.Consent.Value !== last.consent) {
				currentItem.fields.Consent.Value = last.consent;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
			if (currentItem.fields.PublicComment.Value !== last.publicComment) {
				currentItem.fields.PublicComment.Value = last.publicComment;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}

			// set relationship data
			if (currentItem.attributes.relationshipGuid !== last.heading) {
				currentItem.attributes.relationshipGuid = last.heading;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}

			last.subheading = currentItem.guid;
			last.item = null;
			last.itemNumber = "";
			last.recommendation = null;

			if (i === 1) {
				currentItem.moveUpDisabled = true;
			} else {
				currentItem.moveUpDisabled = false;
			}

			if (agendaNumbering.numberSubheadings) {
				last.subheadingNumber = getNumbering(
					agendaNumbering,
					currentItem.itemType,
					parseInt(currentItem.fields.Indent.Value, 10),
					currentSubheadingNumberOrder++,
				);
				currentItem.number = last.headingNumber + last.subheadingNumber;
				if (currentItem.fields.Number.Value !== currentItem.number) {
					currentItem.fields.Number.Value = currentItem.number;
					agenda.itemIdsToUpdate.push(currentItem.guid);
				}
			} else if (currentItem.fields.Number.Value !== "") {
				currentItem.fields.Number.Value = "";
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
		} else if (currentItem.itemType === AgendaItemTypesEnum().RECOMMENDATION.value) {
			if (currentItem.fields.Order.Value !== nextItemOrder) {
				currentItem.fields.Order.Value = nextItemOrder;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
			nextItemOrder += 1;
			if (currentItem.fields.Closed.Value !== last.closed) {
				currentItem.fields.Closed.Value = last.closed;
				updateClosedAttachments(currentItem);
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
			if (currentItem.fields.Consent.Value !== last.consent) {
				currentItem.fields.Consent.Value = last.consent;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
			if (currentItem.fields.PublicComment.Value !== last.publicComment) {
				currentItem.fields.PublicComment.Value = last.publicComment;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}

			let itemIndent = 0;
			if (last.subheading) itemIndent = 1;
			if (currentItem.fields.Indent.Value !== itemIndent) {
				currentItem.fields.Indent.Value = itemIndent;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}

			// set relationship data
			const newRelationshipGuid = last.item || last.subheading || last.heading;
			if (currentItem.attributes.relationshipGuid !== newRelationshipGuid) {
				currentItem.attributes.relationshipGuid = newRelationshipGuid;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
			last.recommendation = currentItem.guid;

			if (i === 1) {
				currentItem.moveUpDisabled = true;
			} else {
				currentItem.moveUpDisabled = false;
			}
		} else {
			// items get the next item number.
			if (currentItem.fields.Order.Value !== nextItemOrder) {
				currentItem.fields.Order.Value = nextItemOrder;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
			nextItemOrder += 1;
			if (currentItem.fields.Closed.Value !== last.closed) {
				currentItem.fields.Closed.Value = last.closed;
				updateClosedAttachments(currentItem);
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
			if (currentItem.fields.Consent.Value !== last.consent) {
				currentItem.fields.Consent.Value = last.consent;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
			if (currentItem.fields.PublicComment.Value !== last.publicComment) {
				currentItem.fields.PublicComment.Value = last.publicComment;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}

			let itemIndent = 0;
			if (last.subheading) itemIndent = 1;
			if (currentItem.fields.Indent.Value !== itemIndent) {
				currentItem.fields.Indent.Value = itemIndent;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}

			// set relationship data
			const newRelationshipGuid = last.subheading || last.heading;
			if (currentItem.attributes.relationshipGuid !== newRelationshipGuid) {
				currentItem.attributes.relationshipGuid = newRelationshipGuid;
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
			last.item = currentItem.guid;
			last.recommendation = null;

			if (i === 1) {
				currentItem.moveUpDisabled = true;
			} else {
				currentItem.moveUpDisabled = false;
			}

			if (agendaNumbering.numberItems) {
				last.itemNumber = getNumbering(
					agendaNumbering,
					currentItem.itemType,
					parseInt(currentItem.fields.Indent.Value, 10),
					currentItemNumberOrder++,
				);
				if (customNumbering) {
					currentItem.number = moveAgendaItemCustomNumbering(customNumbering, last.headingNumber + last.subheadingNumber + last.itemNumber);
				} else {
					currentItem.number = last.headingNumber + last.subheadingNumber + last.itemNumber;
				}
				if (currentItem.fields.Number.Value !== currentItem.number) {
					currentItem.fields.Number.Value = currentItem.number;
					agenda.itemIdsToUpdate.push(currentItem.guid);
				}
			} else if (currentItem.fields.Number.Value !== "") {
				currentItem.fields.Number.Value = "";
				agenda.itemIdsToUpdate.push(currentItem.guid);
			}
		}
	}

	agenda.itemIdsToUpdate = uniq(agenda.itemIdsToUpdate);
	agenda.isDirty = agenda.isDirty || agenda.itemIdsToUpdate.length > 0;

	// disable moveDown menus for last of each item type (if it exists, and if it is in the last section/subsection/item)
	const lastHeading = findItemByID(last.heading, undeletedArray);
	const lastSubheading = findItemByID(last.subheading, undeletedArray);
	const lastItem = findItemByID(last.item, undeletedArray);
	const lastRecommendation = findItemByID(last.recommendation, undeletedArray);

	if (lastHeading) lastHeading.moveDownDisabled = true;
	if (lastSubheading && lastSubheading.attributes.relationshipGuid === lastHeading.guid) lastSubheading.moveDownDisabled = true;
	if (lastItem && lastItem.attributes.relationshipGuid === (lastSubheading ? lastSubheading.guid : lastHeading.guid)) {
		lastItem.moveDownDisabled = true;
		if (lastRecommendation && lastRecommendation.attributes.relationshipGuid === lastItem.guid) lastRecommendation.moveDownDisabled = true;
	}
	if (lastRecommendation && lastRecommendation.attributes.relationshipGuid === lastHeading.guid) lastRecommendation.moveDownDisabled = true;

	// Ensure that a re-render is triggered
	if (agenda?.setState) {
		agenda.setState((state) => ({ ...state }));
	}
}

export function getActiveObject(agenda, items, guid) {
	const { active } = agenda.state;

	if (guid === "toc-header" || guid === "toc-footer" || guid === "scratchPad") {
		// special cases for header/footer - no related item exists
		return {
			selected: guid,
			text: false,
			boardNotes: false,
		};
	}

	const newActive = {};
	let itemId = guid;

	if (guid.indexOf("-text") > 0) {
		// text field - we find the containing item, but set the text as active
		itemId = guid.substr(0, 36);
		newActive.text = true;
	} else {
		newActive.text = false;
	}

	if (guid.indexOf("-board-notes") > 0) {
		// board notes field - we find the containing item, but set the board notes as active
		itemId = guid.substr(0, 36);
		newActive.boardNotes = true;
	} else {
		newActive.boardNotes = false;
	}

	const item = findItemByID(itemId, items);
	if (!item) return active; // no change, return original object

	newActive.selected = guid;
	newActive.itemType = item.itemType;

	return newActive;
}

export function updateAgenda(agenda, stateUpdates, activeId, cancelNextRender, cancelDirty) {
	clearUndoPointer(agenda);
	if (cancelNextRender) {
		if (typeof cancelNextRender === "object") {
			if (cancelNextRender.agenda === true) {
				agenda.cancelUpdateAgenda = true;
			}
		} else if (typeof cancelNextRender === "boolean") {
			agenda.cancelRender = true;
		}
	}
	if (Object.keys(stateUpdates).length > 0 && !cancelDirty) {
		agenda.isDirty = true;
	}

	let newActive;

	agenda.setState(() => {
		const newState = { ...stateUpdates };
		checkAttachmentsAll(newState.items);
		if (activeId) {
			const latestItems = stateUpdates.items ? stateUpdates.items : agenda.state.items;
			newActive = getActiveObject(agenda, latestItems, activeId);
			newState.active = newActive;
		}

		// sync with top menu bar, to enable/disable menu items as appropriate.
		agenda.props.updateTopBar({
			active: newActive || agenda.state.active,
			canUndo: agenda.undoStack.length > 0,
			canRedo: agenda.redoStack.length && agenda.undoPointer > -1,
		});

		return newState;
	});
}

export function clearActiveItem(agenda) {
	clearUndoPointer(agenda);

	agenda.setState(() => {
		const newState = {};
		const newActive = {
			selected: null,
			text: false,
			boardNotes: false,
		};
		newState.active = newActive;

		// sync with top menu bar, to enable/disable menu items as appropriate.
		agenda.props.updateTopBar({
			active: newActive || agenda.state.active,
			canUndo: agenda.undoStack.length > 0,
			canRedo: agenda.redoStack.length && agenda.undoPointer > -1,
		});

		return newState;
	});
}

export function setActiveItem(guid, scrollToEditor) {
	const { navigate, location } = this.props;
	const { active } = this.state;

	if (!guid) {
		clearActiveItem(this);
	}

	if (location.search) {
		navigate({ search: undefined });
	}

	// cancel if we are already set as desired
	if (active.text) {
		if (guid === `${active.selected}-text`) return;
	} else if (guid === active.selected) return;

	updateAgenda(this, {}, guid);

	if (guid && guid.endsWith("-board-notes")) {
		this.setState(
			{
				showNotes: true,
			},
			scrollToEditor
				? () => {
						// Scroll to the notes
						const notesButton = document.getElementById(`edit-board-notes-${guid.substr(0, 36)}`);
						if (notesButton) {
							notesButton.scrollIntoView();
						}
				  }
				: undefined,
		);
	}
}

export function isHeading(item) {
	return item.itemType === AgendaItemTypesEnum().HEADING.value && !item.attributes.relationshipGuid;
}

export function isSubHeading(item) {
	return item.itemType === AgendaItemTypesEnum().HEADING.value && Boolean(item.attributes.relationshipGuid);
}

export function getSection(guid, items) {
	// guid must be the section heading guid - this returns an array of items in that section
	const heading = findItemByID(guid, items);
	const sectionItems = [heading];
	const headingIndex = findIndex((item) => item.guid === guid, items);

	let nextHeadingIndex = headingIndex + 1;
	while (nextHeadingIndex < items.length) {
		const item = items[nextHeadingIndex];
		if (!isHeading(item)) {
			sectionItems.push(item);
			nextHeadingIndex += 1;
		} else {
			break;
		}
	}

	return sectionItems;
}

export function getSectionLength(guid, items) {
	// guid can be any part of the section, this finds the section heading and returns the section length.
	let index = findIndex((item) => item.guid === guid, items);
	let item = items[index];

	while (!isHeading(item)) {
		index -= 1;
		item = items[index];
	}

	let nextHeadingIndex = index + 1;
	while (nextHeadingIndex < items.length) {
		item = items[nextHeadingIndex];
		if (!isHeading(item)) {
			nextHeadingIndex += 1;
		} else {
			break;
		}
	}

	return nextHeadingIndex - index;
}

export function getSubsection(guid, items) {
	// guid must be the Subsection heading guid - this returns an array of items in that subsection
	const heading = findItemByID(guid, items);
	const sectionItems = [heading];
	const headingIndex = findIndex((item) => item.guid === guid, items);

	let nextHeadingIndex = headingIndex + 1;
	const includedGuids = [];
	while (nextHeadingIndex < items.length) {
		const item = items[nextHeadingIndex];
		if (item.attributes.relationshipGuid === guid || includes(item.attributes.relationshipGuid, includedGuids)) {
			sectionItems.push(item);
			includedGuids.push(item.guid);
			nextHeadingIndex += 1;
		} else {
			break;
		}
	}

	return sectionItems;
}

export function getSubsectionLength(guid, items) {
	// guid can be any part of the section, this finds the subsection heading and returns the subsection length.
	let index = findIndex((item) => item.guid === guid, items);
	let item = items[index];

	while (!isSubHeading(item)) {
		index -= 1;
		item = items[index];
	}

	let nextHeadingIndex = index + 1;
	const includedGuids = [];
	while (nextHeadingIndex < items.length) {
		item = items[nextHeadingIndex];
		if (item.attributes.relationshipGuid === guid || includes(item.attributes.relationshipGuid, includedGuids)) {
			includedGuids.push(item.guid);
			nextHeadingIndex += 1;
		} else {
			break;
		}
	}

	return nextHeadingIndex - index;
}

export function isValidSubsectionLocation(item, items) {
	if (item.itemType === AgendaItemTypesEnum().HEADING.value) return true; // can always drop right before a heading or subheading.

	// can drop before section-level items and recommendations, not subsection-level ones. (Don't split up a subsection)
	const parent = findItemByID(item.attributes.relationshipGuid, items);
	return isHeading(parent);
}

export function getDistanceUpToValidSubsectionLocation(guid, items) {
	// guid is the subheading we are moving - we walk backwards until we find a good place to drop it.
	const subheadingIndex = findIndex((item) => item.guid === guid, items);
	let index = subheadingIndex - 1;
	let item = items[index];
	while (!isValidSubsectionLocation(item, items)) {
		index -= 1;
		item = items[index];
	}

	return subheadingIndex - index;
}

export function getItem(guid, items) {
	// guid must be the Item guid - this returns an array of the item and its recommended actions
	const item = findItemByID(guid, items);
	const itemObjects = [item];
	const itemIndex = findIndex((item) => item.guid === guid, items);

	let nextItemIndex = itemIndex + 1;
	while (nextItemIndex < items.length) {
		const item = items[nextItemIndex];
		if (item.attributes.relationshipGuid === guid) {
			itemObjects.push(item);
			nextItemIndex += 1;
		} else {
			break;
		}
	}

	return itemObjects;
}

export function getItemLength(guid, items) {
	// guid can be any item or recommendation - this finds parent item and returns the length of it and its recommendation actions.
	let index = findIndex((item) => item.guid === guid, items);
	let item = items[index];

	while (!item.itemType === AgendaItemTypesEnum().ITEM.value) {
		index -= 1;
		item = items[index];
	}

	let nextItemIndex = index + 1;
	while (nextItemIndex < items.length) {
		item = items[nextItemIndex];
		if (item.attributes.relationshipGuid === guid) {
			nextItemIndex += 1;
		} else {
			break;
		}
	}

	return nextItemIndex - index;
}

export function isValidItemLocation(item) {
	// items can move anywhere except when they split another item from its recommended actions
	return item.itemType !== AgendaItemTypesEnum().RECOMMENDATION.value;
}

export function getDistanceUpToValidItemLocation(guid, items) {
	// guid is the item we are moving - we walk backwards until we find a good place to drop it.
	const itemIndex = findIndex((item) => item.guid === guid, items);
	let index = itemIndex - 1;
	let item = items[index];
	while (!isValidItemLocation(item, items)) {
		index -= 1;
		item = items[index];
	}

	return itemIndex - index;
}
//
// Get first attachment with guid
export function findAttachment(guid, items) {
	let attachment = null;
	for (let i = 0; i < items.length; i++) {
		for (let a = 0; a < items[i].attachments.length; a++) {
			if (items[i].attachments[a].guid === guid) {
				attachment = items[i].attachments[a];
				a = items[i].attachments.length - 1;
				i = items.length - 1;
			}
		}
	}
	return attachment;
}
//
// Get all attachments with guid
export function findAttachmentAll(guid, items) {
	const attachments = [];
	for (let i = 0; i < items.length; i++) {
		for (let a = 0; a < items[i].attachments.length; a++) {
			if (items[i].attachments[a].guid === guid) {
				attachments.push(items[i].attachments[a]);
			}
		}
	}
	return attachments;
}
//
// Check to see if the attachments have changed on an item
// Return true if the status of the attachments has change, either deleted or added
// otherwise return false
//
export function checkAttachments(item) {
	const { attachments } = item;
	let changed = false;
	let nameIndex = -1;
	let textIndex = -1;
	if (item.fields.Name.Value) {
		nameIndex = item.fields.Name.Value.indexOf("inlineFile");
	}
	if (item.fields.Text.Value) {
		textIndex = item.fields.Text.Value.indexOf("inlineFile");
	}
	//
	// No links found and no attachments, so we are done
	//
	if (attachments === undefined || (nameIndex === -1 && textIndex === -1 && attachments.length === 0)) {
		return changed;
	}
	//
	// Set attachments as removed
	//
	forEach((attachment) => {
		attachment.oremoved = attachment.removed;
		attachment.removed = true;
		if (attachment.oremoved !== attachment.removed) {
			changed = true;
		}
	}, attachments);
	//
	// if no links found, since attachments have already been removed, so we are done
	if (nameIndex === -1 && textIndex === -1) {
		return changed;
	}
	//
	// Find the links and set the associated attachment to not removed or create a new one
	//
	// const parser = new DOMParser();
	if (nameIndex !== -1) {
		checkLinks(item.fields.Name.Value, item);
	}
	if (textIndex !== -1) {
		checkLinks(item.fields.Text.Value, item);
	}
	//
	// check to see if an attachment has been removed or added
	//
	for (let idx = 0; idx < attachments.length; idx++) {
		if (attachments[idx].oremoved !== attachments[idx].removed) {
			changed = true;
		}
		delete attachments[idx].oremoved;
	}
	return changed;
}

//
// Find all the inlineFile anchors in the item.
// If the link references an existing attachment for the item, then make sure attachment is not removed
// If the link does not reference an existing attachment for the item, then find the first reference in the meeting and copy
function checkLinks(html, item) {
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, "text/html");
	const links = doc.querySelectorAll("a.inlineFile");
	forEach((link) => {
		const href = link.getAttribute("href");
		const name = link.getAttribute("name");
		const classes = link.getAttribute("class");
		let closed = false;
		if (classes.indexOf("closed") !== -1) {
			closed = true;
		}
		const hrefInfo = getLinkFileHref(href);
		const idx = findIndex((att) => att.guid === hrefInfo.guid, item.attachments);
		if (idx === -1) {
			const newItem = {
				name,
				guid: hrefInfo.guid,
				url: hrefInfo.url,
				itemGuid: item.guid,
				include: true,
				deleted: false,
				removed: false,
			};
			item.attachments.push(newItem);
		} else {
			item.attachments[idx].removed = false;
			item.attachments[idx].closed = closed;
		}
	}, links);
}
//
// check to see if there is an active link for all attachments on all items.
// If not, then remove, otherwise don't remove
// checkAttachments returns true if the status of attachments has changed, either added or removed
// If status has not changed, then it return false;
//
export function checkAttachmentsAll(items) {
	let changed = false;
	forEach((item) => {
		if (checkAttachments(item)) {
			changed = true;
		}
	}, items);

	return changed;
}

export function focusNextElement(guid) {
	// destructuring doesn't work here for some reasons
	// eslint-disable-next-line prefer-destructuring
	const items = this.props.items;
	this.setState({ active: { itemType: undefined, selected: "", text: false } });
	let elem;
	if (guid === "toc-header") {
		elem = document.getElementById(`agenda-${items[0].guid}`);
		elem.getElementsByClassName("heading-container")[0].focus();
	} else if (guid === "toc-footer") {
		elem = document.getElementById(`agenda-toc-header`);
		elem.querySelector('[role = "button"]').focus();
	} else {
		let nextItem;
		if (guid.indexOf("-text") !== -1) {
			// We are tabbing out of a description
			nextItem = items[items.findIndex((item) => item.guid === guid.substring(0, guid.indexOf("-text"))) + 1];

			elem = document.getElementById(`agenda-${nextItem.guid}`);
		} else {
			// We are tabbing out of a header, an item or a recommendation
			nextItem = items[items.findIndex((item) => item.guid === guid) + 1];
			elem = document.getElementById(`agenda-${nextItem.guid}`);
		}
		elem.querySelector('[role = "button"]').focus();
	}
}

export function validateLinkHref(href) {
	if (href && !href.toLowerCase().startsWith("http") && !href.toLowerCase().startsWith("mailto:")) {
		if (href.substr(0, 2) === "//") {
			href = `http:${href}`;
		} else if (href.substr(0, 1) === "/") {
			href = `http:/${href}`;
		} else {
			href = `http://${href}`;
		}
	}
	return href;
}
//
// callback parm is optional.  It will be called after the container saveAgenda is complete
// callback object: { success:  data: }
//
export function saveAgenda(callback, forceSave) {
	if (this.isDirty) {
		const { agendaFooter, agendaScratchpad, agendaHeader, items } = this.state;

		// pass entire agenda back to container - it figure out what needs to go to the API and handles it.
		this.props.saveAgenda({
			items,
			itemIdsToUpdate: this.itemIdsToUpdate,
			itemIdsToDelete: this.itemIdsToDelete,
			agendaHeader: this.agendaHeaderNeedsUpdate ? agendaHeader : null,
			agendaFooter: this.agendaFooterNeedsUpdate ? agendaFooter : null,
			agendaScratchpad: this.agendaScratchpadNeedsUpdate ? agendaScratchpad : null,
			callback,
			forceSave,
		});

		this.isDirty = false;
		this.itemIdsToUpdate = [];
		this.itemIdsToDelete = [];
		this.agendaHeaderNeedsUpdate = false;
		this.agendaFooterNeedsUpdate = false;
		this.agendaScratchpadNeedsUpdate = false;
	} else if (typeof callback === "object" && typeof callback.success === "function") {
		callback.success(callback.data);
	}
}

export function agendaItemFormatCustomNumbering(number, csFormat) {
	return moveAgendaItemCustomNumbering(csFormat, number);
}

const SIDEBAR_WIDTH = "265px";
const WIDE_SIDEBAR_WIDTH = "375px";

export const getSidebarWidth = (useWide) => (useWide ? WIDE_SIDEBAR_WIDTH : SIDEBAR_WIDTH);
