import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";

import { timeStampExists, timeStampToString, getTimeStampPrefix } from "utils/videoTimeStamping";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import Icon from "atlas/components/Icon/Icon";

import { updateCurrentTimestampItem } from "../../../redux/app/actions";
import TimeStampInput from "./TimeStampInput";

const useStyles = makeStyles((theme) => ({
	videoIcon: {
		width: "24px",
		textAlign: "center",
		alignSelf: "center",
		marginLeft: "16px",
	},
	videoIconV2: {
		width: "24px",
		marginLeft: "-24px",
	},
	timestampButton: {
		padding: "0",
	},
	manualTimestamping: {
		display: "flex",
		minWidth: "255px",
		[theme.breakpoints.down("sm")]: {
			minWidth: "190px",
			width: "100%",
			alignItems: "flex-end",
		},
		[theme.breakpoints.down("md")]: {
			marginTop: "8px",
		},
	},
	syncButtons: {
		margin: 0,
		width: "65px",
	},
	syncButtonsSave: {
		marginTop: 0,
		marginBottom: "8px",
		marginRight: 0,
		marginLeft: "8px",
		minWidth: "65px",
	},
	syncButtonsSet: {
		marginTop: 0,
		marginBottom: "8px",
		marginRight: 0,
		marginLeft: "8px",
		minWidth: "170px",
	},
	syncButtonsContainer: {
		[theme.breakpoints.down("md")]: {
			marginTop: "8px",
		},
	},
	timestampInput: {
		marginTop: 0,
		height: "24px",
		width: "110px",
	},
	timestampsyncInput: {
		marginTop: 0,
		height: "24px",
		width: "110px",
	},
	timestampsyncInputContainer: {
		display: "flex",
		width: "100%",
		justifyContent: "flex-end",
	},
	updateTimestampControls: {
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			flexDirection: "column",
			alignSelf: "flex-end",
		},
	},
}));

const TimeStampItem = (props) => {
	const {
		item,
		youtubePlayerRef,
		isSelected,
		syncEnabled,
		saveSyncEnabled,
		updateSync,
		saveSync,
		handleTimeStampBlur,
		clearTimestamp,
		setTimestamp,
		disabled,
		presenting,
		broadcasts,
		isMinuteV2,
	} = props;
	const { t } = useTranslation("meetings");

	const { id } = useParams();
	const navigate = useNavigate();
	const classes = useStyles({});

	const [currentItem, setCurrentItem] = useState(item);

	const dispatch = useDispatch();

	const handleTimeStampClick = (item) => {
		dispatch(updateCurrentTimestampItem(item));

		navigate(`/meeting/timestamping/${id}`);
	};

	useEffect(() => {
		setCurrentItem(item);
	}, [item]);
	return (
		<>
			{timeStampExists(currentItem) && !youtubePlayerRef && !presenting && (
				<div className={isMinuteV2 ? classes.videoIconV2 : classes.videoIcon}>
					<AccessibleIconButton
						className={classes.timestampButton}
						color="inherit"
						onClick={() => handleTimeStampClick(currentItem)}
						iconName="video"
						dataCy={`timestamp-${currentItem.itemType}-${currentItem.guid}`}
						tooltipText={timeStampToString(currentItem?.fields?.TimeStamp?.Value, "go to ")}
					/>
				</div>
			)}
			{timeStampExists(currentItem) && !youtubePlayerRef && presenting && (
				<div className={classes.videoIcon}>
					<Icon name="video" />
				</div>
			)}

			{youtubePlayerRef && syncEnabled && (
				<>
					<div className={classes.updateTimestampControls}>
						{isSelected && (
							<>
								<Button
									className={classes.syncButtonsSet}
									variant="outlined"
									color="primary"
									size="small"
									onClick={() => updateSync(currentItem)}
									data-cy="updateSync"
									fullWidth={false}
								>
									{t("setStartTimeOfMeeting")}
								</Button>

								<Button
									className={classes.syncButtonsSave}
									variant="outlined"
									color="primary"
									size="small"
									onClick={() => saveSync(currentItem)}
									data-cy="saveSync"
									fullWidth={false}
									disabled={!saveSyncEnabled}
								>
									{t("saveSyncTimestamps")}
								</Button>
							</>
						)}
						<div className={classes.timestampsyncInputContainer}>
							<TimeStampInput
								itemGuid={currentItem.guid}
								className={classes.timestampsyncInput}
								disabled
								timeStamp={currentItem?.fields?.TimeStamp?.Value}
								timeStampPrefix={getTimeStampPrefix(currentItem, broadcasts)}
								onBlur={(event) => handleTimeStampBlur(event, currentItem)}
							/>
						</div>
					</div>
				</>
			)}
			{youtubePlayerRef && !syncEnabled && (
				<div className={classes.manualTimestamping}>
					<TimeStampInput
						itemGuid={currentItem.guid}
						className={classes.timestampInput}
						disabled={disabled}
						timeStamp={currentItem?.fields?.TimeStamp?.Value}
						timeStampPrefix={getTimeStampPrefix(currentItem, broadcasts)}
						onBlur={(event) => handleTimeStampBlur(event, currentItem)}
					/>

					<Button
						className={classes.syncButtons}
						variant="text"
						color="primary"
						size="small"
						onClick={() => setTimestamp(currentItem)}
						data-cy="setTimestamp"
						fullWidth={false}
						disabled={disabled}
					>
						{t("setTimestamp")}
					</Button>

					<Button
						className={classes.syncButtons}
						variant="text"
						color="primary"
						size="small"
						onClick={() => clearTimestamp(currentItem)}
						data-cy="clearTimestamp"
						fullWidth={false}
						disabled={disabled}
					>
						{t("clearTimestamp")}
					</Button>
				</div>
			)}
		</>
	);
};

export default TimeStampItem;
