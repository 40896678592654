import {
	GET_AGENDA_BUILDER_AGENDA_ITEMS_PENDING,
	GET_AGENDA_BUILDER_AGENDA_ITEMS_FULFILLED,
	UPDATE_AGENDA_BUILDER_AGENDA_ITEMS,
	UPDATE_AGENDA_SCRATCHPAD,
	ADD_AGENDA_BUILDER_AGENDA_MEETING_ITEM,
	PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_PENDING,
	PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_FULFILLED,
	PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_FAILED,
	REORDER_AGENDA_BUILDER_AGENDA_ITEMS,
	DELETE_AGENDA_BUILDER_AGENDA_MEETING_ITEM,
	REMOVE_AGENDA_BUILDER_AGENDA_MEETING_ITEM,
	RESTORE_AGENDA_BUILDER_AGENDA_MEETING_ITEM,
	SET_ACTIVE,
	SET_AGENDA_BUILDER_CHANGESET_ID,
	CLEAR_AGENDA_BUILDER_STATUS,
} from "./types";
import request from "superagent";
import { API_HOST } from "config/env";

export const getAgendaBuilderItem = (id) => (dispatch) => {
	return new Promise((resolve, reject) =>
		dispatch({
			type: GET_AGENDA_BUILDER_AGENDA_ITEMS_PENDING,
			payload: request
				.get(`${API_HOST}/api/meeting/${id}/getagendaitems`)
				.withCredentials()
				.then((response) => response.body)
				.then((json) => {
					dispatch({
						type: GET_AGENDA_BUILDER_AGENDA_ITEMS_FULFILLED,
						payload: { ...json, id },
					});
					resolve(json);
				})
				.catch((exception) => {
					reject("Error getting agenda items");
				}),
		}),
	);
};

export const setAgendaBuilderAgendaItems = (fieldData, editorFields) => (dispatch) =>
	dispatch({
		type: UPDATE_AGENDA_BUILDER_AGENDA_ITEMS,
		fieldData,
		editorFields,
	});

export const updateAgendaScratchpad = (value) => (dispatch) =>
	dispatch({
		type: UPDATE_AGENDA_SCRATCHPAD,
		value,
	});

export const persistAgendaBuilderAgendaItems = (t, dateFormat, id) => (dispatch, getState) => {
	const state = getState();
	const {
		agendaBuilderReducer: {
			agenda: { changeSetId, persistObject },
		},
	} = state;

	return new Promise((resolve, reject) => {
		if (
			typeof persistObject.agendaHeader !== "undefined" ||
			typeof persistObject.agendaFooter !== "undefined" ||
			typeof persistObject.agendaScratchpad !== "undefined" ||
			(persistObject.items && persistObject.items.length > 0) ||
			(persistObject.itemIdsToDelete && persistObject.itemIdsToDelete.length > 0)
		) {
			persistObject.changeSetId = changeSetId;
			dispatch({
				type: PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_PENDING,
				payload: request
					.put(`${API_HOST}/api/meeting/${id}/saveagendaitems`)
					.withCredentials()
					.timeout({
						response: 300000, // Wait 30 seconds for the server to start sending,
						deadline: 350000, // but allow 35 seconds for the data to finish sending.
					})
					.send(persistObject)
					.then((response) => {
						if (response.status === 200) {
							const { meeting: { changeSetId } = {} } = response.body || {};
							dispatch({
								type: PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_FULFILLED,
								t,
								dateFormat,
								changeSetId,
								clearStatus: () =>
									dispatch({
										type: CLEAR_AGENDA_BUILDER_STATUS,
									}),
							});
							resolve({ meeting: response.body?.meeting });
						} else {
							dispatch({
								type: PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_FAILED,
								t,
								persistObject,
							});
							resolve(false);
						}
					})
					.catch((err) => {
						if (err.status !== 403) {
							dispatch({
								type: PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_FAILED,
								t,
								persistObject,
							});
						}
						reject(err);
					}),
				t,
			});
		} else {
			dispatch({
				type: PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_PENDING,
				noChanges: true,
				t,
			});
			resolve(true);
		}
	});
};

export const reorderAgendaBuilderAgendaItems = (reorderedItems) => (dispatch) =>
	dispatch({
		type: REORDER_AGENDA_BUILDER_AGENDA_ITEMS,
		payload: reorderedItems,
	});

export const deleteAgendaItem = (guid) => (dispatch) =>
	dispatch({
		type: DELETE_AGENDA_BUILDER_AGENDA_MEETING_ITEM,
		payload: guid,
		deleteItem: true,
	});

export const rejectAgendaItem = (comment, item) =>
	new Promise((resolve, reject) => {
		request.post(`${API_HOST}/api/agendaitem/${item.guid}/reject`).withCredentials().send({ comment }).then(resolve).catch(reject);
	});

export const removeAgendaItem = (guid) => (dispatch) =>
	dispatch({
		type: REMOVE_AGENDA_BUILDER_AGENDA_MEETING_ITEM,
		payload: guid,
		deleteItem: false,
	});

export const restoreAgendaItem = (guid) => (dispatch) =>
	dispatch({
		type: RESTORE_AGENDA_BUILDER_AGENDA_MEETING_ITEM,
		payload: guid,
		deleteItem: true,
	});

export const addAgendaItem = (sourceItems, newItemGuid, itemsInsertIndex, parentItem) => (dispatch) =>
	dispatch({
		type: ADD_AGENDA_BUILDER_AGENDA_MEETING_ITEM,
		payload: {
			sourceItems,
			newItemGuid,
			itemsInsertIndex,
			parentItem,
		},
	});

export const setActive = (guid, focus, field) => (dispatch) =>
	dispatch({
		type: SET_ACTIVE,
		payload: {
			guid,
			focus,
			field,
		},
	});

export const setChangeSetId = (changeSetId) => (dispatch) =>
	dispatch({
		type: SET_AGENDA_BUILDER_CHANGESET_ID,
		payload: { changeSetId },
	});
