import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { Close } from "components/Icons";
import { EditorFunctionsContext } from "contexts/EditorFunctions/EditorFunctionsContext";
import AgendaCKEditor from "./AgendaCKEditor";

class ScratchPad extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editorLoaded: null,
		};

		this.handleEditorLoad = this.handleEditorLoad.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		const { showScratchpad } = this.props;
		const {
			editorLoaded: { editor, container },
		} = this.state;

		if ((!prevProps.showScratchpad || prevState.editorLoaded?.editor !== editor) && showScratchpad && container) {
			container.parentElement.querySelector('.ck-content[contenteditable="true"]').focus();
		}
	}

	handleEditorLoad = ({ editor, container }) => {
		this.setState({ editorLoaded: { editor, container } });
	};

	render() {
		const { t, scratchPadContent, handleChange, toggleScratchpadDrawer, active, onClickAction, showScratchpad } = this.props;

		return (
			<Box className="scratchpad-drawer" onClick={onClickAction} data-cy="scratchpad">
				<Box display="flex" className="scratchpad-title">
					<Typography variant="h3">{t("scratchpad")}</Typography>
					<IconButton
						aria-label={t("meetings:buttons.close")}
						className="scratchpad-close-icon"
						onClick={() => toggleScratchpadDrawer()}
						size="large"
					>
						<Close />
					</IconButton>
				</Box>
				<AgendaCKEditor
					guid="scratchPad"
					active={active}
					isActive
					isScratchpad
					toolbar="scratchpad"
					content={scratchPadContent}
					handleLoad={this.handleEditorLoad}
					onChange={(event, editor, cancelNextRender) => handleChange(event, editor, "agendaScratchpad", cancelNextRender)}
					showScratchpad={showScratchpad}
					editorFunctionsContext={this.context}
				/>
			</Box>
		);
	}
}
ScratchPad.contextType = EditorFunctionsContext;
export default withTranslation("agendaMenu")(ScratchPad);
