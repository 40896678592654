import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors, rectIntersection } from "@dnd-kit/core";
import request from "superagent";
import clsx from "clsx";
import { cloneDeep } from "lodash";

import makeStyles from "@mui/styles/makeStyles";

import { grayColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";
import { useWidthDown } from "atlas/utils/useWidth";
import { API_HOST } from "config/env";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import SelectInput from "atlas/components/FormControls/SelectInput";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import DragPresentation from "atlas/components/DragAndDrop/DragPresentation";
import TableOfContents from "./TableOfContents";
import TOCHeading from "./TOCHeading";
import TOCAgendaItem from "./TOCAgendaItem";
import AgendaItemTypesEnum from "utils/enums/AgendaItemTypes";
import { formatDate } from "utils/date";
import { useSelectInputOptionStyles, getNoOptionsMenuItem, getDropdownProgressIndicator, getMenuItem } from "utils/dropDown";
import { useUpdateObject } from "utils/updateObject";
import { getCollisionDetection } from "utils/dragAndDrop";
import { createMeetingElement, prepareAttachmentDuplication } from "utils/meetingElement";
import telemetryAddEvent from "utils/telemetryAddEvent";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const useInputStyles = makeStyles(inputStyle);
const useStyles = makeStyles((theme) => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "672px",
			maxWidth: "100%",
		},
	},
	itemColumns: {
		display: "flex",
		justifyContent: "space-between",
		"& > div": {
			boxSizing: "border-box",
			width: "calc(50% - 8px)",
			[theme.breakpoints.down("md")]: {
				width: "100%",
			},
		},
	},
	meetingLabel: {
		marginBottom: "8px",
	},
	itemList: {
		border: `solid 1px ${grayColor[3]}`,
		maxHeight: "260px",
		overflow: "auto",
	},
	warningMessage: {
		color: "#fa0a0a",
	},
}));

const defaultMeetingType = { meetingType: 0 };
const defaultMeeting = { meeting: 0 };

const CopyMoveItemDialog = (props) => {
	const { show, meeting, numbering, onClose, afterCopyMove, editorFunctions, showSignIn, telemetryPage } = props;
	const mobile = useWidthDown("md");
	const { t } = useTranslation("meetings");
	const dispatch = useDispatch();
	const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
	const [meetingTypes, setMeetingTypes] = useState(null);
	const [meetings, setMeetings] = useState([]);
	const [workingItems, setWorkingItems] = useState(null);
	const [agendaItems, setAgendaItems] = useState([]);
	const [agendaNumbering, setAgendaNumbering] = useState(null);
	const [selections, setSelections] = useState({
		...defaultMeetingType,
		...defaultMeeting,
	});
	const [draggedId, setDraggedId] = useState(null);
	const [droppedId, setDroppedId] = useState(null);
	const [selectedCustomNumbering, setSelectedCustomNumbering] = useState({});
	const [movedSelectedCustomNumbering, setMovedSelectedCustomNumbering] = useState({});
	const [copying, setCopying] = useState(false);
	const [selectWidth, setSelectWidth] = useState(0);
	const dragRef = useRef(null);
	const classes = useStyles();
	const selectInputOptionClasses = useSelectInputOptionStyles({ selectWidth });
	const inputClasses = useInputStyles({ fullWidth: true });
	const meetingActive = meeting ? meeting.startTimeStamp <= Math.floor(Date.now() / 1000) : false;

	const recordOriginalItemOrder = (items) => {
		if (items) {
			items
				.filter((item) => !item.original)
				.forEach((item) => {
					item.original = {
						guid: item.guid,
						order: item.fields.Order.Value,
						number: item.number || item.fields.Number.Value,
						closed: item.fields.Closed.Value,
					};
				});
		}
	};

	const updateSelections = useUpdateObject(setSelections);

	const loadMeetingTypes = () => {
		request
			.get(`${API_HOST}/api/meetingtypes`)
			.withCredentials()
			.then((res) => {
				const { body: meetingTypes } = res || {};

				setMeetingTypes(meetingTypes);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadMeetingTypes();
				});
			});
	};

	const loadMeetings = (meetingTypeId) => {
		// Clear selection
		setMeetings(meetingTypeId > 0 ? null : []);
		updateSelections({ target: { value: defaultMeeting.meeting } }, "meeting", false, true);

		if (meetingTypeId > 0) {
			const queryFilters = {};
			const currentDateTime = new Date();
			const date = new Date(
				currentDateTime.getFullYear(),
				currentDateTime.getMonth(),
				currentDateTime.getDate(),
				currentDateTime.getHours(),
				currentDateTime.getMinutes(),
			); // makes sure seconds and milliseconds don't mess up comparisons
			const textDateTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
			queryFilters.from = textDateTime;
			queryFilters.meetingTypes = meetingTypeId;
			queryFilters.ascending = true;

			request
				.get(`${API_HOST}/api/meetings/filter`)
				.query(queryFilters)
				.then((res) => {
					const {
						body: { meetings },
					} = res || {};

					setMeetings(meetings.filter((futureMeeting) => futureMeeting.id !== meeting.id));
				})
				.catch((err) => {
					showSignIn(err, () => {
						loadMeetings(meetingTypeId);
					});
				});
		}
	};

	const loadWorkingAgendaItems = () => {
		if (meeting.id > 0) {
			request
				.get(`${API_HOST}/api/meeting/${meeting.id}/getagendaitems`)
				.then((response) => {
					const {
						body: { items, customNumbering },
					} = response;

					// Process the agenda items to add the numbering, among other things
					editorFunctions.reOrderItemArray(
						items,
						{
							props: {
								agendaNumbering: numbering,
							},
							itemIdsToUpdate: [],
						},
						customNumbering,
					);
					// Record the original numbering and order
					setSelectedCustomNumbering(customNumbering);
					recordOriginalItemOrder(items);

					setWorkingItems(items);
				})
				.catch((err) => {
					showSignIn(err, () => {
						loadWorkingAgendaItems();
					});
				});
		}
	};

	const loadAgendaItems = (meetingId) => {
		// Clear selection
		setAgendaItems(null);

		if (meetingId > 0) {
			request
				.get(`${API_HOST}/api/meeting/${meetingId}/getagendaitems`)
				.then((response) => {
					const {
						body: { items, agendaNumbering, customNumbering },
					} = response;

					// Process the agenda items to add the numbering, among other things
					editorFunctions.reOrderItemArray(
						items,
						{
							props: {
								agendaNumbering,
							},
							itemIdsToUpdate: [],
						},
						customNumbering,
					);
					// Record the original numbering and order
					recordOriginalItemOrder(items);
					setMovedSelectedCustomNumbering(customNumbering);
					setAgendaItems(items);
					setAgendaNumbering(agendaNumbering);
				})
				.catch((err) => {
					showSignIn(err, () => {
						loadAgendaItems(meetingId);
					});
				});
		}
	};

	const getActiveItemData = (activeId) => {
		const activeItem = workingItems.find((item) => item.guid === activeId);
		const isHeading = activeItem
			? activeItem.itemType === AgendaItemTypesEnum().HEADING.value && activeItem.fields.Indent.Value === 0
			: false;

		return [activeItem, isHeading];
	};

	const getNearestValidDropLocation = (activeId, overId) => {
		const [, isHeading] = getActiveItemData(activeId);
		let dropBelowId = overId;
		if (dropBelowId && dropBelowId.length > 36) {
			dropBelowId = dropBelowId.substr(0, 36);
		}

		if (isHeading) {
			// Restrict the place holder to between headings, not items
			dropBelowId = null;
			let foundItem = false;
			for (const item of agendaItems) {
				if (!foundItem) {
					foundItem = item.guid === overId;
				}
				if (foundItem) {
					if (item.itemType === AgendaItemTypesEnum().HEADING.value && item.fields.Indent.Value === 0) {
						if (item.guid === overId) {
							dropBelowId = item.guid;
						} else {
							break;
						}
					} else if (item.itemType === AgendaItemTypesEnum().ITEM.value) {
						dropBelowId = item.guid;
					}
				}
			}
		}

		return dropBelowId;
	};

	const addItem = (sourceItem, destinationParent, order, sourceItems, destinationItems, destinationIndex) => {
		const source = prepareAttachmentDuplication(cloneDeep(sourceItem));

		const newItem = createMeetingElement({
			guid: !meetingActive ? source.guid : undefined,
			itemType: source.itemType,
			name: source.fields.Name.Value,
			text: source.fields.Text.Value,
			boardNotes: source.fields.BoardNotes.Value,
			indent: source.fields.Indent.Value,
			order,
			parentGuid: destinationParent ? destinationParent.guid : "",
			closed: (destinationParent || source).fields.Closed.Value,
			consent: (destinationParent || source).fields.Consent.Value,
			publicComment: (destinationParent || source).fields.PublicComment.Value,
			attachmentMapping: source.attachmentMapping,
		});
		newItem.copied = true;
		newItem.original = {
			...sourceItem.original,
		};

		destinationItems.splice(destinationIndex, 0, newItem);

		destinationIndex++;

		sourceItems
			.filter((item) => item.attributes.relationshipGuid === source.guid)
			.forEach((item, index) => {
				destinationIndex = addItem(item, newItem, index + 1, sourceItems, destinationItems, destinationIndex);
			});

		return destinationIndex;
	};

	const removeItem = (itemToRemove, sourceItems, destinationItems, flagRemoval = true) => {
		if (flagRemoval) {
			// Remove item from the current meeting
			itemToRemove.removed = true;
			sourceItems
				.filter((item) => item.attributes.relationshipGuid === itemToRemove.guid)
				.forEach((item) => {
					removeItem(item, sourceItems, destinationItems, flagRemoval);
				});
		} else {
			// Remove copied or moved item from the new meeting
			const index = destinationItems.findIndex((item) => item.guid === itemToRemove.guid);
			if (index >= 0) {
				destinationItems.splice(index, 1);
			}
			const sourceItem = sourceItems.find((item) => item.guid === itemToRemove.original.guid);
			if (sourceItem) {
				delete sourceItem.removed;
			}
			destinationItems
				.filter((item) => item.attributes.relationshipGuid === itemToRemove.guid)
				.forEach((item) => {
					removeItem(item, sourceItems, destinationItems, flagRemoval);
				});
		}
	};

	const copyOrMove = (sourceId, destinationId) => {
		if (destinationId) {
			const [activeItem, isHeading] = getActiveItemData(sourceId);
			const destinationIndex = agendaItems.findIndex((item) => item.guid === destinationId);
			const destinationItem = agendaItems[destinationIndex];
			const destinationHeading =
				destinationItem.itemType === AgendaItemTypesEnum().HEADING.value
					? destinationItem
					: agendaItems.find((item) => item.guid === destinationItem.attributes.relationshipGuid);
			// Skip any recommendations
			let insertIndex = destinationIndex;
			for (let index = destinationIndex; index < agendaItems.length; index++) {
				const item = agendaItems[index];
				if (item.itemType === AgendaItemTypesEnum().HEADING.value || item.itemType === AgendaItemTypesEnum().ITEM.value) {
					break;
				} else {
					insertIndex = index;
				}
			}
			insertIndex++;

			// Add the item(s) to the new meeting
			if (!isHeading) {
				// Add item
				addItem(
					activeItem,
					destinationHeading,
					destinationItem.itemType === AgendaItemTypesEnum().HEADING.value ? 1 : destinationItem.fields.Order.Value + 1,
					workingItems,
					agendaItems,
					insertIndex,
				);
			} else {
				addItem(
					activeItem,
					null,
					destinationItem.itemType === AgendaItemTypesEnum().HEADING.value ? 1 : destinationItem.fields.Order.Value + 1,
					workingItems,
					agendaItems,
					insertIndex,
				);
			}

			editorFunctions.reOrderItemArray(
				agendaItems,
				{
					props: {
						agendaNumbering,
					},
					itemIdsToUpdate: [],
				},
				movedSelectedCustomNumbering,
			);
			setAgendaItems([...agendaItems]);

			// Remove the items from the current meeting if moving
			if (!meetingActive) {
				removeItem(activeItem, workingItems);

				editorFunctions.reOrderItemArray(
					workingItems.filter((item) => !item.removed),
					{
						props: {
							agendaNumbering: numbering,
						},
						itemIdsToUpdate: [],
					},
					selectedCustomNumbering,
				);
				setWorkingItems([...workingItems]);
			}
		}
	};

	const handleDragStart = (e) => {
		const { active } = e;

		setDraggedId(active.id);

		document.body.style.userSelect = "none";
	};

	const handleDragMove = (e) => {
		const { active, over } = e;

		if (over) {
			setDroppedId(getNearestValidDropLocation(active.id, over.id));
		} else {
			setDroppedId(null);
		}
	};

	const endDrag = () => {
		setDraggedId(null);
		setDroppedId(null);

		document.body.style.userSelect = null;
	};

	const handleDragEnd = (e) => {
		const { active } = e;

		copyOrMove(active.id, droppedId);

		endDrag();
	};

	const handleDragCancel = (e) => {
		endDrag();
	};

	const handleCancelMoveCopy = useCallback(
		(guid) => {
			const activeItem = agendaItems.find((item) => item.guid === guid);
			if (activeItem) {
				removeItem(activeItem, workingItems, agendaItems, false);

				editorFunctions.reOrderItemArray(
					agendaItems,
					{
						props: {
							agendaNumbering,
						},
						itemIdsToUpdate: [],
					},
					movedSelectedCustomNumbering,
				);
				setAgendaItems([...agendaItems]);

				if (!meetingActive) {
					editorFunctions.reOrderItemArray(
						workingItems.filter((item) => !item.removed),
						{
							props: {
								agendaNumbering: numbering,
							},
							itemIdsToUpdate: [],
						},
						selectedCustomNumbering,
					);
					setWorkingItems([...workingItems]);
				}
			}
		},
		[agendaItems, workingItems, agendaNumbering, numbering],
	);

	const isItemUpdated = (item) =>
		item.removed || item.copied || item.original.order !== item.fields.Order.Value || item.original.number !== item.fields.Number.Value;

	const handleCopyMove = () => {
		setCopying(true);

		telemetryAddEvent(`Agenda builder - Confirm and ${!meetingActive ? "Move" : "Copy"}`);

		request
			.post(`${API_HOST}/api/meeting/${meeting.id}/copy`)
			.withCredentials()
			.send({
				newMeetingId: selections.meeting,
				newItems: agendaItems.filter(isItemUpdated),
				oldItems: workingItems.filter(isItemUpdated),
			})
			.then(() => {
				onClose();
				let option = notifierMessage(t(`copyMoveItemDialog.snackbar.success.${!meetingActive ? "move" : "copy"}`), "success");
				dispatch(setSnackbarOptions(option));
				if (typeof afterCopyMove === "function") {
					afterCopyMove();
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleCopyMove(meetingId);
				});
			});
	};

	const handleCancel = () => {
		onClose();
	};

	const getMeetingName = (selectedMeeting) =>
		selectedMeeting ? `${selectedMeeting.name} ${formatDate(null, selectedMeeting.startTime, null, t("app:at"), "", "", false)}` : null;

	useEffect(() => {
		loadMeetingTypes();
		loadWorkingAgendaItems();
	}, [meeting]);

	useEffect(() => {
		loadMeetings(selections.meetingType);
	}, [selections.meetingType]);

	useEffect(() => {
		loadAgendaItems(selections.meeting);
	}, [selections.meeting]);

	useEffect(() => {
		// Set the drop-down options width to match the width of the control
		const itemSelect = document.getElementById("meeting-types");
		if (itemSelect) {
			setSelectWidth(itemSelect.offsetWidth);
		}
	});

	const i18n = t("copyMoveItemDialog");
	const dialog = {
		title: i18n.title[meetingActive ? "copy" : "move"],
		line1: i18n.line1,
		primaryTitle: i18n.buttons[meetingActive ? "copy" : "move"],
		primaryAction: handleCopyMove,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	const getMeetingTypes = () => {
		const meetingTypeMenuItems = [];

		if (meetingTypes) {
			meetingTypeMenuItems.push(getNoOptionsMenuItem(t));

			meetingTypes
				.filter((meetingType) => !meetingType.deleted)
				.forEach((meetingType) => {
					meetingTypeMenuItems.push(
						getMenuItem(meetingType.id, meetingType.name, `meeting-type-${meetingType.id}`, selectInputOptionClasses),
					);
				});
		} else {
			meetingTypeMenuItems.push(getDropdownProgressIndicator());
		}

		return meetingTypeMenuItems;
	};

	const getMeetings = () => {
		const meetingMenuItems = [];

		if (meetings) {
			meetingMenuItems.push(getNoOptionsMenuItem(t));

			meetings
				.filter((meeting) => !meeting.deleted)
				.forEach((meeting) => {
					meetingMenuItems.push(getMenuItem(meeting.id, getMeetingName(meeting), `meeting-${meeting.id}`, selectInputOptionClasses));
				});
		} else {
			meetingMenuItems.push(getDropdownProgressIndicator());
		}

		return meetingMenuItems;
	};

	const getItemList = (items, meeting, mobile, canDrag, canDrop) => (
		<div className={classes.itemList}>
			<TableOfContents
				items={items}
				isSmallDevice={mobile}
				isClosedMeeting={meeting.closed}
				idPrefix={canDrop ? "destination" : "source"}
				lineLimit={2}
				hideRecommendations
				hideAttachments
				canDrag={canDrag}
				dragPlaceholders={!meetingActive ? [draggedId] : undefined}
				canDrop={canDrop}
				dropId="next-meeting"
				dropPlaceholder={canDrop ? droppedId : undefined}
				handleCancelMoveCopy={canDrop ? handleCancelMoveCopy : undefined}
				telemetryPage={telemetryPage}
			/>
		</div>
	);

	const getDragComponent = () => {
		let component = null;
		if (draggedId) {
			const item = workingItems.find((item) => item.guid === draggedId);
			if (item) {
				const parserOptions = {
					replace: (node) => {
						if (!node.attribs) return;
						if (["img", "br"].includes(node.name) && node.attribs.style) {
							node.attribs.style = "";
						}
						if (node.name === "p") {
							node.attribs.style = "margin-top: 0; margin-bottom: 0;";
						}
					},
				};

				if (item.itemType === AgendaItemTypesEnum().HEADING.value) {
					component = (
						<TOCHeading
							isSmallDevice={mobile}
							isSubHeading={Boolean(item.attributes.relationshipGuid)}
							section={item}
							parserOptions={parserOptions}
							isClosedMeeting={meeting.closed}
							lineLimit={2}
							hideAttachments
							dragPresentational
						/>
					);
				}

				if (item.itemType === AgendaItemTypesEnum().ITEM.value) {
					component = (
						<TOCAgendaItem
							isSmallDevice={mobile}
							item={item}
							parserOptions={parserOptions}
							isClosedMeeting={meeting.closed}
							lineLimit={2}
							hideAttachments
							dragPresentational
						/>
					);
				}
			}
		}

		return component;
	};

	const selectedMeeting = meetings ? meetings.find((meeting) => meeting.id === selections.meeting) : null;
	const isValid = selections.meetingType && selections.meeting && agendaItems && agendaItems.find((item) => item.copied);

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={!isValid || copying}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={copying}
			leftActionChildren={
				agendaItems && agendaItems.find((item) => item.original && item.original.closed && !item.fields.Closed.Value) ? (
					<div className={classes.warningMessage}>{i18n.warningMembersOnlyToPublic}</div>
				) : null
			}
			clickAwayDisabled={copying}
			closeIcon={<Icon name="close" />}
			data-cy="copy-move-items"
		>
			<div className={classes.body}>
				<div>{dialog.line1}</div>
				<div>
					<SelectInput
						id="meeting-types"
						className={inputClasses.smallInput}
						noDefaultClassName
						label={i18n.meetingTypes}
						size="small"
						value={selections.meetingType}
						onChange={(e) => updateSelections(e, "meetingType", false, true)}
						data-cy="meeting-types"
					>
						{getMeetingTypes()}
					</SelectInput>
				</div>
				<div>
					<SelectInput
						id="meetings"
						className={inputClasses.smallInput}
						noDefaultClassName
						label={i18n.meetings}
						size="small"
						value={selections.meeting}
						onChange={(e) => updateSelections(e, "meeting", false, true)}
						data-cy="meetings"
					>
						{getMeetings()}
					</SelectInput>
				</div>
				<div className={clsx(classes.itemColumns, classes.meetingLabel)}>
					<div>{getMeetingName(meeting)}</div>
					<div>{getMeetingName(selectedMeeting)}</div>
				</div>
				<DndContext
					sensors={sensors}
					collisionDetection={getCollisionDetection(dragRef, rectIntersection)}
					onDragStart={handleDragStart}
					onDragMove={handleDragMove}
					onDragEnd={handleDragEnd}
					onDragCancel={handleDragCancel}
				>
					<div className={classes.itemColumns}>
						{workingItems ? (
							getItemList(
								workingItems.filter((item) => !item.removed),
								meeting,
								mobile,
								!!selectedMeeting,
							)
						) : (
							<CircularProgressIndicator />
						)}
						{selectedMeeting ? (
							getItemList(agendaItems, selectedMeeting, mobile, false, true)
						) : selections.meeting ? (
							<CircularProgressIndicator />
						) : null}
					</div>
					<DragOverlay>
						<DragPresentation ref={dragRef}>{getDragComponent()}</DragPresentation>
					</DragOverlay>
				</DndContext>
			</div>
		</GenericDialog>
	);
};

export default CopyMoveItemDialog;
