import React, { useState, useContext, createContext, useRef, useEffect } from "react";
import clsx from "clsx";

import { useWidthDown } from "atlas/utils/useWidth";
import { setActive } from "redux/agendaBuilder/actions";
import TableOfContents from "../Meeting/TableOfContents";
import PdfViewerNew from "./PdfViewerNew";
import { makeStyles } from "@mui/styles";
import useStateRef from "./utils/useStateRef";
import { UserContext } from "contexts/User/UserContext";
import telemetryAddEvent from "utils/telemetryAddEvent";
import ComparePolicySideBar from "./ComparePolicySideBar";
import {
	loadAllAnnotations,
	loadAnnotations,
	saveAnnotation,
	moveAllAnnotations,
	deleteAllAnnotations,
	deleteAnnotation,
	getPolicyCompareList,
} from "./PdfAPICalls";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import GoalsViewDialog from "./GoalsViewDialog";

const useStyles = makeStyles((theme) => ({
	tableOfContentContainer: {
		overflow: "overlay",
		height: "calc(100% - 48px)",
		paddingBottom: "8px",
	},
	rowContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		height: "100%",
		zIndex: "1201",
		position: "relative",
	},
	agendaOutlineContainer: {
		background: "#2f3b4d",
		color: "#FFFFFF",
		height: "48px",
		display: "flex",
		alignItems: "center",
		padding: "0px 0px 0px 16px",
	},
	sideBarClosed: {
		transform: "translateX(-100%)",
		transition: "transform 50s ease-in-out",
		visibility: "hidden",
	},
	sideBarOpen: {
		transform: "transform .3s ease-in-out",
		transition: "transform 50s ease-in-out",
		background: "#FFFFFF",
	},
	sideBar: {
		background: "#FFFFFF",
		width: "0px",
		height: "100%",
		transition: "width 1s ease-in-out",
		overflow: "hidden",
		[theme.breakpoints.down(769)]: {
			position: "absolute",
			top: "48px",
			left: "0px",
			height: "calc(100% - 48px)",
			zIndex: "1",
		},
	},
	visible: {
		maxWidth: "300px",
		minWidth: "0px",
		width: "300px",
		[theme.breakpoints.down(769)]: {
			maxWidth: "100%",
			width: "100%",
		},
	},
	pdfViewerWrapper: {
		flex: 1,
		height: "100%",
	},
}));

const telemetryPage = "Meeting Template Agenda";

export const StaffUserContext = createContext();

const PdfWithSideBar = ({
	id,
	agendaItems,
	showClosedItemDescription,
	settings,
	selectedDocumentId,
	settings: { attachments: attachmentsDetails, fullAgendaPdfId },
	fileBlob,
	useBlob,
	showSignIn,
	meetingId,
	agendaItemsForBookmarks,
}) => {
	const widthDownSm = useWidthDown("sm");
	const [attachmentData, setAttachmentData] = useState();
	const { t } = useTranslation();
	const [showSideBar, setShowSideBar, refShowSideBar] = useStateRef(!widthDownSm);
	const [loadAttachment, setloadAttachment] = useState(false);
	const [activeOutlineItem, setActiveOutlineItem] = useState();
	const [loadOriginalDoc, setLoadOriginalDoc] = useState(false);
	const [showCompareHeader, setShowCompareHeader] = useState(false);
	const [selectedCompareDoc, setSelectedCompareDoc] = useState({});
	const [, setDocumentAnnotationVersionsSelected, refDocumentAnnotationVersions] = useStateRef([]);
	const { active: { id: selectedId } = {} } = useSelector((state) => state.agendaBuilderReducer);
	const [currentPolicyDetails, setCurrentPolicyDetails] = useState();
	const classes = useStyles();
	const { boardAdmin, boardMember, boardStaff } = useContext(UserContext).user;
	const [comparePolicyDialog, setComparePolicyDialog] = useState(false);
	const closeComparePolicySideBar = () => setComparePolicyDialog(false);
	const openComparePolicySideBar = (data) => {
		setCurrentPolicyDetails(data);
		setComparePolicyDialog(true);
	};
	const [showGoal, setShowGoal] = useState(false);
	const [goalLink, setGoalLink] = useState("");

	useEffect(() => {
		const contentScroll = document.getElementById("content-scroll");
		if (contentScroll) {
			contentScroll.setAttribute("style", `overflow: hidden;${widthDownSm ? " padding-inline: 0;" : ""}`);
		}

		return () => {
			const contentScroll = document.getElementById("content-scroll");
			if (contentScroll) {
				contentScroll.setAttribute("style", "");
			}
		};
	}, [widthDownSm]);

	useEffect(() => {
		if (settings && settings.documentAnnotationVersions) {
			setDocumentAnnotationVersionsSelected(settings.documentAnnotationVersions);
		}
	}, [settings]);

	const setDataForAttachmentDocument = (attachments, itemGuid, file) => {
		if (attachments && attachments.length > 0) {
			if (widthDownSm) {
				setShowSideBar(false);
			}
			setloadAttachment(true);
			const attachmentObject = attachmentsDetails?.find((e) => e.guid === itemGuid && e.url.includes(file.guid));
			if (attachmentObject && attachmentObject.guid === file.itemGuid) {
				attachmentObject["isPolicy"] = file.isPolicy;
				attachmentObject["sourcePolicyGuid"] = file.sourcePolicyGuid;
				attachmentObject["policyGuid"] = file.guid;
			}
			setAttachmentData(attachmentObject);
			setActiveOutlineItem(null);
			setLoadOriginalDoc(false);
		}
	};

	const openGoalsPopup = (goal) => {
		setShowGoal(true);
		setGoalLink(goal.id);
	};
	const isStaffUser = boardStaff && !boardAdmin && !boardMember;

	const setSearchHeading = () => {
		setActiveOutlineItem(null);
	};

	const setDataForOutlineItem = (section) => {
		if (widthDownSm) {
			setShowSideBar(false);
		}
		setloadAttachment(false);
		setLoadOriginalDoc(true);
		setActiveOutlineItem(section);
		setAttachmentData(null);
	};

	const cleanAttachmentData = () => {
		setloadAttachment(false);
	};

	const resetDocumentAnnotationVersion = () => {
		setDocumentAnnotationVersionsSelected([]);
	};

	const setLoadOriginalDocFromPDF = (value) => {
		setLoadOriginalDoc(value);
	};

	const setDataForAttachmentLink = (attachment) => {
		setAttachmentData(attachment);
		setloadAttachment(true);
		setLoadOriginalDoc(false);
	};

	const resetComparePolicySelection = () => {
		setSelectedCompareDoc(null);
	};

	const onCompareClick = (selectedPolicy) => {
		telemetryAddEvent("Agenda View - Policy Doing Compare");
		setSelectedCompareDoc(selectedPolicy);
		setShowCompareHeader(true);
		closeComparePolicySideBar();
	};
	const closeGoalPopup = () => {
		setShowGoal(false);
	};

	const customizationOptions = {
		fullScreenCustom: {
			customWrapper: document.getElementById("memberAgendaWrapper"),
		},
		printCustom: {
			printButton1: {
				customlabel: t("meetings:pdfViewer.printButton1"),
				onClickUrl: `/document/${fullAgendaPdfId}`,
			},
			printButton2: {
				customlabel: t("meetings:pdfViewer.printButton2"),
				onClickUrl: "internal",
			},
		},
		downloadCustom: {
			customLabel: t("meetings:pdfViewer.downloadLabel"),
		},
	};

	return (
		<StaffUserContext.Provider value={isStaffUser}>
			<div className={classes.rowContainer} id="memberAgendaWrapper">
				{showGoal ? <GoalsViewDialog goalLink={goalLink} closeGoalPopup={closeGoalPopup} /> : null}
				<div
					id="toc"
					className={clsx(classes.sideBar, {
						[classes.visible]: showSideBar,
					})}
					data-cy="agenda-outline"
				>
					<div className={classes.agendaOutlineContainer}>Agenda Outline</div>
					<div id="toccontent" className={classes.tableOfContentContainer}>
						<TableOfContents
							items={agendaItems}
							showClosedItemDescription={showClosedItemDescription}
							isClosed={true}
							buttons
							setActive={setActive}
							telemetryPage={telemetryPage}
							isPdfSidebar={true}
							setDataForAttachmentDocument={setDataForAttachmentDocument}
							openGoalsPopup={openGoalsPopup}
							setDataForOutlineItem={setDataForOutlineItem}
							selectedId={selectedId && selectedId}
						/>
					</div>
				</div>
				<div className={classes.pdfViewerWrapper} id="pdf-viewer-wrapper">
					<PdfViewerNew
						id={id}
						meetingId={meetingId}
						settings={settings}
						fileBlob={fileBlob}
						useBlob={useBlob}
						isMeetingDocument
						isMemberAgenda
						agendaItems={agendaItems}
						agendaItemsForBookmarks={agendaItemsForBookmarks}
						selectedDocumentId={selectedDocumentId}
						loadAnnotationsAction={loadAnnotations}
						saveAnnotationAction={saveAnnotation}
						moveAllAnnotationsAction={moveAllAnnotations}
						deleteAnnotationAction={deleteAnnotation}
						deleteAllAnnotationsAction={deleteAllAnnotations}
						loadAllAnnotations={loadAllAnnotations}
						attachmentData={attachmentData}
						loadAttachment={loadAttachment}
						activeOutlineItem={activeOutlineItem}
						loadOriginalDoc={loadOriginalDoc}
						showSignIn={showSignIn}
						setShowSideBar={setShowSideBar}
						refShowSideBar={refShowSideBar}
						documentAnnotationVersions={refDocumentAnnotationVersions}
						resetDocumentAnnotationVersion={resetDocumentAnnotationVersion}
						setLoadOriginalDocFromPDF={setLoadOriginalDocFromPDF}
						setSearchHeading={setSearchHeading}
						setDataForAttachmentLink={setDataForAttachmentLink}
						showSidePanel
						showCompareMode={showCompareHeader}
						setShowCompareHeader={setShowCompareHeader}
						openComparePolicySideBar={openComparePolicySideBar}
						selectedCompareDoc={selectedCompareDoc}
						cleanAttachmentData={cleanAttachmentData}
						resetComparePolicySelection={resetComparePolicySelection}
						customizationOptions={customizationOptions}
						headerEndDivider
					/>
				</div>
				{comparePolicyDialog && (
					<ComparePolicySideBar
						meetingId={meetingId}
						currentPolicyDetails={currentPolicyDetails}
						attachmentData={attachmentData}
						getPolicyCompareList={getPolicyCompareList}
						onClose={closeComparePolicySideBar}
						onCompareClick={onCompareClick}
						selectedComparePolicy={selectedCompareDoc}
					/>
				)}
			</div>
		</StaffUserContext.Provider>
	);
};

export default PdfWithSideBar;
