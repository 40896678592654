import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import { v4 as uuid } from "uuid";
import { debounce } from "lodash";

import makeStyles from "@mui/styles/makeStyles";
import { Container, List } from "@mui/material";

import AgendaItemTypesEnum from "utils/enums/AgendaItemTypes";
import MinutesItemTypesEnum from "utils/enums/MinutesItemTypes";
import { scrollToElement2 as scrollToElement } from "utils/scrollToElement";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { useWidthUp, useWidthDown } from "atlas/utils/useWidth";
import LiveMeetingItem from "./LiveMeetingItem";
import { findItemByID } from "views/MeetingEditor/functions/utils";
import { addMotion, setActive, setSelected, timestampItem, updateMinutesItems } from "redux/meeting/actions";
import { adoptPublishPreviousMinutes } from "redux/liveMeeting/actions";
import { timeStampExists } from "utils/videoTimeStamping";
import SingletonEditor from "components/Editor/SingletonEditor";
import LiveMeetingHeader from "./LiveMeetingHeader";
import LiveMeetingRequestToSpeak from "views/LiveMeeting/components/LiveMeetingRequestToSpeak";
import AdoptPublishErrorDialog from "components/Dialogs/AdoptPublishErrorDialog";
import { GET_MULTIPLE_POLICY_ATTACHMENTS_FULFILLED } from "redux/policyGovernance/types";

const useEditorStyles = makeStyles(() => ({
	editorFieldHide: {
		visibility: "hidden",
	},
	loadingIndicator: {
		height: "49.4px",
		width: "100%",
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		position: "absolute",
		left: "0",
		top: "0",
		boxSizing: "border-box",
	},
	editorContentSmUp: {
		minWidth: "450px",
		boxSizing: "border-box",
		overflow: "hidden",
		"-webkit-transform": "translate3d(0, 0, 0)",
		width: (props) => (props.showOutline ? "calc(100% - 265px)" : "100%"),
		marginLeft: (props) => (props.showOutline ? "265px" : "0"),
	},
	contentContainer: {
		maxWidth: "7.5in !important",
		paddingTop: "8px !important",
		paddingBottom: "8px !important",
	},
}));

const MinutesEditor = (props) => {
	const {
		editorToolbarRef,
		showSignIn,
		handleUpdateMotion,
		queueFileUploads,
		addPolicy,
		invalidFileExtension,
		addGoals,
		votingData,
		onlineVoters,
		votingSettings,
		adoptUpdating,
		showOutline,
	} = props;
	const widthUpSm = useWidthUp("sm");
	const widthDownLg = useWidthDown("lg");
	const { t } = useTranslation("meetings");
	const scrollToSelected = useRef(false);
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const client = useSelector((state) => state.appReducer.signalR?.client);
	const {
		minutesItems,
		agendaItems,
		meeting,
		meeting: { rollCall, otherRollCallTypes } = {},
		additionalUsers,
		presenting,
		selected,
		header,
		footer,
		requestsToSpeak,
		active,
		focus,
		field: activeField,
	} = useSelector((state) => state.meetingsReducer);
	const policyAttachmentsMinutes = useSelector((state) => state.policyGovernanceReducer.policyAttachmentsMinutes) || [];
	const multiplePolicyData = useSelector((state) => state.policyGovernanceReducer.multiplePolicyData);
	const classes = useEditorStyles({ showOutline: showOutline && !widthDownLg });
	const [editorFields, _setEditorFields] = useState([]);
	const [editorInitializing, setEditorInitializing] = useState(true);
	const [triggerEditorChange, setTriggerEditorChange] = useState(false);
	const [adoptPublishError, setAdoptPublishError] = useState(null);
	const [deletedFieldIdx, setDeletedFieldIdx] = useState(-1);
	const [policyAttachments, setPolicyAttachments] = useState({});
	const editorFieldsRef = useRef(editorFields);
	const elementsRef = useRef([]);

	const setEditorFields = (data) => {
		editorFieldsRef.current = data;
		_setEditorFields(data);
	};

	const getDefaultPublicCommentHeading = (items) =>
		items.find((item) => !item.deleted && item.itemType === AgendaItemTypesEnum().HEADING.value && item.fields.PublicComment.Value);

	const getAgendaItem = (minutesItem) => {
		return (
			agendaItems.find((item) => minutesItem.agendaItemGuid === item.guid || minutesItem.headingGuid === item.guid) || { attachments: [] }
		);
	};

	const addRequestsToSpeak = (filteredItems, headingData, defaultPublicCommentHeading) => {
		const { item, numberOfChildren } = headingData;

		if (item) {
			const agendaItem = getAgendaItem(item);

			requestsToSpeak
				.filter(
					(requestToSpeak) =>
						requestToSpeak.headingGuid === agendaItem.guid ||
						(requestToSpeak.headingGuid.length === 0 &&
							defaultPublicCommentHeading &&
							defaultPublicCommentHeading.guid === agendaItem.guid),
				)
				.forEach((requestToSpeak, index) => {
					const requestToSpeakFilteredItem = filteredItems.filter((filteredItem) => filteredItem.guid === requestToSpeak.guid);
					if (requestToSpeakFilteredItem == null || requestToSpeakFilteredItem.length === 0) {
						filteredItems.push({
							...requestToSpeak,
							order: numberOfChildren + index + 1,
							fields: {
								Consent: {
									Value: agendaItem.fields.Consent.Value,
								},
								PublicComment: {
									Value: agendaItem.fields.PublicComment.Value,
								},
								Closed: {
									Value: agendaItem.fields.Closed.Value,
								},
							},
						});
					}
				});
		}
	};

	const handleEditorInitialized = () => {
		setEditorInitializing(false);
	};

	const handleFieldChange = (editor) => {
		const fieldData = editor.getFieldData();

		dispatch(updateMinutesItems(fieldData, editorFieldsRef.current));
		editor.ui.update();
	};

	const focusOnEditor = (fieldName) => {
		dispatch(setActive(fieldName, true));
	};

	// TODO Find the reason why this gets triggered twice when dispatching - Debounce is only there to prevent 2nd call. Temporary band-aid...
	const handleFocusChange = debounce(
		useCallback((fieldName, fieldElement, editor) => {
			if (fieldName && editor?.ui?.focusTracker?.isFocused) {
				dispatch(setActive(fieldName));
			}
		}, []),
		100,
	);

	const handleSelect = useCallback(
		(e, options) => {
			const updatedSelected = typeof e === "string" ? e : e.target.value;
			dispatch(setSelected(updatedSelected));

			scrollToSelected.current = Boolean(options && options.scroll);

			const minutesItem = minutesItems.find((item) => item.guid === updatedSelected);

			client.liveMeetingHub.updateLiveMeeting(meeting.id, presenting, minutesItem ? minutesItem.agendaItemGuid : updatedSelected);
			if (presenting && meeting.startTimeStamp <= Math.floor(Date.now() / 1000) && !timeStampExists(minutesItem)) {
				dispatch(timestampItem(minutesItem));
			}
		},
		[minutesItems, meeting?.id, meeting?.startTimeStamp, presenting],
	);

	const updateEditorFields = (itemGuid, isDeleting = false) => {
		const newEditorFields = [...editorFieldsRef.current];
		const editorFieldText = newEditorFields.find((field) => field.name === `${itemGuid}-text`);
		if (editorFieldText) {
			editorFieldText.deleted = isDeleting;
			setEditorFields(newEditorFields);
			setTriggerEditorChange((triggerEditorChange) => !triggerEditorChange);
		}
	};

	const handleAddMotion = useCallback((item) => {
		const newFields = [...editorFieldsRef.current];
		const newItemGuid = uuid();

		const index = newFields.findIndex((field) => {
			return field.name === item.guid;
		});

		newFields.splice(
			index + 2,
			0,
			{
				guid: newItemGuid,
				name: newItemGuid,
				content: "",
				toolbar: "simple",
				deleted: false,
			},
			{
				guid: newItemGuid,
				name: `${newItemGuid}-text`,
				content: item.fields.Text.Value || "",
				toolbar: "itemText",
				deleted: item.fields.Text.Value === null,
			},
		);

		setEditorFields(newFields);

		dispatch(addMotion(item, newItemGuid));
		focusOnEditor(newItemGuid);

		enqueueSnackbar(t("motions.snackbar.added"));
	}, []);

	const addItemText = useCallback((itemGuid) => {
		updateEditorFields(itemGuid);
		if (itemGuid) {
			dispatch(setActive(itemGuid.indexOf("-text") < 0 ? `${itemGuid}-text` : itemGuid, true));
		}
	}, []);

	const removeItemText = useCallback((itemGuid) => {
		const idx = editorFieldsRef.current.findIndex((f) => f.name === `${itemGuid}-text`);
		setDeletedFieldIdx(idx);
		updateEditorFields(itemGuid, true);
	}, []);

	const handleDeletedFieldIndex = () => {
		setDeletedFieldIdx(-1);
	};

	const handleUndoRedoRoot = (itemGuid, isUndo, isDeletion) => {
		const idx = editorFieldsRef.current.findIndex((f) => f.name === itemGuid);
		if (idx < 0) return;
		const newFields = [...editorFieldsRef.current];
		newFields[idx].deleted = isUndo ? !isDeletion : isDeletion;
		setEditorFields(newFields);
		setTriggerEditorChange((triggerEditorChange) => !triggerEditorChange);
	};

	const handleAdoptPublishPreviousMinutes = useCallback((previousMeetingId) => {
		const guid = uuid();

		dispatch(adoptPublishPreviousMinutes(previousMeetingId, guid))
			.then((res) => {
				if (res.status === 200) {
					enqueueSnackbar(t("approveMeetingDialog.success.minutes"));
				} else if (res && res.body && res.body.pdf && res.body.pdf.errors) {
					setAdoptPublishError(previousMeetingId);
				}
			})
			.catch(() => {
				setAdoptPublishError(previousMeetingId);
			});
	}, []);

	const findPolicyAttribute = (relationshipGuid) => {
		for (const key in policyAttachments) {
			if (key === relationshipGuid) {
				if (Object.values(policyAttachments[key]).every((data) => data.removed)) {
					return false;
				}
				return true;
			}
		}
		return false;
	};

	const getItems = () => {
		let isConsentHeading = false;
		let isPublicCommentHeading = false;
		let isMemberOnlyHeading = false;
		let videoExists = false;
		let isPolicyMotion = false;
		if (meeting.purchasedBoxcast && meeting.broadcasts) {
			// future story to allow timestamping against mutiple videos
			if (meeting.broadcasts.length > 0 && meeting.broadcasts[0].youtubeId) {
				videoExists = true;
			}
		} else if (meeting?.youtubeLink && meeting?.youtubeLink != null) {
			videoExists = true;
		}

		const defaultPublicCommentHeading = getDefaultPublicCommentHeading(agendaItems);
		const filteredItems = [];
		const previous = {
			heading: {
				item: null,
				numberOfChildren: 0,
			},
		};
		minutesItems.forEach((item) => {
			if (!item.deleted) {
				filteredItems.push(item);
				if (item.itemType === MinutesItemTypesEnum().HEADING.value) {
					// Top level heading
					previous.heading = {
						item,
						numberOfChildren: !item.attributes.relationshipGuid ? 0 : previous.heading.numberOfChildren,
					};
					addRequestsToSpeak(filteredItems, previous.heading, defaultPublicCommentHeading);
				} else if (previous.heading.item && item.itemType === MinutesItemTypesEnum().ITEM.value) {
					// Count the children of the heading
					previous.heading.numberOfChildren++;
				}
			}
		});

		addRequestsToSpeak(agendaItems, previous.heading, defaultPublicCommentHeading); // Add requests to the last heading if needed
		const lastIndex = filteredItems.length - 1;
		let elementsIndex = 0;
		let domElements = [];

		// Header

		const headerElement = <LiveMeetingHeader key={"toc-header"} isHeader elementsRef={elementsRef} elementsIndex={elementsIndex} />;
		domElements.push(headerElement);
		elementsIndex++;
		filteredItems.map((minutesItem, index) => {
			const item = getAgendaItem(minutesItem);
			isConsentHeading = minutesItem && minutesItem.fields && minutesItem.fields.Consent && minutesItem.fields.Consent.Value;
			isPublicCommentHeading =
				(minutesItem && minutesItem.fields && minutesItem.fields.PublicComment && minutesItem.fields.PublicComment.Value) ||
				(item && item.fields && item.fields.PublicComment && item.fields.PublicComment.Value);
			isMemberOnlyHeading =
				(item && item.fields && item.fields.Closed.Value) || (minutesItem && minutesItem.fields && minutesItem.fields.Closed.Value);
			isPolicyMotion = minutesItem.itemType === 8 && findPolicyAttribute(minutesItem?.attributes?.relationshipGuid);
			let element;
			if (typeof minutesItem.topic === "string") {
				// Request to speak
				element = (
					<LiveMeetingRequestToSpeak
						key={minutesItem.guid}
						requestToSpeak={minutesItem}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						addBottomBorder={index === lastIndex}
						selected={selected}
						handleSelect={handleSelect}
						isMinutesV2
					/>
				);
			} else {
				const parent = findItemByID(minutesItem.attributes.relationshipGuid, agendaItems);
				const editorFieldText = editorFieldsRef.current.find((field) => field.name === `${minutesItem.guid}-text`);
				const policyData = [];
				if (multiplePolicyData) {
					for (const key in multiplePolicyData) {
						if (key === minutesItem.attributes.relationshipGuid) {
							Object.values(multiplePolicyData[key]).forEach((attachment) => {
								if (!attachment.removed) {
									policyData.push(attachment);
								}
							});
							break;
						}
					}
				}

				element = (
					<LiveMeetingItem
						key={minutesItem.guid}
						item={minutesItem}
						isClosedMeeting={meeting.closed}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						isHeading={minutesItem.itemType === MinutesItemTypesEnum().HEADING.value}
						isItem={minutesItem.itemType === MinutesItemTypesEnum().ITEM.value}
						isResolution={minutesItem.itemType === MinutesItemTypesEnum().RESOLUTION.value}
						isHeadingAction={parent && parent.itemType === MinutesItemTypesEnum().HEADING.value}
						isSubHeading={Boolean(minutesItem.attributes.relationshipGuid)}
						addBottomBorder={index === lastIndex}
						videoExists={videoExists}
						meeting={meeting}
						rollCall={rollCall}
						otherRollCallTypes={otherRollCallTypes}
						additionalUsers={additionalUsers}
						minutesItems={minutesItems}
						selected={
							selected &&
							(selected.startsWith(minutesItem.guid) || minutesItem.attachments?.find((attachment) => selected.startsWith(attachment.guid)))
								? selected
								: undefined
						}
						votingData={votingData?.itemInProgress?.guid === minutesItem.guid ? votingData : undefined}
						onlineVoters={onlineVoters}
						votingSettings={votingSettings}
						adoptUpdating={adoptUpdating}
						presenting={presenting}
						active={active?.indexOf(minutesItem.guid) >= 0 ? active : undefined}
						policyData={policyData.length > 0 ? policyData : undefined}
						handleSelect={handleSelect}
						elementsRef={elementsRef}
						elementsIndex={elementsIndex}
						removeText={removeItemText}
						addText={addItemText}
						editorFieldTextDeleted={editorFieldText?.deleted}
						forceUpdate={minutesItem.forceUpdate}
						showSignIn={showSignIn}
						handleUpdateMotion={handleUpdateMotion}
						handleAddMotion={handleAddMotion}
						adoptPublishPreviousMinutes={handleAdoptPublishPreviousMinutes}
						isPolicyMotion={isPolicyMotion}
						signalRClient={client}
					/>
				);
				elementsIndex += 2;
			}

			// We now always create the text element, it is just hidden or shown sometimes
			domElements.push(element);
			return element || <div key={`item${minutesItem.guid}`}>{minutesItem.fields.Name.Value}</div>;
		});

		// Footer
		const footerElement = <LiveMeetingHeader key={"toc-footer"} elementsRef={elementsRef} elementsIndex={elementsIndex} />;
		domElements.push(footerElement);
		elementsIndex++;

		return domElements;
	};

	const scrollToActive = (fieldName, field) => {
		scrollToElement(fieldName);
		const elem = document.getElementById(field ? `${field}-${fieldName}` : `agenda-${fieldName}`);
		if (elem) {
			scrollToElement(elem, document.getElementById("content-scroll"), -48);
		}
		const tocElement = document.getElementById(`outline-minute-${fieldName}`);
		if (tocElement) {
			scrollToElement(tocElement, document.getElementById("minute-outline"));
		}
	};

	const focusEditor = (fieldName, attempts = 0) => {
		const root = window.editor.model.document.getRoot(fieldName);
		let element = null;
		if (root) {
			element = document.querySelectorAll(`[data-fieldname="${fieldName}"]`);
		}
		if (element && element.length > 0) {
			element[0].focus();
			scrollToActive(fieldName);
		} else if (attempts < 10) {
			// Try again
			setTimeout(() => {
				focusEditor(fieldName, attempts + 1);
			}, 100);
		}
	};

	useEffect(() => {
		if (window.editor) {
			handleFieldChange(window.editor);
		}
	}, [triggerEditorChange]);

	useEffect(() => {
		if (active && focus) {
			focusEditor(active);
		} else {
			scrollToActive(active, activeField);
		}
	}, [active, focus, activeField]);

	useEffect(() => {
		setUnpublishedPolicyAttachments(minutesItems);
	}, [minutesItems]);

	useEffect(() => {
		if (minutesItems.length > 0 && Object.keys(policyAttachments).length > 0) {
			minutesItems.forEach((item) => {
				if (policyAttachments[item.guid]) {
					setRemovePropertyForAttachments(item.attachments);
				}
			});
		}
	}, [[...minutesItems]]);

	useEffect(() => {
		const newFields = [];

		// Header
		newFields.push({
			guid: "toc-header",
			name: "toc-header",
			content: header,
			toolbar: "minutesHeader",
			deleted: false,
		});
		minutesItems.forEach((item) => {
			if (!item.deleted) {
				newFields.push({
					guid: item.guid,
					name: item.guid,
					content: item.fields.Name.Value,
					toolbar: item.fields.Closed.Value ? "simpleMO" : "simple",
					isMemberOnly: item.fields.Closed.Value,
					deleted: false,
				});

				newFields.push({
					guid: item.guid,
					name: `${item.guid}-text`,
					content: item.fields.Text.Value || "",
					toolbar: item.fields.Closed.Value ? "minutesItemTextMO" : "minutesItemText",
					isMemberOnly: item.fields.Closed.Value,
					deleted: item.fields.Text.Value === null,
				});
			}
		});

		// Footer
		newFields.push({
			guid: "toc-footer",
			name: "toc-footer",
			content: footer,
			toolbar: "header",
			deleted: false,
		});
		setEditorFields(newFields);
	}, [minutesItems?.length]);

	useEffect(() => {
		let attachmentData = {};
		if (policyAttachmentsMinutes && policyAttachmentsMinutes.length > 0) {
			attachmentData = setSourcePolicyAttachments(policyAttachmentsMinutes);
			attachmentData && setPolicyAttachments((prev) => ({ ...prev, ...attachmentData }));
		}
	}, [policyAttachmentsMinutes]);

	useEffect(() => {
		if (policyAttachments) {
			dispatch({
				type: GET_MULTIPLE_POLICY_ATTACHMENTS_FULFILLED,
				payload: policyAttachments,
			});
		}
	}, [policyAttachments]);

	const setUnpublishedPolicyAttachments = (minutesItems) => {
		let attachmentData = {};
		minutesItems.forEach((item) => {
			if (item.attachments && item.attachments.length > 0) {
				let itemData = setSourcePolicyAttachments(item.attachments);
				attachmentData = { ...attachmentData, ...itemData };
			}
		});
		attachmentData && setPolicyAttachments((prev) => ({ ...prev, ...attachmentData }));
	};

	const setRemovePropertyForAttachments = (attachments) => {
		let tempObj = policyAttachments;
		attachments.forEach((attachment) => {
			let parentObj = tempObj[attachment.itemGuid];
			if (parentObj[attachment.guid]) {
				if (attachment.removed) {
					parentObj[attachment.guid].removed = true;
				} else {
					parentObj[attachment.guid].removed = false;
				}
			}
		});
		setPolicyAttachments(tempObj);
	};

	const setSourcePolicyAttachments = (policyAttachmentsMinutes) => {
		let policyData = {};
		policyAttachmentsMinutes.forEach((attachment) => {
			let tempObj = policyAttachments[attachment.itemGuid] || {};
			if (attachment.isPolicy && attachment?.sourcePolicyGuid) {
				let key = attachment["guid"];
				let tempArray = policyData[attachment.itemGuid] || {};
				policyData[attachment.itemGuid] = { ...tempArray, ...tempObj, [key]: attachment };
			}
		});
		return policyData;
	};

	if (!minutesItems || !agendaItems || !meeting) {
		return <CircularProgressIndicator />;
	}

	return (
		<>
			{editorInitializing && <CircularProgressIndicator />}
			<div
				className={clsx("flex", "direction-column", "agenda-editor-content", {
					["agenda-editor-content-xs"]: !widthUpSm,
					[classes.editorContentSmUp]: widthUpSm,
					//[classes.editorFieldHide]: editorInitializing,
				})}
			>
				<span id="cont" />
				<Container id={"new-editor-toc-header"} maxWidth="lg" className={classes.contentContainer}>
					<List component="ul" disablePadding classes={{ root: "agenda-items-container" }} id="item-list">
						{editorFields.length > 0 && getItems()}
					</List>
				</Container>
				{editorFields.length > 0 && elementsRef.current.length > 0 && (
					<SingletonEditor
						fields={editorFields}
						fieldsRefs={elementsRef}
						editorToolbarRef={editorToolbarRef}
						queueFileUploads={queueFileUploads}
						addPolicy={addPolicy}
						addGoals={addGoals}
						invalidFileExtension={invalidFileExtension}
						onFieldChange={handleFieldChange}
						onEditorInitialized={handleEditorInitialized}
						onFocusChange={handleFocusChange}
						onUndoRedoRoot={handleUndoRedoRoot}
						onDeletedFieldIndex={handleDeletedFieldIndex}
						deletedFieldIdx={deletedFieldIdx}
						meetingId={meeting.id}
						features={{
							id: "MOA",
							label: t("inlineFile.features.MOA.featureLabel"),
							className: "closed",
							anchorTitle: t("inlineFile.features.MOA.anchorTitleMember"),
							tooltipDisabledOn: t("inlineFile.features.MOA.tooltipDisabledOn"),
						}}
					/>
				)}
				{adoptPublishError > 0 && (
					<AdoptPublishErrorDialog
						meetingId={adoptPublishError}
						handleCancel={() => {
							setAdoptPublishError(null);
						}}
					/>
				)}
			</div>
		</>
	);
};

export default MinutesEditor;
