import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import dragAndDropStyle from '../../assets/jss/components/dragAndDropStyle';

const useDragAndDropStyles = makeStyles(dragAndDropStyle);

const DropPlaceholder = React.forwardRef((props, ref) => {
	const { width, height, component: Component = 'div', children } = props;
	const dragAndDropClasses = useDragAndDropStyles({ width, height });

	return (
		<Component ref={ref} className={dragAndDropClasses.dropPlaceholder}>
			{children}
		</Component>
	);
});

export default DropPlaceholder;
