export default {
	meetings: "Meetings",
	allMeetings: "All meetings",
	attachmentVersions: "Attachment Versions",
	documentVersions: "Document Versions",
	meeting: "Meeting",
	upcoming: "Upcoming",
	upcomingMeetings: "Upcoming meetings",
	previous: "Previous",
	recent: "Recent",
	recentMeetings: "Recent meetings",
	date: "Date",
	agenda: "Agenda",
	minutes: "Minutes",
	public: "Public",
	member: "Member",
	members: "Members",
	memberAgenda: "Member agenda",
	publicAgenda: "Public agenda",
	memberMinutes: "Member minutes",
	publicMinutes: "Public minutes",
	previewMembersView: "Members view: {{meetingName}}",
	previewPublicView: "Public view: {{meetingName}}",
	adoptedMembersView: "Members adopted view: {{meetingName}}",
	adoptedPublicView: "Public adopted view: {{meetingName}}",
	preview: "Preview: {{meetingName}}",
	reviewPreview: "Review preview",
	to: "to",
	from: "from",
	filters: "Filters",
	dateRange: "Date Range",
	keyword: "Keyword",
	additionalInformation: "Additional Information",
	meetingType: "Meeting Template",
	meetingTemplate: "Meeting template",
	meetingGroup: "Meeting group",
	startTime: "From",
	endTime: "To",
	name: "Name",
	location: "Location",
	hour: "Hour",
	minute: "Minutes",
	period: "Period",
	durationOptional: "Duration (optional)",
	am: "AM",
	pm: "PM",
	publicSite: "Public site",
	show: "Show",
	hide: "Hide",
	motionText: "Motion text",
	movedBy: "Moved by",
	motion: "Motion",
	secondedBy: "Seconded by",
	disposition: "Disposition",
	results: "Results",
	createMeeting: "Create meeting",
	viewMeeting: "View meeting",
	voteType: "Vote type",
	shared: "Shared",
	notShared: "Not Shared",
	published: "Published",
	notPublished: "Not Published",
	outOfSync: "Out of Sync",
	agendaStatus: "Agenda Status",
	board: "Board",
	editMeetingDetails: "Edit meeting details",
	openAgenda: "Open agenda",
	meetingDetails: "Meeting details",
	minutesNotes: "Note:<br/>{{name}}",
	lastEdited: "Last edited:",
	lastPublished: "Last published:",
	lastShared: "Last shared:",
	membersLastUploaded: "Members last uploaded:",
	publicLastUploaded: "Public last uploaded:",
	membersFileNameUploaded: "Members:",
	publicFileNameUploaded: "Public:",
	adoptedMembersLastUploaded: "Members adopted last uploaded:",
	adoptedMembersFileNameUploaded: "Members adopted:",
	adoptedPublicLastUploaded: "Public adopted last uploaded:",
	adoptedPublicFileNameUploaded: "Public adopted:",
	generatingPreview: "Generating preview",
	generatingMinutes: "Generating minutes",
	updatingMinutes: "Updating minutes",
	keep: "Keep",
	discard: "Discard",
	zoomConferenceLink: "Zoom conference link",
	youTubeBroadcastLink: "YouTube broadcast link",
	video: "Video",
	videos: "Videos",
	Boxcast: "Boxcast",
	YouTube: "YouTube",
	showTimestamps: "Show timestamps",
	showVideo: "Show video",
	currentVideos: "Currently selected video",
	enable: "Enable",
	publicTimestamps: "public timestamps",
	publicTimestampsEnabled: "Public timestamps enabled successfully",
	publicTimestampsDisabled: "Public timestamps disabled successfully",
	broadcastRemovedSuccessfully: "Broadcast removed successfully",
	broadcastUpdatedSuccessfully: "Broadcast updated successfully",
	setStartTimeOfMeeting: "Set Start Time Of Meeting",
	updateStartTimeOfMeeting: "Update Start Time Of Meeting",
	startTimeOfMeetingSaved: "Start time of meeting saved",
	setStartTimeOfMeetingUndone: "Set start time of meeting reverted successfully",
	saveSyncTimestamps: "Save",
	cancelSyncTimestamps: "Cancel",
	clearTimestamp: "Clear",
	setTimestamp: "Set",
	submittedItems_one: "{{count}} submitted item",
	submittedItems_other: "{{count}} submitted items",
	warningPendingChanges: "Warning: Pending changes",
	noDocument: "No Document",
	attachmentPage: "Page {{page}}",
	timeHasElapsed: "Time has elapsed",
	timeOver: "Time over:",
	consentAgendaItemMoved: "Consent agenda item moved",
	warningShowOnPortalPublished: "Cannot disable show on public site when public document is published",
	meetingContainsAnnotations: "Shared document contains annotations",
	errorPublishingMinutes: "Error occurred publishing minutes",
	newMinutes: "New minutes",
	tieBreaker: "Tie breaker",
	addPolicyToAgendaDialog: {
		buttons: {
			addToItem: "Add copy to item",
			addCopyOfDraft: "Add copy of draft",
			addCopiesofDraft: "Add copies of draft",
			addCopy: "Add copy",
			addCopies: "Add copies",
		},
		labels: {
			noPoliciesFound: "No policies found",
		},
		tabs: {
			ariaLabel: "Select a tab to filter the policy list",
			draft: "Draft",
			published: "Published",
		},
		title: "Add policy to agenda item",
	},
	addGoalsToAgendaDialog: {
		buttons: {
			addGoal: "Link to agenda item",
		},
		title: "Link goal to agenda item",
		publishedLabel: "Published",
		InternalLabel: "Internal",
	},
	agendaEdit: {
		minutesOutOfSync: "This agenda cannot be edited as items have been moved in the live meeting",
	},
	annotationsVersionChangeDialog: {
		title: "Keep annotations?",
		listLabel: "You annotated a previous version of:",
		annotationsLocationChange:
			"If content has been added or removed in the current version, annotations may not be in the place you expect.",
		keepAnnotationsConfirm: "Would you like to keep your annotations?",
	},
	copyMoveItemDialog: {
		buttons: {
			copy: "Continue and copy",
			move: "Continue and move",
		},
		line1: "Select the meeting and section to move/copy to and drag items over",
		meetings: "Meeting",
		meetingTypes: "Meeting template",
		snackbar: {
			success: {
				copy: "Items copied to meeting",
				move: "Agenda items moved",
			},
		},
		title: {
			copy: "Copy agenda items",
			move: "Move agenda items",
		},
		tooltips: {
			cancelCopyMove: "Cancel copying or moving this item",
		},
		warningMembersOnlyToPublic: "You are about to copy members only items into a public section. Are you sure you want to continue?",
	},
	noMeetings: {
		upcoming: { title: "No upcoming meetings", description: "We couldn't find any upcoming meetings that you have access to" },
		previous: { title: "No previous meetings", description: "We couldn't find any previous meetings that you have access to" },
	},
	rejectAgendaItem: {
		title: "Reject to submitter",
		snackbar: {
			success: "Agenda item rejected to submitter",
		},
	},
	editAgenda: "Agenda: {{meetingName}}",
	outofsyncWarning: "Warning: you have pending changes.",
	policyDrafts: "New policy drafts available.",
	warningPolicyDrafts: "Warning: New policy drafts available.",
	portalMinutesWarning: "Warning: Minutes manually added through",
	portalMinutesWarningLink: "portal",
	unsavedChanges: "Changes you made may not be saved.",
	unsavedUploading:
		"One or more attachments are currently being uploaded. Navigating away from this page will cause the attachment upload to fail. We recommend staying on this page until the upload is completed.",
	notes: "Notes",
	signAndAdoptTitle: "Sign & adopt: {{meetingName}}",
	adoptedTitle: "Adopted: {{meetingName}}",
	deleteMeetingDialog: {
		title: "Delete meeting",
		line1: "You are about to delete {{name}}.",
		line2: "All meeting information will be removed.",
		buttons: {
			delete: "Delete",
			cancel: "Cancel",
		},
		snackbar: {
			success: "Meeting deleted successfully",
		},
	},
	deleteMotionDialog: {
		title: "Delete resolution",
		line1: "You are about to delete:",
		buttons: {
			delete: "Delete",
			cancel: "Cancel",
		},
	},
	fields: {
		minutes: "Minutes",
		seconds: "Seconds",
	},
	approveMeetingDialog: {
		title: {
			agenda: "Approve agenda",
			minutes: "Adopt minutes",
		},
		label: {
			agenda: "{{meetingName}} agenda",
			minutes: "{{meetingName}} minutes",
		},
		success: {
			agenda: "Agenda approved",
			minutes: "Minutes adopted",
		},
		error: {
			minutes: "Warning: An error occurred while publishing and adopting the minutes.",
		},
	},
	confirmAdoptMinutesDialog: {
		body: "This will overwrite any adopted documents you uploaded.",
	},
	publishMeetingDialog: {
		agendaNotice: "Download agenda notice",
		title: {
			agenda: "Publish agenda",
			minutes: "Publish minutes",
		},
		line1: {
			agenda: "You are about to publish the {{name}} agenda to the public.",
			minutes: "You are about to publish the {{name}} minutes to the public.",
		},
		line1v2: {
			agenda: "You are about to share and publish the {{name}} agenda.",
			minutes: "You are about to share and publish the {{name}} minutes.",
		},
		line2: "Here's what will happen:",
		info: {
			confidential: {
				agenda: "The confidential agenda will be shared with the group members.",
				minutes: "The confidential minutes will be shared with the group members.",
			},
			public: {
				agenda: "The open agenda will be published to the public.",
				minutes: "The open minutes will be published to the public.",
			},
		},
		notify: "Notify subscribers",
		notifyMembers: "Notify members",
		buttons: {
			publish: "Publish",
			cancel: "Cancel",
		},
		snackbar: {
			success: {
				agenda: "Agenda published successfully",
				minutes: "Minutes published successfully",
			},
			successWithShare: {
				agenda: "Agenda shared and published successfully",
				minutes: "Minutes shared and published successfully",
			},
		},
		warningPendingItems: "Warning: You cannot publish this agenda until the {{count}} outstanding agenda items have cleared approval.",
		warningShowOnPortal: "Cannot publish unless show on public site enabled",
	},
	updateMinutesDialog: {
		title: "Updating meeting",
		line1: "You are updating the {{name} - Minutes",
		line2: "The Attached Minutes will be update for:",
		update: "Update",
		cancel: "Cancel",
		memberMinutes: "Member Minutes:",
		publicMinutes: "Public Minutes:",
		snackbar: {
			success: "Update Minutes successfully",
			error: " Error updating minutes",
		},
	},
	publishErrorDialog: {
		contactSupport: "contact support",
		errorType: {
			attachment: {
				button: "Dismiss",
				description: {
					agenda: "The following errors prevented publishing the agenda for {{name}}:",
					minutes: "The following errors prevented publishing the minutes for {{name}}:",
				},
				resolution: {
					title: "To resolve the issue:",
					suggestions: ["Remove password protection from the attachment", "Save a new copy of the attachment and attach that version"],
				},
			},
			badAttachment: {
				button: "Dismiss",
				description: "The following attachments were not uploaded successfully:",
				resolution: {
					title: "To resolve the issue:",
					suggestions: ["Try uploading the attachment again"],
				},
			},
			generic: {
				button: "Dismiss",
				description: {
					agenda: "Errors prevented publishing the agenda for {{name}}.",
					minutes: "Errors prevented publishing the minutes for {{name}}.",
				},
				resolution: {
					title: "To resolve the issue:",
					suggestions: ["Refresh the page and try to publish again"],
				},
			},
			item: {
				button: "Dismiss",
				description: {
					agenda: "The following errors prevented publishing the agenda for {{name}}:",
					minutes: "The following errors prevented publishing the minutes for {{name}}:",
				},
				resolution: {
					title: "To resolve the issue:",
					suggestions: ["Replace the item name (and description, if relevant) by typing it in or by pasting it in as plain text"],
					linkText: "Go to item",
				},
			},
			timeout: {
				button: "Dismiss",
				description: {
					agenda: "The agenda publishing is taking longer than expected for {{name}}:",
					minutes: "The minutes publishing is taking longer than expected for {{name}}:",
				},
				resolution: {
					title: "To resolve the issue:",
					suggestions: ["Check back in a few minutes", " If your document is not published, try publishing again"],
				},
			},
		},
		issuePersists: "If the issue persists, please <1>contact support</1>",
		title: {
			error: "Publishing error",
			badAttachment: "Attachment(s) not uploaded",
			preview: {
				agenda: "Agenda preview not generated",
				minutes: "Minutes preview not generated",
			},
			published: {
				agenda: "Agenda not published",
				minutes: "Minutes not published",
			},
			shared: {
				agenda: "Agenda not shared",
				minutes: "Minutes not shared",
			},
			sharedOrPublished: {
				agenda: "Agenda not shared or published",
				minutes: "Minutes not shared or published",
			},
			timeout: {
				agenda: "Agenda taking longer than expected",
				minutes: "Minutes taking longer than expected",
			},
		},
	},
	rollCall: {
		absent: "Absent",
		attendeesRollCall: "Attendees: Roll call",
		late: "Late",
		members: "Members {{present}}/{{total}}",
		nonVotingAttendees: "Non-voting standard attendees {{present}}/{{total}}",
		present: "Present",
	},
	rollCallQuorumLostDialog: {
		title: "Quorum lost",
		body: "Not enough voting members are present to reach quorum. Motions & voting will be disabled until quorum is reached.",
		buttonText: "Got it",
	},
	pdfViewer: {
		printButton1: "Print High Quality PDF - No Annotations",
		printButton2: "Print with Annotations and Comments",
		downloadLabel: "Download (Current Document)",
	},
	voting: {
		voteInProgress: "Vote in progress",
		for: "For",
		against: "Against",
		abstain: "Abstain",
		absent: "Absent",
		coi: "COI",
		quorumNotMet: "Quorum not met",
		countedVotes: "Counted votes:",
		votingStarted: "Voting started:",
		digitalVotingStarted: "Digital voting started",
		digitalVotingStopped: "Digital voting stopped",
		majority: "Majority",
		votes: "Votes",
		voters: "VOTERS",
		recordedVote: "Recorded vote",
		buttons: {
			resetVote: "Reset vote",
			sendForVote: "Send for vote",
			reSendForVote: "Resend for vote",
			rollCallVote: "Roll call vote",
			showVote: "Show voting",
			hideVote: "Hide voting",
			showResults: "Show results",
			vote: "Vote",
		},
		tooltips: {
			resetVote: "Resets user votes",
			rollCallVote: "Shows voting control",
			sendForVote: "Initiate digital voting",
			userConnected: "User connected",
			userDisconnected: "User disconnected",
			votingNotInRange: "Available the day of the meeting - {{date}}",
			showResults: "Show voting results",
			votingTypes: "Show additional voting types",
			recordedVote: "Adds Voting results to minutes",
		},
		confirmResetVoteDialog: {
			title: "Reset vote",
			body: "Please confirm that you want to reset this vote.",
		},
		confirmChangeVote: {
			title: "Vote change",
			body: "Are you sure you want to change this vote?",
		},
	},
	votingDialog: {
		title: "Voting in progress",
		resultsTitle: "Voting results",
		recordedTitle: "Vote recorded",
		votes: "Votes",
		notice: {
			success: "Successfully voted {{voteType}}",
			error: "Error recording your vote",
			quorumLost: "Quorum has been lost",
		},
	},
	unpublishMeetingDialog: {
		title: {
			agenda: "Unpublish agenda",
			minutes: "Unpublish minutes",
		},
		line1: {
			agenda: "You are about to unpublish the {{name}} agenda.",
			minutes: "You are about to unpublish the {{name}} minutes.",
		},
		line2: {
			agenda: "The agenda will be hidden from the public until you publish it again.",
			minutes: "The minutes will be hidden from the public until you publish it again.",
		},
		buttons: {
			unpublish: "Unpublish",
			cancel: "Cancel",
		},
		snackbar: {
			success: {
				agenda: "Agenda unpublished successfully",
				minutes: "Minutes unpublished successfully",
			},
			republishAgenda: "Public draft minutes unpublished here, but not in the agenda PDF! Please republish agenda to remove.",
			republishAgendaLink: "Republish agenda",
		},
	},
	shareMeetingDialog: {
		title: {
			agenda: "Share agenda",
			minutes: "Share minutes",
		},
		line1: {
			agenda: "You are about to share the {{name}} agenda with the group members.",
			minutes: "You are about to share the {{name}} minutes with the group members.",
		},
		line2: "Here's what will happen:",
		info: {
			confidential: {
				agenda: "The confidential agenda will be shared with the group members.",
				minutes: "The confidential minutes will be shared with the group members.",
			},
		},
		notifyMembers: "Notify members",
		buttons: {
			share: "Share",
			cancel: "Cancel",
		},
		snackbar: {
			success: {
				agenda: "Agenda shared successfully",
				minutes: "Minutes shared successfully",
			},
		},
		warningPendingItems: "Warning: You cannot share this agenda until the {{count}} outstanding agenda items have cleared approval.",
	},
	tabs: {
		webView: "Web View",
		pdf: "PDF",
		publicPdfView: "Public PDF",
		membersPdfView: "Members PDF",
	},
	addToAgendaDialog: {
		createNewAgendaItem: "Create new agenda item",
		noMeetings: "There are no future meetings available",
		options: {
			none: "None",
		},
		snackbar: {
			success: "Minutes added successfully",
		},
		title: "Add minutes to agenda",
		titleAddToMeeting: "Add minutes to meeting",
		addPolicyTitle: "Add Policy to Agenda",
	},
	addBroadcastDialog: {
		boxcastSelectSource: "Select a source and create a new broadcast",
		createNewBroadcast: "Create new broadcast",
		broadcastLoadingBroadcasts: "Loading...",
		broadcastSelectBroadcast: "Select broadcast",
		broadcastSelectAdd: "Add",
		broadcastRemove: "Remove the connected video from this meeting",
		options: {
			none: "None",
		},
		snackbar: {
			success: "Broadcast added successfully",
		},
		title: "Boxcast broadcast selector",
	},
	moveConsentItemDialog: {
		title: "Move item out of consent agenda",
		createNewAgendaSection: "Create new agenda section",
		description: "Move into a new agenda item or select an existing one",
		snackbar: {
			success: "Item moved successfully",
		},
	},
	timerCustomDialog: {
		title: "Set custom timer",
	},
	timerPresetEditDialog: {
		snackbar: {
			success: "The timer presets have been updated.",
		},
		title: "Edit preset timers",
	},
	tooltips: {
		addKeywordsHere: "Add keywords here",
		addNewMotion: "Add a new motion",
		addToAgenda: "Add these minutes to an agenda",
		adoptMinutes: "Adopt the minutes",
		signAdoptMinutes: "Add signature and adopt the minutes",
		printMinutes: "Print minutes with signatures",
		approveMinutes: "Approve the minutes",
		attachmentMemberOnly: "Member only attachment: not visible in the public-facing published agenda",
		attachmentPublic: "Public attachment: visible in the public-facing published agenda",
		closeNotes: "Close notes panel",
		closeMotion: "Close the motion panel",
		closeRollCall: "Close the roll call panel",
		createMeeting: "Create a new meeting",
		deleteMotion: "Delete this motion",
		downloadMinutes: "Download the minutes",
		downloadMinutesToAdopt: "Download minutes document to adopt",
		editMeetingDetails: "Edit meeting details",
		editMinutes: "Edit the minutes",
		filterByTextInMeetingName: "Filter by text in meeting name",
		goToNextItem: "Go to the next item",
		goToPreviousItem: "Go to the previous item",
		headingHasMotions: "This section has related resolutions",
		inProgressGoToLiveMeeting: "Go to the live meeting to edit the minutes",
		itemHasMotions: "This item has related resolutions",
		meetingTemplateCantBeChanged: "Meeting template can't be changed",
		notesMotions: "View and edit notes and resolutions",
		openFilter: "Open the filtering options",
		previewAgenda: "Preview the agenda",
		previewAgendaDraft: "Preview draft",
		previewMinutesDraft: "Preview draft",
		downloadMinutesDraft: "Download draft",
		uploadMinutesDraft: "Upload draft",
		previewMemberAgenda: "Preview the member agenda",
		previewMemberMinutes: "Preview the member minutes",
		previewMinutes: "Preview the minutes",
		previewPublicAgenda: "Preview the public agenda",
		previewPublicMinutes: "Preview the public minutes",
		publicRelease: "Public release - {{date}}",
		publishAgenda: "Publish to Public",
		publishMinutes: "Publish to public",
		rollCall: "View and edit the roll call",
		sendForReview: "Send this draft for review",
		setupVideo: "Set up the video",
		shareAgenda: "Share with members",
		shareMinutes: "Share with members",
		startPresenting: "Start the live meeting presentation",
		stopPresenting: "Stop the live meeting presentation",
		timer: "Set up the timer",
		timerClose: "Stop and close the timer",
		timerPause: "Pause the timer",
		timerStart: "Start the timer",
		timerStop: "Stop and reset the timer",
		toggleTableOfContent: "Toggle table of contents",
		unpublishMinutes: "Unpublishes the minutes from the public",
		uploadAdoptedMinutes: "Upload adopted minutes",
		uploadAdoptedMemberMinutes: "Upload the adopted member minutes",
		uploadAdoptedPublicMinutes: "Upload the adopted public minutes",
		uploadMemberMinutes: "Upload the member minutes",
		uploadMinutes: "Upload the minutes",
		uploadPublicMinutes: "Upload the public minutes",
		viewAllNotes: "View all minutes notes",
		downloadMembersMinutesToAdopt: "Download members minutes pdf document to adopt",
		downloadPublicMinutesToAdopt: "Download public minutes pdf document to adopt",
		uploadMembersMinutesToAdopt: "Upload members minutes pdf document to adopt",
		uploadPublicMinutesToAdopt: "Upload public minutes pdf document to adopt",
		uploadMembersAdoptedMinutes: "Upload adopted members minutes document",
		uploadPublicAdoptedMinutes: "Upload adopted public minutes document",
		updateAdoptedMinutes: "Recreate the adopted minutes",
		adoptMinutesOverflowButton: "Download and upload to add signatures to the PDF version of minutes",
		moveConsentItem: "Move item out of consent agenda section",
		viewFullScreen: "Expand this pdf to full screen",
		uploadInProgress: "Upload in progress",
		broadcastGoToIntermission: "Livestream will be muted and show an overlay image",
		broadcastReenablePublicBroadcast: "Unmute, remove the overlay image, and continue the livestream",
		adoptPublish: "Click to adopt previous meeting minutes document",
		newMinutesOn: "Enables new minutes or disables new minutes",
		openVideoLink: "Open Video Conference link",
		agendaSettings: "Agenda settings",
		newAgendaOn: "Enables new Agenda or disables new Agenda",
		updateMinutes: "Update minutes",
	},
	comparePolicy: {
		emptyDocumentMsg: "Selected Policy is blank and has nothing to compare, select another version",
		unableToLoadDocument: "Unable to load the policy for comparison",
	},
	options: {
		adopted: "Adopted",
		approved: "Approved",
		archived: "Archived",
		denied: "Denied",
		draft: "Draft",
		draftMemberMinutes: "Draft member minutes",
		draftPublicMinutes: "Draft public minutes",
		memberMinutes: "Member minutes",
		notPublished: "Not Published",
		notShared: "Not Shared",
		outOfSync: "Out of sync",
		pending: "Pending",
		publicMinutes: "Public minutes",
		published: "Published",
		scheduled: "Scheduled",
		shared: "Shared",
	},
	buttons: {
		addBroadcast: "Add Broadcast",
		addMeeting: "+ meeting",
		addNewMotion: "Add new motion",
		addToAgenda: "Add to agenda",
		addNewSignatures: "Add new signature(s)",
		calendar: "Choose a date",
		clearAll: "Clear All",
		close: "Close",
		createMeeting: "Create meeting",
		delete: "Delete",
		deleteMotion: "Delete motion",
		editAgenda: "Edit agenda",
		editMeetingDetails: "Edit Details",
		editTimestamps: "Edit Timestamps",
		filter: "FILTER",
		joinVideo: "Join video",
		loadMore: "Load More",
		moreActions: "More actions",
		notesMotions: "Notes/resolutions",
		newWindow: "Opens in new window",
		publish: "Publish",
		removeFilters: "Remove Filters",
		removeSelectedVideo: "Remove the currently selected video before adding a new broadcast",
		rollCall: "Roll call",
		seeAllMeetings: "see all the meetings for {{customer}} here",
		setupVideo: "Set up video",
		share: "Share",
		showHideFilter: "Show/Hide Filter",
		startPresenting: "Start presenting",
		startTimer: "Start timer",
		stopPresenting: "Stop presenting",
		testYouTube: "Test YouTube",
		testZoom: "Test Zoom",
		timer: "Timer",
		toggleTableOfContent: "Table of contents",
		undo: "Undo",
		unpublish: "Unpublish",
		viewDetails: "View details",
		liveMeeting: "Live Meeting",
		launchLiveMeeting: "Launch Live Meeting",
		viewAllNotes: "Minutes",
		downloadDraft: "Download draft",
		downloadMinutesToAdopt: "Download adopted",
		downloadMembersMinutesToAdopt: "Download adopted members minutes",
		downloadPublicMinutesToAdopt: "Download adopted public minutes",
		uploadDraft: "Upload draft",
		uploadAdoptedMinutes: "Upload adopted",
		uploadMembersAdoptedMinutes: "Upload adopted members minutes",
		uploadPublicAdoptedMinutes: "Upload adopted public minutes",
		previewDraft: "Preview draft",
		move: "Move",
		viewFullScreen: "Enter full screen",
		fullScreen: "Full screen",
		print: "Print",
		sendDraft: "Send draft",
		sendForReview: "Send for review",
		broadcastGoToIntermission: "Go To Intermission",
		broadcastReenablePublicBroadcast: "Reenable Public Broadcast",
		goToSignAdopt: "Go to sign and adopt",
		adoptPublish: "Adopt and publish",
		preview: "Preview",
		cancel: "Cancel",
		addToMeeting: "Add to meeting",
		addCopyOfDraft: "Add copy of draft",
		updateMinutes: "Update minutes",
	},
	notifications: {
		emailNotifications: "Notifications",
		meetingFilterApplied: "Meeting filter applied",
		noMatchesFound: "No matches found",
		noMeetingsMatchFilters: "We couldn’t find any meetings that match the filters you set.",
		period: ".",
		meetingCreated: "Meeting created successfully",
		meetingSaved: "Meeting saved successfully",
		noUpcomingMeetings: "We couldn’t find any upcoming meetings for your meeting groups.",
		noRecentMeetings: "We couldn’t find any recent meetings for your meeting groups.",
		notSeeingMeeting: "Not seeing a meeting? You can ",
		minutesUploaded: "Minutes uploaded successfully",
		goToIntermission: "Public broadcast disabled successfully",
		reenablePublicBroadcast: "Public broadcast reenabled successfully",
		signatureSaved: "Signature(s) saved",
		signatureDeleted: "Signature(s) deleted",
		timeStampingVideoError: "Warning: Please change the permissions to this video to access it within Community to edit timestamps",
		timeStampingNoTimestampsMsg:
			"Please set the start time of the meeting manually. To have automatic timestamps use the presentation view during the live meeting.",
	},
	durations: {
		minutes: "minutes",
		hour: "hour",
		hours: "hours",
	},
	errors: {
		required: "{{field}} can't be empty",
		dateCannotBeInThePast: "The date cannot be in the past.",
		timeCannotBeInThePast: "The time cannot be in the past.",
		invalidZoom: "Enter a valid Zoom conference link",
		invalidYoutube: "Enter a valid YouTube broadcast link",
		broadcastGoToIntermission: "Error: Go to intermission failed",
		broadcastReenablePublicBroadcast: "Error: Reenable public broadcast failed",
	},
	liveMeeting: {
		backToMeeting: "Back to {{meetingName}}: {{bullet}}",
		currentTopic: "Current Topic",
		label: "Live Meeting",
		liveMeetingInProgress: "Live meeting in progress:",
		meeting: "{{meetingName}}: {{bullet}}",
		startPresentingNotification:
			"<p>Presenting successfully. We’ve opened the presentation view in another browser window &ndash; you can use that browser window to share the agenda in a video conference or to project the agenda on a screen.</p><p>When you navigate through the agenda, group members and public visitors will automatically see what you have selected. Your selection is also visible in the presentation view.</p>",
		startPresentingNotificationEnd:
			"Timestamps will also be automatically generated as you update the live presentation, if you have a YouTube video associated to the meeting and it is after the meeting start time on the day of the meeting.",
		stopPresentingNotification:
			"<p>Presenting stopped successfully.</p><p>Group members will no longer see what you have selected. The public view and presentation view will show a standard landing page with your logo and the name and date of the meeting.</p>",
		title: "Minutes: {{name}}",
	},
	meetingPreviewDialog: {
		agendaLabel: "{{meetingName}} agenda",
		agendaTitle: "Generating agenda preview",
		minutesLabel: "{{meetingName}} minutes",
		minutesTitle: "Generating minutes preview",
	},
	menu: {
		customTimer: "Custom...",
		deleteMeeting: "Delete meeting",
		editMeetingDetails: "Edit meeting details",
		editPresetTimers: "Edit preset timers...",
		viewMeetingHistory: "View meeting history",
		viewNotificationsHistory: "View notifications history",
	},
	motions: {
		panel: {
			title: "Motion",
			selectItem: "First select an agenda item on the left and then press the add motion button to add a new motion.",
		},
		snackbar: {
			added: "New motion added",
			saved: "Motion data saved",
		},
	},
	deleteMeetingTemplateDialog: {
		title: "Delete meeting template",
		line1: "Are you sure that you want to delete the {{name}} meeting template?",
		line2: "{{meetings}} meetings were created using this template.",
		snackbar: {
			success: "Meeting template {{name}} deleted",
		},
	},
	deleteRollCallDialog: {
		title: "Delete vote type",
		line1: "Are you sure that you want to delete the {{name}} vote type",
	},
	duplicateMeetingTemplateDialog: {
		title: "Duplicate meeting template",
		line1: "Duplicating the {{name}} meeting template.",
	},
	inlineFile: {
		buttons: {
			selectFile: "Select File...",
		},
		labels: {
			editNoFiles: "Select file to upload",
			noUrl: "This link has no URL",
		},
		tooltips: {
			edit: "Edit Attachment",
			remove: "Remove Link",
			open: "Open attachment in new tab",
			editConfirm: "Save",
			editConfirmNoFiles: "Select an attachment to proceed",
			editCancel: "Cancel",
			print: "Print document",
		},
		features: {
			MOA: {
				featureLabel: "Member only",
				anchorTitleMember: "- Member only attachment: not visible in the public-facing published agenda",
				anchorTitlePublic: "- Public attachment: visible in the public-facing published agenda",
				tooltipDisabledOn: "Attachments in a member only section area always visible only to members",
			},
		},
	},
	sendDraftForReviewDialog: {
		titleAgenda: "Send draft agenda for review",
		titleMinutes: "Send draft minutes for review",
		line1: "Here's what will happen:",
		line2Agenda: "You are about to send for review the draft {{name}} agenda to users selected below",
		line2Minutes: "You are about to send for review the draft {{name}} minutes to users selected below",
		quorumWarning: "Warning: Quorum met, you may be breaking open meeting laws please consult policy",
		draftSentForReview: "Draft sent for review",
		accordionTitle: "The draft  will be shared with",
		noMembers: "No members to select. Make sure there are members on this meeting group",
		sent: "Sent?",
	},
	templateDetail: {
		buttons: {
			addRollCall: "Add vote type",
		},
		labels: {
			archived: "Archived",
			archivedEnabled: "Archived meeting template",
			default: "Default",
			description: "Description text",
			durationOptional: "Duration (optional)",
			footer: "Footer",
			hour: "Hour",
			location: "Location",
			meetingDetails: "Meeting Details",
			meetingGroup: "Meeting group",
			memberCount: "Member count: {{count}}",
			membersOnlyMeeting: "Members only meeting",
			minute: "Minutes",
			minutesSettings: "Minutes settings",
			name: "Name",
			period: "Period",
			publicRequestToSpeak: "Public request to speak",
			publicRequestToSpeakEnabled: "Public request to speak enabled",
			publicSite: "Public site",
			quorumNeeded: "Quorum needed",
			quorumValueMemberCount: "Quorum member count",
			quorumValuePercent: "Quorum percentage",
			rollCallName: "Name",
			rollCallType: "Type",
			security: "Security",
			userWeighting: "User vote weighting",
			useWeightedVoting: "Use weighted voting",
			voteType: "Vote type",
			publishSettings: "Publish settings",
			signatures: "Signatures",
		},
		options: {
			majority: "Majority",
			memberCount: "Member count",
			percent: "Percent",
			twoThirdsMajority: "2/3 majority",
		},
		tabs: {
			detail: "Meeting details",
			agenda: "Agenda",
			minutes: "Minutes",
		},
		title: "Meeting template: {{name}}",
		tooltips: {
			archived:
				"Removed the ability to add new meetings with this template. Past meetings still show on the public site if not members only",
			deleteRollCall: "Delete this vote type",
			dragItem: "Drag this item to reorder it",
			minutesOptions: "Toggle the options",
			save: "Save this meeting template",
			agendaSettings: "Agenda settings",
		},
		unsavedChanges: "You have unsaved changes",
		validation: {
			location: "Location required",
			meetingGroup: "Meeting group required",
			name: "Meeting template name required",
			startTime: "Start time required",
		},
	},
	templateList: {
		header: {
			board: "Meeting group",
			location: "Location",
			name: "Meeting template",
			startTime: "Time",
		},
		labels: {
			includeArchived: "Archived templates",
		},
		noResults: {
			description: "We couldn’t find any meeting templates",
			title: "No meeting templates found",
		},
		title: "Meeting templates",
		tooltips: {
			delete: "Delete this meeting template",
			duplicate: "Duplicate this meeting template",
			filter: "Show or hide the filter panel",
			meetingTemplateOptions: "Meeting template options",
		},
		snackbar: {
			copy: {
				success: "Meeting template {{name}} duplicated",
			},
			restore: {
				success: "Meeting template {{name}} restored",
			},
		},
	},
	shortCutKeys: {
		nextAgendaShortCutKey: "alt+z",
		previousAgendaShortCutKey: "alt+a",
		rollCallShortCutKey: "alt+c",
		resultShortCutKey: "alt+r",
		playPauseTimerShortCutKey: "alt+t",
	},
	filterMeetingGroup: "Filter Meeting Group",
	AllMeetingGroups: "All Meeting Groups",
	NumberingSettings: "Numbering Settings",
	unableToDeleteDialog: {
		title: "Unable to delete",
		line1: "Cannot delete this section until all items in approval are moved to another section.",
	},
	publishSettings: {
		alwaysUseRecordedVote: "Always record votes, this will set all voting results in minutes. It remove the option for anonymous votes.",
		minutesAttachment: "Include minutes attachments in PDF.",
		showClosedItems: "Show member only item titles on public minute documents.",
		PublishDraftMinutesPublicly: "Publish Draft minutes publicly when “added to agenda” or “publish” minutes.",
	},
	signatures: {
		input: "Input role for signature",
		addButton: "Add",
		signAddedFor: "Signatures added for:",
		remove: "Remove",
		noSignatureAdded: "No signatures added",
		signatureSucess: "Signature Added Successfully",
	},

	publicMinutesAddedTo: "Public minutes added to:",
	memberMinutesAddedTo: "Members minutes added to:",
	snackbar: {
		success: {
			deleteItem: "Agenda item successfully deleted",
			deleteRecommendation: "Recommendation successfully deleted",
			deleteSection_zero: "Section successfully deleted",
			deleteSection_one: "Section and {{count}} item successfully deleted",
			deleteSection_other: "Section and {{count}} items successfully deleted",
			deleteSubHeading_zero: "Subheading successfully deleted",
			deleteSubHeading_one: "Subheading and {{count}} item successfully deleted",
			deleteSubHeading_other: "Subheading and {{count}} items successfully deleted",
		},
	},
};
