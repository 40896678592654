import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import { blackColor, grayColor, whiteColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";
import ApprovalProgressBlock from "components/Approval/ApprovalProgressBlock";
import { WORKFLOW_STATUS_PENDING } from "utils/workflowStatuses";

const useStyles = makeStyles(() => ({
	container: {
		display: "inline-flex",
		alignItems: "center",
		minWidth: "120px",
		width: (props) => (props.fullWidth ? "100%" : undefined),
		height: "40px",
		padding: "4px",
		borderRadius: "5px",
		backgroundColor: whiteColor,
		boxSizing: "border-box",
		cursor: "pointer",
	},
	containerExpanded: {
		alignItems: (props) => (props.expandDown ? "flex-start" : "center"),
		padding: "0",
		marginBottom: (props) => (props.expandDown ? "48px" : undefined),
		border: "none",
		height: (props) => (props.disableExpandOverlap ? "88px" : undefined),
	},
	list: {
		display: "flex",
		alignItems: "center",
		position: "relative",
		minWidth: "112px",
		width: (props) => (props.fullWidth ? "100%" : undefined),
		margin: "0",
		padding: "0",
		backgroundColor: whiteColor,
		boxSizing: "border-box",
		listStyleType: "none",
		"& > li": {
			display: "inline-block",
			marginRight: "8px",
			"&:last-child": {
				marginRight: "0",
			},
		},
	},
	listExpanded: {
		height: "88px",
		padding: "4px",
		borderRadius: "5px",
		border: `1px solid ${grayColor[4]}`,
		boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
		"& > li": {
			marginRight: "16px",
			"&:last-child": {
				marginRight: "16px",
			},
		},
	},
	listExpandedSingleApprover: {
		justifyContent: "center",
	},
	label: {
		position: "absolute",
		top: "4px",
		left: "4px",
		margin: "0 !important",
		fontSize: "12px",
		lineHeight: "1.25",
		fontWeight: "bold",
		color: blackColor[6],
	},
	scroll: {
		display: "flex !important",
		alignItems: "center",
		cursor: "pointer",
		height: "32px",
	},
	scrollCircle: {
		height: "8px",
		width: "8px",
		boxSizing: "border-box",
		backgroundColor: whiteColor,
		border: `2px solid ${grayColor[1]}`,
		borderRadius: "50%",
	},
	scrollEllipsis: {
		color: grayColor[1],
		height: "30px",
	},
}));
const maxIcons = 8;
const maxExpanedIcons = 3;
const minUsersOrBlocks = 3;
const iconsPerBlock = 3;

const ApprovalProgress = (props) => {
	const {
		workflow,
		expanded: externalExpanded,
		expandDown,
		disableExpandOverlap,
		fullWidth,
		maxIcons: maximumIcons = maxIcons,
		onToggleExpand,
	} = props;
	const { t } = useTranslation("app");

	const containsPending = (block) => block && block.users.find((user) => user.status === WORKFLOW_STATUS_PENDING);

	const setBlockVisibility = (blocks, defaultPosition, isExpanded) => {
		defaultPosition.hiddenAtEndExist = false;

		if (blocks) {
			let visibleIcons = 0;
			let visibleUsersOrBlocks = 0;

			if (blocks.length === 1) {
				// Single block
				const block = blocks[0];

				block.visible = true;
				block.showPendingArrow = false;

				block.users.forEach((user, index) => {
					user.visible =
						index >= defaultPosition.current &&
						(visibleIcons < (isExpanded ? maxExpanedIcons : maximumIcons) || visibleUsersOrBlocks < minUsersOrBlocks);
					// Show only one pending arrow and only after an approved user
					user.showPendingArrow = index > defaultPosition.current && user.status === WORKFLOW_STATUS_PENDING && block.ordered;
					if (index >= defaultPosition.current && !user.visible) {
						defaultPosition.hiddenAtEndExist = true;
					}

					if (user.visible) {
						visibleIcons++;
						visibleUsersOrBlocks++;
					}
				});
			} else {
				// Multiple blocks
				blocks.forEach((block, index) => {
					block.visible =
						index >= defaultPosition.current &&
						(visibleIcons < (isExpanded ? maxExpanedIcons : maximumIcons) || visibleUsersOrBlocks < minUsersOrBlocks);
					// Show only one pending arrow
					block.showPendingArrow = index > defaultPosition.current && containsPending(block);
					if (index >= defaultPosition.current && !block.visible) {
						defaultPosition.hiddenAtEndExist = true;
					}

					if (block.visible) {
						visibleIcons += block.users.length === 1 ? 1 : iconsPerBlock;
						visibleUsersOrBlocks++;
					}

					block.users.forEach((user) => {
						user.visible = true;
						user.showPendingArrow = false;
					});
				});
			}
		}
	};

	const getDefaultPosition = (blocks, isExpanded) => {
		const defaultPosition = {
			current: 0,
			hiddenAtEndExist: false,
			max: 0,
		};

		if (!blocks) {
			return defaultPosition;
		}

		// Give each user a number to use for the avatar background
		let number = 0;
		const numberCache = {};
		blocks.forEach((block) => {
			block.users.forEach((user) => {
				if (typeof user.number === "undefined") {
					if (typeof numberCache[user.id] === "undefined") {
						numberCache[user.id] = number;
						number++;
					}
					user.number = numberCache[user.id];
				}
			});
		});

		let pendingIndex = -1;
		if (blocks.length === 1) {
			pendingIndex = blocks[0].users.findIndex((user) => user.status === WORKFLOW_STATUS_PENDING);

			defaultPosition.max = blocks[0].users.length - (isExpanded ? maxExpanedIcons : maximumIcons); // The maximum index that current can be set to
		} else {
			pendingIndex = blocks.findIndex((block) => containsPending(block));

			// Calculate the maximum index that current can be set to based on the number of icons per block
			const blockIcons = blocks.map((block) => (block.users.length > 1 ? iconsPerBlock : 1));
			let lastBlockVisible = false;
			let max = -1;
			while (!lastBlockVisible) {
				max++;

				let visibleIcons = 0;
				let visibleUsersOrBlocks = 0;
				for (let index = max; index < blockIcons.length; index++) {
					visibleIcons += blockIcons[index];
					visibleUsersOrBlocks++;
					lastBlockVisible = index === blockIcons.length - 1;
					if (visibleIcons >= (isExpanded ? maxExpanedIcons : maximumIcons) && visibleUsersOrBlocks >= minUsersOrBlocks) {
						break;
					}
				}
			}
			defaultPosition.max = max;
		}
		defaultPosition.current = Math.max(Math.min(pendingIndex - 1, defaultPosition.max), 0); // The default current index is one before whichever block or user is pending, but don't exceed max

		setBlockVisibility(blocks, defaultPosition, isExpanded);

		return defaultPosition;
	};

	const [expanded, setExpanded] = useState(typeof externalExpanded === "boolean" ? externalExpanded : false);
	const [position, setPosition] = useState(getDefaultPosition());
	const expandedRef = useRef(false);
	const classes = useStyles({ expandDown, fullWidth, disableExpandOverlap });

	const handleListClick = (e) => {
		setExpanded((prevExpanded) => {
			setPosition((prevPosition) => {
				const newPosition = getDefaultPosition(workflow.blocks, !prevExpanded);
				newPosition.current = Math.max(Math.min(prevPosition.current, newPosition.max), 0); // Calculate current based on updated max

				setBlockVisibility(workflow.blocks, newPosition, !prevExpanded);

				return newPosition;
			});

			if (typeof onToggleExpand === "function") {
				onToggleExpand(!prevExpanded);
			}

			return !prevExpanded;
		});

		e.preventDefault();
		e.stopPropagation();
	};

	const handlePrevious = (e) => updatePosition(e, -1);

	const handleNext = (e) => updatePosition(e, 1);

	const updatePosition = (e, offset) => {
		setPosition((prev) => {
			const newPosition = {
				...prev,
				current: Math.max(Math.min(prev.current + offset, prev.max), 0),
			};

			setBlockVisibility(workflow.blocks, newPosition, expandedRef.current);

			return newPosition;
		});

		e.preventDefault();
		e.stopPropagation();
	};

	const getUserIds = () =>
		workflow ? workflow.blocks.reduce((previous, current) => previous.concat(current.users.map((blockUser) => blockUser.id)), []) : [];

	useEffect(() => {
		expandedRef.current = expanded; // Avoid using an out of date value in updatePosition

		if (expanded) {
			document.addEventListener("click", handleListClick);
		} else {
			document.removeEventListener("click", handleListClick);
		}

		return () => {
			document.removeEventListener("click", handleListClick);
		};
	}, [expanded]);

	// Allow the expanded state to be managed externally
	useEffect(() => {
		setExpanded(externalExpanded);
	}, [externalExpanded]);

	useEffect(() => {
		setPosition(getDefaultPosition((workflow || {}).blocks));
	}, [maximumIcons, workflow, getUserIds().join(",")]);

	const singleBlock = workflow && workflow.blocks.length === 1;
	const singleApprover = singleBlock && workflow.blocks[0].users.length === 1;

	return (
		workflow && (
			<div
				className={clsx(classes.container, {
					[classes.containerExpanded]: expanded,
				})}
				data-cy={`approval-progress-${workflow.id}`}
			>
				<ol
					id={`workflow-${workflow.id}`}
					className={clsx(classes.list, {
						[classes.listExpanded]: expanded,
						[classes.listExpandedSingleApprover]: expanded && singleApprover,
					})}
					onClick={handleListClick}
				>
					{expanded && (
						<li key={`workflow-${workflow.id}-label`} className={classes.label} data-cy="approval-progress-label">
							{t("workflows.progress.label")}
						</li>
					)}
					{position.current > 0 && (
						<li
							key={`workflow-${workflow.id}-previous`}
							className={classes.scroll}
							role="button"
							aria-label={t("workflows.toolTips.scrollPrevious")}
							tabIndex="0"
							onClick={handlePrevious}
						>
							<Icon name="previous-bubble" size="8" color={grayColor[2]} />
						</li>
					)}
					{workflow.blocks
						.filter((block) => block.visible)
						.map((block) => (
							<ApprovalProgressBlock
								key={`workflow-block-${block.id}`}
								block={block}
								usersOnly={singleBlock || block.users.length === 1}
								expanded={expanded}
							/>
						))}
					{position.hiddenAtEndExist && (
						<li
							key={`workflow-${workflow.id}-next`}
							className={classes.scroll}
							role="button"
							aria-label={t("workflows.toolTips.scrollNext")}
							tabIndex="0"
							onClick={handleNext}
						>
							<Icon name="next-bubble" size="8" color={grayColor[2]} />
						</li>
					)}
				</ol>
			</div>
		)
	);
};

export default ApprovalProgress;
