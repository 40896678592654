import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useDroppable } from "@dnd-kit/core";

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, ListItem, ListItemText, Typography } from "@mui/material";

import { blackColor } from "atlas/assets/jss/shared";
import tableOfContentsStyle from "assets/jss/components/tableOfContentsStyle";
import dragAndDropStyle from "atlas/assets/jss/components/dragAndDropStyle";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import { Check } from "components/Icons";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import { EditorFunctionsContext } from "contexts/EditorFunctions/EditorFunctionsContext";
import telemetryAddEvent from "utils/telemetryAddEvent";
import TOCAttachments from "./TOCAttachments";
import TOCPrimaryText from "components/Meeting/TOCPrimaryText";

const useTableOfContentsStyles = makeStyles(tableOfContentsStyle);
const useDragAndDropStyles = makeStyles(dragAndDropStyle);

const TOCAgendaItem = (props) => {
	const {
		item: { fields },
		item,
		active = {},
		isMemberOnlySection,
		isConsentSection,
		isPublicCommentSection,
		toggleTableOfContent,
		isSmallDevice,
		parserOptions,
		isClosedMeeting,
		idPrefix = "outline-agenda",
		button,
		checkbox: { className: checkboxClassName, checked, onCheckChange } = {},
		checkbox,
		lineLimit,
		hideAttachments,
		canDrag,
		dragPlaceholder,
		dragPresentational,
		canDrop,
		handleCancelMoveCopy,
		telemetryPage,
	} = props;
	const { t } = useTranslation("agendaMenu");
	const { setActiveItem = () => {} } = useContext(EditorFunctionsContext) || {};
	const tableOfContentsClasses = useTableOfContentsStyles({ lineLimit });
	const dragAndDropClasses = useDragAndDropStyles();
	const ListItemComponent = canDrag ? Draggable : ListItem;
	const dragProps = canDrag ? { dragId: item.guid, dragComponent: ListItem } : {};
	const { setNodeRef } = canDrop
		? useDroppable({
				id: item.guid,
		  })
		: {};

	return (
		!item.deleted && (
			<>
				<ListItemComponent
					id={!dragPresentational ? `${idPrefix}-${item.guid}` : undefined}
					className={clsx(tableOfContentsClasses.listItem, {
						[dragAndDropClasses.dragPlaceholder]: dragPlaceholder,
						[tableOfContentsClasses.copyMoved]: item.copied,
						[tableOfContentsClasses.closedChanged]: item.original && item.original.closed && !item.fields.Closed.Value,
					})}
					button={button}
					component={dragPresentational ? "div" : "li"}
					disableGutters
					divider={!dragPresentational}
					classes={{
						root: clsx("outline-item-secondary", {
							"member-only-section": isMemberOnlySection,
							"consent-section-outline": isConsentSection,
							"public-comment-section-outline": isPublicCommentSection,
							"closed-meeting": isClosedMeeting,
						}),
					}}
					onClick={
						button
							? () => {
									telemetryAddEvent(`${telemetryPage} - Outline navigation`);

									setActiveItem(item.guid);
									if (isSmallDevice) {
										toggleTableOfContent();
									}
							  }
							: undefined
					}
					selected={active.selected === item.guid}
					{...dragProps}
					ref={setNodeRef}
				>
					<>
						{(canDrag || dragPresentational) && <DragHandle />}
						{checkbox && (
							<Checkbox
								className={checkboxClassName}
								checkedIcon={<Check fontSize="small" color="primary" />}
								checked={checked}
								onChange={(e) => onCheckChange(e, item)}
							/>
						)}
						<Typography variant="h5" component="span" className="outline-number">
							{item.number}
						</Typography>
						<ListItemText
							className={clsx({
								[tableOfContentsClasses.limitLines]: Boolean(lineLimit),
							})}
							primaryTypographyProps={{ variant: "h5" }}
							primary={
								<TOCPrimaryText
									text={fields.Name.Value}
									parserOptions={parserOptions}
									showTruncationTooltip={Boolean(lineLimit) && !dragPresentational}
									truncationThreshold={50}
								></TOCPrimaryText>
							}
						/>
						{item.copied && (
							<div className={tableOfContentsClasses.cancelCopyMove}>
								<AccessibleIconButton
									iconName="remove"
									iconColor={blackColor[1]}
									tooltipText={t("meetings:copyMoveItemDialog.tooltips.cancelCopyMove")}
									onClick={() => handleCancelMoveCopy(item.guid)}
									dataCy={`cancel-${item.guid}`}
								/>
							</div>
						)}
					</>
				</ListItemComponent>
				{!hideAttachments && (
					<TOCAttachments
						attachments={item.attachments}
						item={item}
						itemGuid={item.guid}
						isMemberOnlySection={isMemberOnlySection}
						isConsentSection={isConsentSection}
						isPublicCommentSection={isPublicCommentSection}
						toggleTableOfContent={toggleTableOfContent}
						telemetryPage={telemetryPage}
					/>
				)}
			</>
		)
	);
};

export default React.memo(TOCAgendaItem);
