import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { format, parseISO } from "date-fns";

import makeStyles from "@mui/styles/makeStyles";

import { SettingsContext } from "contexts/Settings/SettingsContext";
import BoardAgendaStatusesEnum from "utils/enums/BoardAgendaStatuses";
import PublicAgendaStatusesEnum from "utils/enums/PublicAgendaStatuses";
import BoardMinutesStatusesEnum from "utils/enums/BoardMinutesStatuses";
import PublicMinutesStatusesEnum from "utils/enums/PublicMinutesStatuses";
import { isShared, isPublished } from "utils/agendaStatuses";
import { formatDate } from "utils/date";
import { isShared as isMinutesShared, isPublished as isMinutesPublished } from "utils/minutesStatuses";
import telemetryAddEvent from "utils/telemetryAddEvent";

import StatusLine from "./StatusLine";

const useStyles = makeStyles({
	container: {
		// eslint-disable-next-line no-nested-ternary
		marginTop: (props) => (props.topMargin >= 3 ? "24px" : props.topMargin === 2 ? "16px" : props.topMargin === 0 ? "0" : "20px"), // This may only need the last option once Atlas is implemented everywhere
		verticalAlign: "middle",
	},
	statusContainer: {
		width: (props) => (props.width ? props.width : "100%"),
		borderCollapse: "collapse",
		"& > div.table-row": {
			"& > div.table-cell": {
				borderTop: "solid 3px transparent",
				borderRight: "solid 16px transparent",

				"&:last-child": {
					borderRight: "none",
				},
			},
			"&:first-child": {
				"& > div.table-cell": {
					borderTop: "none",
					borderBottom: "8px solid transparent",
				},
			},
		},
	},
});

const MeetingPublishStatus = (props) => {
	const {
		agenda = true,
		topMargin = 0,
		width,
		boardStatus,
		publicStatus,
		publicReleaseDay,
		boardHtmlId,
		meetingId,
		draftReview,
		telemetryPage,
	} = props;
	const { t } = useTranslation("meetings");
	const { container, statusContainer } = useStyles({ topMargin, width });
	const { dateFormat } = useContext(SettingsContext);

	const openBoard = (id, draft) => {
		if (draft) {
			telemetryAddEvent(`${telemetryPage} - Navigate to draft review agenda`);

			window.open(`/home/meeting/draftreview/${id}/agenda/members`);
		} else if (agenda) {
			telemetryAddEvent(`${telemetryPage} - Navigate to shared agenda`);

			window.open(boardHtmlId ? `/home/document/${boardHtmlId}` : `/home/meeting/document/${id}`);
		} else {
			telemetryAddEvent(`${telemetryPage} - Navigate to shared minutes`);

			window.open(boardHtmlId ? `/home/document/${boardHtmlId}` : `/home/meeting/final/${id}/minutes/members`);
		}
	};

	const openPublic = (id, draft) => {
		if (draft) {
			telemetryAddEvent(`${telemetryPage} - Navigate to draft review agenda`);

			window.open(`/home/meeting/draftreview/${id}/agenda/public`);
		} else if (agenda) {
			telemetryAddEvent(`${telemetryPage} - Navigate to published agenda`);

			window.open(`/Portal/MeetingInformation.aspx?Org=Cal&Id=${id}`);
		} else {
			telemetryAddEvent(`${telemetryPage} - Navigate to published minutes`);

			window.open(`/Portal/MeetingInformation.aspx?Org=Cal&Id=${id}&minutes=true`);
		}
	};

	const getBoardStatusText = (status) => {
		if (agenda) {
			switch (status) {
				case BoardAgendaStatusesEnum().NOTSHARED.value:
					return t(`app:${BoardAgendaStatusesEnum().NOTSHARED.label}`);
				case BoardAgendaStatusesEnum().FINALSHARED.value:
				case BoardAgendaStatusesEnum().SHARED.value:
					return t(`app:${BoardAgendaStatusesEnum().SHARED.label}`);
				case BoardAgendaStatusesEnum().OUTOFSYNC.value:
					return t(`app:${BoardAgendaStatusesEnum().OUTOFSYNC.label}`);
				default:
					return t(`app:${BoardAgendaStatusesEnum().NOTSHARED.label}`);
			}
		} else {
			switch (status) {
				case BoardMinutesStatusesEnum().NOTSHARED.value:
					return t(`app:${BoardMinutesStatusesEnum().NOTSHARED.label}`);
				case BoardMinutesStatusesEnum().SHARED.value:
					return t(`app:${BoardMinutesStatusesEnum().SHARED.label}`);
				case BoardMinutesStatusesEnum().OUTOFSYNC.value:
					return t(`app:${BoardMinutesStatusesEnum().OUTOFSYNC.label}`);
				case BoardMinutesStatusesEnum().APPROVED.value:
					return t(`app:${BoardMinutesStatusesEnum().APPROVED.label}`);
				case BoardMinutesStatusesEnum().ADOPTED.value:
					return t(`app:${BoardMinutesStatusesEnum().ADOPTED.label}`);
				case BoardMinutesStatusesEnum().ADDEDTOAGENDA.value:
					return t(`app:${BoardMinutesStatusesEnum().ADDEDTOAGENDA.label}`);
				default:
					return t(`app:${BoardMinutesStatusesEnum().NOTSHARED.label}`);
			}
		}
	};

	const getPublicStatusText = (status, releaseDay) => {
		if (releaseDay) {
			return t("app:enums.statuses.release", { date: format(parseISO(releaseDay), dateFormat) });
		}

		if (agenda) {
			switch (status) {
				case PublicAgendaStatusesEnum().NOTPUBLISHED.value:
					return t(`app:${PublicAgendaStatusesEnum().NOTPUBLISHED.label}`);
				case PublicAgendaStatusesEnum().FINALPUBLISHED.value:
				case PublicAgendaStatusesEnum().PUBLISHED.value:
					return t(`app:${PublicAgendaStatusesEnum().PUBLISHED.label}`);
				case PublicAgendaStatusesEnum().OUTOFSYNC.value:
					return t(`app:${PublicAgendaStatusesEnum().OUTOFSYNC.label}`);
				default:
					return t(`app:${PublicAgendaStatusesEnum().NOTPUBLISHED.label}`);
			}
		} else {
			switch (status) {
				case PublicMinutesStatusesEnum().NOTPUBLISHED.value:
					return t(`app:${PublicMinutesStatusesEnum().NOTPUBLISHED.label}`);
				case PublicMinutesStatusesEnum().PUBLISHED.value:
					return t(`app:${PublicMinutesStatusesEnum().PUBLISHED.label}`);
				case PublicMinutesStatusesEnum().OUTOFSYNC.value:
					return t(`app:${PublicMinutesStatusesEnum().OUTOFSYNC.label}`);
				case PublicMinutesStatusesEnum().APPROVED.value:
					return t(`app:${PublicMinutesStatusesEnum().APPROVED.label}`);
				case PublicMinutesStatusesEnum().ADOPTED.value:
					return t(`app:${PublicMinutesStatusesEnum().ADOPTED.label}`);
				case PublicMinutesStatusesEnum().ADDEDTOAGENDA.value:
					return t(`app:${PublicMinutesStatusesEnum().ADDEDTOAGENDA.label}`);
				default:
					return t(`app:${PublicMinutesStatusesEnum().NOTPUBLISHED.label}`);
			}
		}
	};

	const getPublicStatusTooltip = (releaseDay) => {
		if (releaseDay) {
			return t("tooltips.publicRelease", { date: formatDate(releaseDay, releaseDay, null, t("app:at"), "", "", false, true, false) });
		}

		return null;
	};

	const getStatusColor = (status) => {
		if (agenda) {
			switch (status) {
				case BoardAgendaStatusesEnum().NOTSHARED.value:
					return BoardAgendaStatusesEnum().NOTSHARED.color;
				case BoardAgendaStatusesEnum().FINALSHARED.value:
				case BoardAgendaStatusesEnum().SHARED.value:
					return BoardAgendaStatusesEnum().SHARED.color;
				case BoardAgendaStatusesEnum().OUTOFSYNC.value:
					return BoardAgendaStatusesEnum().OUTOFSYNC.color;
				default:
					return BoardAgendaStatusesEnum().NOTSHARED.color;
			}
		} else {
			switch (status) {
				case BoardAgendaStatusesEnum().NOTSHARED.value:
					return BoardAgendaStatusesEnum().NOTSHARED.color;
				case BoardAgendaStatusesEnum().SHARED.value:
					return BoardAgendaStatusesEnum().SHARED.color;
				case BoardAgendaStatusesEnum().OUTOFSYNC.value:
					return BoardAgendaStatusesEnum().OUTOFSYNC.color;
				case BoardMinutesStatusesEnum().APPROVED.value:
					return BoardMinutesStatusesEnum().APPROVED.color;
				case BoardMinutesStatusesEnum().ADOPTED.value:
					return BoardMinutesStatusesEnum().ADOPTED.color;
				case PublicMinutesStatusesEnum().ADDEDTOAGENDA.value:
					return PublicMinutesStatusesEnum().ADDEDTOAGENDA.color;
				case BoardMinutesStatusesEnum().ADDEDTOAGENDA.value:
					return BoardMinutesStatusesEnum().ADDEDTOAGENDA.color;
				default:
					return BoardAgendaStatusesEnum().NOTSHARED.color;
			}
		}
	};

	return (
		<div className={container} data-cy={`meetingStatus${meetingId}`}>
			<div className={clsx(statusContainer, "table")}>
				{boardStatus && (
					<StatusLine
						label={t(agenda ? "memberAgenda" : "memberMinutes")}
						statusColor={getStatusColor(boardStatus)}
						statusText={getBoardStatusText(boardStatus)}
						sharedOrPublished={agenda ? isShared(boardStatus) : isMinutesShared(boardStatus)}
						draftReview={
							draftReview && draftReview.members && !isShared(boardStatus) && !isPublished(publicStatus)
								? { status: t("reviewPreview"), statusColor: BoardAgendaStatusesEnum().SHARED.color }
								: undefined
						}
						openMethod={openBoard}
						meetingId={meetingId}
						agenda
						data-cy={`shared${agenda ? "Agenda" : "Minutes"}Status`}
						boardStatus={boardStatus}
					/>
				)}
				{publicStatus && (
					<StatusLine
						label={t(agenda ? "publicAgenda" : "publicMinutes")}
						statusColor={getStatusColor(publicStatus)}
						statusText={getPublicStatusText(publicStatus, publicReleaseDay)}
						tooltip={getPublicStatusTooltip(publicReleaseDay)}
						sharedOrPublished={agenda ? isPublished(publicStatus) : isMinutesPublished(publicStatus)}
						draftReview={
							draftReview && draftReview.public && !isPublished(publicStatus) && !isShared(boardStatus)
								? { status: t("reviewPreview"), statusColor: BoardAgendaStatusesEnum().SHARED.color }
								: undefined
						}
						openMethod={openPublic}
						meetingId={meetingId}
						agenda
						data-cy={`published${agenda ? "Agenda" : "Minutes"}Status`}
					/>
				)}
			</div>
		</div>
	);
};

MeetingPublishStatus.propTypes = {
	agenda: PropTypes.bool,
	width: PropTypes.string,
	topMargin: PropTypes.number,
	meetingId: PropTypes.number.isRequired,
	boardStatus: PropTypes.number,
	publicStatus: PropTypes.number,
	telemetryPage: PropTypes.string,
};

MeetingPublishStatus.defaultProps = {
	agenda: true,
	width: "",
	topMargin: 0,
	boardStatus: undefined,
	publicStatus: undefined,
	telemetryPage: "",
};

export default MeetingPublishStatus;
