import React from "react";
import PropTypes from "prop-types";

import LiveMeetingAttachment from "./LiveMeetingAttachment";

const LiveMeetingAttachments = (props) => {
	const {
		attachments,
		item,
		isClosedMeeting,
		isMemberOnlyHeading,
		isConsentHeading,
		addBottomBorder,
		selected,
		handleSelect,
		presentation,
		noMargin,
	} = props;

	const filteredAttachments = attachments.filter((attachment) => !attachment.removed && !attachment.deleted);
	const lastIndex = filteredAttachments.length - 1;

	return filteredAttachments.map((attachment, index) => (
		<LiveMeetingAttachment
			key={attachment.guid}
			attachment={attachment}
			item={item}
			isClosedMeeting={isClosedMeeting}
			isMemberOnlyHeading={isMemberOnlyHeading}
			isConsentHeading={isConsentHeading}
			addBottomBorder={addBottomBorder && index === lastIndex}
			selected={selected && selected.startsWith(attachment.guid) ? selected : undefined}
			handleSelect={handleSelect}
			presentation={presentation}
			noMargin={noMargin}
		/>
	));
};

LiveMeetingAttachments.propTypes = {
	attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
	isClosedMeeting: PropTypes.bool,
	isMemberOnlyHeading: PropTypes.bool,
	isConsentHeading: PropTypes.bool,
	isSubHeading: PropTypes.bool,
	addBottomBorder: PropTypes.bool,
	handleSelect: PropTypes.func,
};

LiveMeetingAttachments.defaultProps = {
	isClosedMeeting: false,
	isMemberOnlyHeading: false,
	isConsentHeading: false,
	isSubHeading: false,
	addBottomBorder: false,
	handleSelect: undefined,
};

export default LiveMeetingAttachments;
