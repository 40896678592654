import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Icon from '../Icon/Icon';
import AccessibleIconButton from '../Buttons/AccessibleIconButton';

import { blackColor, warningColor, infoColor, successColor, errorColor } from '../../assets/jss/shared';

import notificationStyle from '../../assets/jss/components/notificationStyle';

const useNotificationStyle = makeStyles(notificationStyle);

const Notification = React.forwardRef((props, ref) => {
	const { key, severity = 'success', action, actionLabel, actionIconName, iconName, message, persist = false, dismissAction } = props;
	const { t } = useTranslation();
	const classes = useNotificationStyle();
	let iconColor = successColor;

	useEffect(() => {
		const note = document?.querySelector("div[class*='notistack-SnackbarContainer']");
		if (note) {
			note.style.zIndex = '10001';
		}
	}, []);
	switch (severity) {
		case 'error':
			iconColor = errorColor;
			break;
		case 'info':
			iconColor = infoColor;
			break;
		case 'warning':
			iconColor = warningColor;
			break;
		default: {
			iconColor = successColor;
		}
	}
	let actionProp = null;
	if (action && (actionLabel || actionIconName)) {
		actionProp = (
			<Box ml={1} component="span">
				<Link
					ml={3}
					component="button"
					className={classes.action}
					onClick={(e) => {
						action(e);
						dismissAction(key);
					}}
					data-cy="snackbarAction"
				>
					{actionIconName && <Icon name={actionIconName} color={blackColor[1]} />}
					<div className={classes.actionsLabels}>
					{actionLabel && actionLabel}
					</div>
				</Link>
			</Box>
		);
	}
	return (
		<Alert classes={classes} elevation={6} icon={false} severity={severity} ref={ref} data-cy="notification">
			{iconName && <Icon name={iconName} color={iconColor} size="16px" />}
			{message}
			{actionProp && actionProp}
			{persist && (
				<AccessibleIconButton
					id="notification-close"
					onClick={() => dismissAction(key)}
					iconName="close"
					tooltipText={t('buttons.close')}
					dataCy="notification-close"
				/>
			)}
		</Alert>
	);
});

Notification.propTypes = {
	severity: PropTypes.string,
	action: PropTypes.func,
	actionLabel: PropTypes.string,
	actionIconName: PropTypes.string,
	iconName: PropTypes.string,
	message: PropTypes.string,
};

Notification.defaultProps = {
	severity: 'success',
	action: undefined,
	iconName: undefined,
	message: undefined,
	actionLabel: undefined,
	actionIconName: undefined,
};

export default Notification;