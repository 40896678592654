import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import map from "lodash/fp/map";

import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";

import Tooltip from "atlas/components/Tooltip/Tooltip";
import { EditorFunctionsContext } from "contexts/EditorFunctions/EditorFunctionsContext";
import { Paperclip, Locked } from "components/Icons";
import { getAttachmentLinkText } from "utils/meetingElement";
import telemetryAddEvent from "utils/telemetryAddEvent";

const TOCAttachments = (props) => {
	const {
		attachments,
		item,
		itemGuid,
		isMemberOnlySection,
		isConsentSection,
		isPublicCommentSection,
		toggleTableOfContent,
		isSmallDevice,
		telemetryPage,
	} = props;
	const { t } = useTranslation("meetings");
	const { setActiveItem = () => {} } = useContext(EditorFunctionsContext) || {};

	return map((file) => {
		if (typeof file.linkText === "undefined") {
			file.linkText = getAttachmentLinkText(item, file.guid);
		}

		let attachmentText = file.name || file.description;
		if (file.linkText && file.linkText !== attachmentText) {
			attachmentText = `${file.linkText} (${attachmentText})`;
		}

		return (
			!file.removed && (
				<ListItem
					id={`outline-attachment-${file.guid}`}
					button
					component="li"
					disableGutters
					divider
					classes={{
						root: clsx("outline-item-secondary", {
							"member-only-section": isMemberOnlySection,
							"consent-section-outline": isConsentSection,
							"public-comment-section-outline": isPublicCommentSection,
						}),
					}}
					onClick={() => {
						telemetryAddEvent(`${telemetryPage} - Outline navigation`);

						setActiveItem(itemGuid);
						if (isSmallDevice) {
							toggleTableOfContent();
						}
					}}
					key={`outline-attachment-${file.guid}`}
				>
					<Tooltip
						placement="top"
						style={{ maxWidth: "" }}
						title={
							isMemberOnlySection || (!isMemberOnlySection && file.closed)
								? t("tooltips.attachmentMemberOnly")
								: t("tooltips.attachmentPublic")
						}
					>
						<ListItemText primaryTypographyProps={{ variant: "h5" }} primary={attachmentText} />
					</Tooltip>
					<ListItemSecondaryAction>
						{(isMemberOnlySection || (!isMemberOnlySection && file.closed)) && <Locked fontSize="small" />}
						{!isMemberOnlySection && !file.closed && <Paperclip fontSize="small" />}
					</ListItemSecondaryAction>
				</ListItem>
			)
		);
	}, attachments);
};

export default TOCAttachments;
