import React, { useRef } from "react";
import { useSelector } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

import { useWidthDown, useWidthUp } from "atlas/utils/useWidth";
import { setActive } from "redux/agendaBuilder/actions";
import TableOfContents from "components/Meeting/TableOfContents";
import AgendaSingletonEditor from "./AgendaSingletonEditor";
import TableOfContentsContainer from "views/MeetingTemplate/components/TableOfContentsContainer";
import { getSidebarWidth } from "../functions/utils";

const useStyles = makeStyles(() => ({
	tableOfContentContainer: {
		overflow: "auto",
		maxWidth: (props) => getSidebarWidth(props.wideSidebar),
		minWidth: (props) => getSidebarWidth(props.wideSidebar),
		height: "calc(100vh - 212px)",
		position: "fixed",
		borderRight: "1px solid #E6E6E6",
		paddingBottom: "8px",
	},
	stickyToolbar: {
		position: "-webkit-sticky",
		position: "sticky",
		top: 0,
		zIndex: 150,
		marginLeft: (props) => (props.showOutline ? getSidebarWidth(props.wideSidebar) : "0"),
		width: (props) => (props.showOutline ? `calc(100% - ${getSidebarWidth(props.wideSidebar)})` : "100%"),
		minWidth: "200px",
	},
}));

const telemetryPage = "Meeting Template Agenda";

const AgendaEditorV2 = (props) => {
	const { meetingId, showNotes, updateMeeting, addPolicy, addGoals, queueFileUploads, requestedItemGuid } = props;
	const widthDownMd = useWidthDown("md");
	const widthUpXl = useWidthUp("xl");
	const editorToolbarRef = useRef(null);
	const classes = useStyles({ showOutline: !widthDownMd, wideSidebar: widthUpXl });
	const { agenda: { items } = {} } = useSelector((state) => state.agendaBuilderReducer);

	return (
		<>
			<TableOfContentsContainer containerClass={classes.tableOfContentContainer} innerId="outline">
				<TableOfContents items={items} isClosed={true} buttons setActive={setActive} telemetryPage={telemetryPage} />
			</TableOfContentsContainer>
			<Box>
				<Box id="toolbar" className={classes.stickyToolbar} ref={editorToolbarRef} />
				<AgendaSingletonEditor
					queueFileUploads={queueFileUploads}
					showNotes={showNotes}
					editorToolbarRef={editorToolbarRef}
					id={meetingId}
					updateMeeting={updateMeeting}
					addPolicy={addPolicy}
					addGoals={addGoals}
					requestedItemGuid={requestedItemGuid}
				/>
			</Box>
		</>
	);
};

export default AgendaEditorV2;
