import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useDroppable } from "@dnd-kit/core";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Checkbox, ListItem, ListItemText, Typography } from "@mui/material";

import { blackColor } from "atlas/assets/jss/shared";
import tableOfContentsStyle from "assets/jss/components/tableOfContentsStyle";
import dragAndDropStyle from "atlas/assets/jss/components/dragAndDropStyle";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import { Check, Locked } from "components/Icons";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import { EditorFunctionsContext } from "contexts/EditorFunctions/EditorFunctionsContext";
import telemetryAddEvent from "utils/telemetryAddEvent";
import TOCAttachments from "./TOCAttachments";
import TOCPrimaryText from "components/Meeting/TOCPrimaryText";

const useTableOfContentsStyles = makeStyles(tableOfContentsStyle);
const useDragAndDropStyles = makeStyles(dragAndDropStyle);

const TOCHeading = (props) => {
	const {
		section: { fields },
		section,
		active = {},
		isSubHeading,
		isMemberOnlySection,
		isConsentSection,
		isPublicCommentSection,
		notReallyAnItem,
		toggleTableOfContent,
		isSmallDevice,
		parserOptions,
		isClosedMeeting,
		idPrefix = "outline-agenda",
		button,
		checkbox: { className: checkboxClassName, checked, onCheckChange } = {},
		checkbox,
		lineLimit,
		hideAttachments,
		canDrag,
		dragPlaceholder,
		dragPresentational,
		canDrop,
		handleCancelMoveCopy,
		telemetryPage,
	} = props;
	const { t } = useTranslation("agendaMenu");
	const { setActiveItem = () => {} } = useContext(EditorFunctionsContext) || {};
	const tableOfContentsClasses = useTableOfContentsStyles({ lineLimit });
	const dragAndDropClasses = useDragAndDropStyles();
	const ListItemComponent = canDrag ? Draggable : ListItem;
	const dragProps = canDrag ? { dragId: section.guid, dragComponent: ListItem } : {};
	const { setNodeRef } = canDrop
		? useDroppable({
				id: section.guid,
		  })
		: {};

	return (
		!section.deleted && (
			<>
				{isMemberOnlySection && !isSubHeading && (
					<li className="member-only-toc-header">
						<Box className="margin-top-bottom-auto" color="common.white">
							<Locked fontSize="small" className="toc-locked-icon" />
						</Box>
						<Typography variant="body2" className="margin-top-bottom-auto" component="div">
							<Box color="common.white">{t("memberOnlySection")}</Box>
						</Typography>
					</li>
				)}
				{isConsentSection && !isSubHeading && (
					<li className="consent-section-toc-header">
						<Typography variant="body2" className="margin-top-bottom-auto" component="div">
							<Box>{t("consentSection")}</Box>
						</Typography>
					</li>
				)}
				{isPublicCommentSection && !isSubHeading && (
					<li className="public-comment-section-toc-header">
						<Typography variant="body2" className="margin-top-bottom-auto" component="div">
							<Box>{t("publicCommentSection")}</Box>
						</Typography>
					</li>
				)}
				<ListItemComponent
					id={!dragPresentational ? `${idPrefix}-${section.guid}` : undefined}
					className={clsx(tableOfContentsClasses.listItem, {
						[dragAndDropClasses.dragPlaceholder]: dragPlaceholder,
						[tableOfContentsClasses.copyMoved]: section.copied,
						[tableOfContentsClasses.closedChanged]: section.original && section.original.closed && !section.fields.Closed.Value,
					})}
					button={button}
					component={dragPresentational ? "div" : "li"}
					disableGutters
					divider={!dragPresentational}
					classes={{
						root: clsx("outline-item", {
							"outline-sub-heading": isSubHeading,
							"outline-heading": !isSubHeading,
							"member-only-section": isMemberOnlySection,
							"consent-section-outline": isConsentSection,
							"public-comment-section-outline": isPublicCommentSection,
							"closed-meeting": isClosedMeeting,
						}),
					}}
					onClick={
						button
							? () => {
									telemetryAddEvent(`${telemetryPage} - Outline navigation`);

									setActiveItem(section.guid);
									if (isSmallDevice) {
										toggleTableOfContent();
									}
							  }
							: undefined
					}
					selected={active.selected === section.guid}
					{...dragProps}
					ref={setNodeRef}
				>
					{notReallyAnItem ? (
						<ListItemText primaryTypographyProps={{ variant: "h5" }} primary={fields.Name.Value} />
					) : (
						<>
							{(canDrag || dragPresentational) && <DragHandle />}
							{checkbox && (
								<Checkbox
									className={checkboxClassName}
									checkedIcon={<Check fontSize="small" color="primary" />}
									checked={checked}
									onChange={(e) => onCheckChange(e, section)}
								/>
							)}
							<Typography variant="h5" component="span" className="outline-number">
								{section.number}
							</Typography>

							<ListItemText
								className={clsx({
									[tableOfContentsClasses.limitLines]: Boolean(lineLimit),
								})}
								primaryTypographyProps={{ variant: "h5" }}
								primary={
									<TOCPrimaryText
										text={fields.Name.Value}
										parserOptions={parserOptions}
										showTruncationTooltip={Boolean(lineLimit) && !dragPresentational}
										truncationThreshold={50}
									></TOCPrimaryText>
								}
							/>
							{section.copied && (
								<div className={tableOfContentsClasses.cancelCopyMove}>
									<AccessibleIconButton
										iconName="remove"
										iconColor={blackColor[1]}
										tooltipText={t("meetings:copyMoveItemDialog.tooltips.cancelCopyMove")}
										onClick={() => handleCancelMoveCopy(section.guid)}
										dataCy={`cancel-${section.guid}`}
									/>
								</div>
							)}
						</>
					)}
				</ListItemComponent>
				{!hideAttachments && (
					<TOCAttachments
						attachments={section.attachments}
						item={section}
						itemGuid={section.guid}
						isMemberOnlySection={isMemberOnlySection}
						isConsentSection={isConsentSection}
						isPublicCommentSection={isPublicCommentSection}
						toggleTableOfContent={toggleTableOfContent}
						isSmallDevice={isSmallDevice}
						telemetryPage={telemetryPage}
					/>
				)}
			</>
		)
	);
};

export default React.memo(TOCHeading);
