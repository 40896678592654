import React from "react";
import { useTranslation } from "react-i18next";
import { useDroppable } from "@dnd-kit/core";

import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import minutesStyle from "assets/jss/components/minutesStyle";
import InputLabel, { SIZE_LARGE } from "atlas/components/FormControls/InputLabel";

const useStyle = makeStyles(minutesStyle);

export const ADD_HEADING_ABOVE = "above";
export const ADD_HEADING_BELOW = "below";

const HeaderFooter = (props) => {
	const { isHeader = false, label, elementsRef, elementsIndex, afterElementRefSet, addHeadingPosition, handleAddHeading, canDrop } = props;
	const { t } = useTranslation();
	const classes = useStyle();
	const { setNodeRef } = canDrop
		? useDroppable({
				id: isHeader ? "toc-header" : "toc-footer",
		  })
		: {};

	const getAddHeading = () => (
		<div>
			<Button variant="outlined" onClick={handleAddHeading} data-cy="add-heading">
				{t("agendaMenu:addHeader")}
			</Button>
		</div>
	);

	return (
		<>
			<li id={isHeader ? "header" : "footer"} className="agenda-header" style={{ marginBottom: "8px", marginTop: "8px" }} ref={setNodeRef}>
				{addHeadingPosition === ADD_HEADING_ABOVE && handleAddHeading ? getAddHeading() : null}
				{label ? <InputLabel htmlFor="footer" label={label} size={SIZE_LARGE} /> : null}
				<Box
					id="footer"
					className={classes.editorFieldContent}
					data-fieldname={isHeader ? "toc-header" : "toc-footer"}
					ref={(el) => {
						afterElementRefSet(elementsIndex);

						return (elementsRef.current[elementsIndex] = el);
					}}
				>
					<div className={classes.editorFieldPlaceHolder}></div>
				</Box>
				{addHeadingPosition === ADD_HEADING_BELOW && handleAddHeading ? getAddHeading() : null}
			</li>
		</>
	);
};

export default React.memo(HeaderFooter);
