const tableOfContentsStyle = {
	listItem: {
		position: "relative",
		"& .MuiListItemText-root": {
			marginTop: "0px !important",
			marginBottom: "0px !important",
		},
		"& [data-role='drag-handle']": {
			top: "16px",
			left: "2px",
			display: "none",
		},
		"&:hover [data-role='drag-handle']": {
			display: "block",
		},
	},
	limitLines: {
		display: "-webkit-box",
		"-webkit-box-orient": "vertical",
		"-webkit-line-clamp": (props) => (props && props.lineLimit ? props.lineLimit : 2),
		overflow: "hidden",
	},
	copyMoved: {
		backgroundColor: "#edf5fA",
	},
	closedChanged: {
		backgroundColor: "#f3bbbb",
	},
	cancelCopyMove: {
		marginRight: "-16px",
	},
	selected: {
		borderLeft: "6px solid #5d7599 !important",
	},
};

export default tableOfContentsStyle;
