import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Box, ListItem, ListItemText, Typography } from "@mui/material";

import tableOfContentsStyle from "assets/jss/components/tableOfContentsStyle";
import { Locked } from "components/Icons";
import telemetryAddEvent from "utils/telemetryAddEvent";
import TOCAttachments from "./TOCAttachments";
import TOCPrimaryText from "./TOCPrimaryText";
import useAttachmentsHighlight from "../PdfViewerNew/utils/useAttachmentsHighlight";

const useTableOfContentsStyles = makeStyles(tableOfContentsStyle);

const TOCHeading = (props) => {
	const {
		section: { fields },
		section,
		isSubHeading,
		isMemberOnlySection,
		isConsentSection,
		isPublicCommentSection,
		notReallyAnItem,
		toggleTableOfContent,
		isSmallDevice,
		parserOptions,
		isClosedMeeting,
		idPrefix = "outline",
		button,
		selected,
		setActive,
		telemetryPage,
		setDataForAttachmentDocument,
		openGoalsPopup,
		setDataForOutlineItem,
		isPdfSidebar,
		selectedId,
	} = props;
	const { t } = useTranslation("agendaMenu");
	const dispatch = useDispatch();
	const tableOfContentsClasses = useTableOfContentsStyles({ lineLimit: 2 });
	const [clickedOnAttachment, setClickedOnAttachment, clickedOnAttachmentInSidePanel] = useAttachmentsHighlight(false);

	return (
		!section.deleted && (
			<>
				{isMemberOnlySection && !isSubHeading && (
					<li className="member-only-toc-header">
						<Box className="margin-top-bottom-auto" color="common.white">
							<Locked fontSize="small" className="toc-locked-icon" />
						</Box>
						<Typography variant="body2" className="margin-top-bottom-auto" component="div">
							<Box color="common.white">{t("memberOnlySection")}</Box>
						</Typography>
					</li>
				)}
				{isConsentSection && !isSubHeading && (
					<li className="consent-section-toc-header">
						<Typography variant="body2" className="margin-top-bottom-auto" component="div">
							<Box>{t("consentSection")}</Box>
						</Typography>
					</li>
				)}
				{isPublicCommentSection && !isSubHeading && (
					<li className={`${isPdfSidebar ? "public-comment-section-toc-header-pdfsidebar" : "public-comment-section-toc-header"}`}>
						<Typography variant="body2" className="margin-top-bottom-auto" component="div">
							<Box>{t("publicCommentSection")}</Box>
						</Typography>
					</li>
				)}
				<ListItem
					id={`${idPrefix}-${section.guid}`}
					data-cy={`${idPrefix}-${section.guid}`}
					className={clsx(tableOfContentsClasses.listItem, {
						[tableOfContentsClasses.closedChanged]: section.original && section.original.closed && !section.fields.Closed.Value,
					})}
					button={button}
					role=""
					component={"li"}
					disableGutters
					divider
					classes={{
						root: clsx("outline-item", {
							"outline-sub-heading": isSubHeading,
							"outline-heading": !isSubHeading,
							"member-only-section": isMemberOnlySection,
							"consent-section-outline": isConsentSection,
							"public-comment-section-outline-pdfsidebar": isPublicCommentSection && isPdfSidebar,
							"public-comment-section-outline": isPublicCommentSection && !isPdfSidebar,
							"closed-meeting": isClosedMeeting,
							"pdfSideBar pdfSideBarHeading": isPdfSidebar,
							hyperlinkAttachment: isPdfSidebar && selectedId && selectedId.includes(section.guid) && !clickedOnAttachment,
							[tableOfContentsClasses.selected]: selected && selected.includes(section.guid),
						}),
					}}
					onClick={
						button && isPdfSidebar
							? (e) => {
									setDataForOutlineItem(section);
									setClickedOnAttachment(false);
									dispatch(setActive(section.guid, true));
							  }
							: button
							? () => {
									telemetryAddEvent(`${telemetryPage} - Outline navigation`);

									dispatch(setActive(section.guid, true));
									if (isSmallDevice) {
										toggleTableOfContent();
									}
							  }
							: undefined
					}
					selected={selected && selected.includes(section.guid)}
				>
					{notReallyAnItem ? (
						<ListItemText primaryTypographyProps={{ variant: "h5" }} primary={fields.Name.Value} />
					) : (
						<>
							<Typography variant="h5" component="span" className="outline-number">
								{fields.Number.Value}
							</Typography>

							<ListItemText
								className={clsx({
									[tableOfContentsClasses.limitLines]: true,
								})}
								primaryTypographyProps={{ variant: "h5" }}
								primary={
									<TOCPrimaryText
										text={fields.Name.Value}
										parserOptions={parserOptions}
										showTruncationTooltip
										truncationThreshold={isPdfSidebar ? 23 : 50}
										isPdfSidebar={isPdfSidebar}
									></TOCPrimaryText>
								}
							/>
						</>
					)}
				</ListItem>
				<TOCAttachments
					attachments={section.attachments}
					item={section}
					itemGuid={section.guid}
					isMemberOnlySection={isMemberOnlySection}
					isConsentSection={isConsentSection}
					isPublicCommentSection={isPublicCommentSection}
					toggleTableOfContent={toggleTableOfContent}
					isSmallDevice={isSmallDevice}
					setActive={setActive}
					telemetryPage={telemetryPage}
					setDataForAttachmentDocument={setDataForAttachmentDocument}
					isPdfSidebar={isPdfSidebar}
					clickedOnAttachmentInSidePanel={clickedOnAttachmentInSidePanel}
					selectedId={selectedId}
					openGoalsPopup={openGoalsPopup}
				/>
			</>
		)
	);
};

export default React.memo(TOCHeading);
