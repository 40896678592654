export default {
	signIn: "Connexion",
	username: "Nom d'utilisateur",
	password: "Mot de passe",
	showPasswordIcon: "Montrer de passe",
	hidePasswordIcon: "Cacher de passe",

	forgotPassword: {
		checkInbox: "Regarder votre boîte de réception!",
		email: "Courriel",
		instruction: "Entrez votre adresse courriel et nous vous enverrons des instructions pour réinitialiser votre mot de passe.",
		sentRequest:
			"Si <1>{{email}}</1> est le courriel associé au compte {{productName}}, nous vous enverrons un courriel avec des instructions pour réinitialiser votre mot de passe.",
		sentRequest2: "Si vous ne recevez pas de courriel, vérifier vos courriels indésirables.",
	},
	resetPassword: {
		enterPassword: "Enter your new password",
		newPassword: "New password",
		confirmNewPassword: "Confirm new password",
		helper8Characters: "Eight characters minimum",
		helperPasswordMatch: "Must match new password",
		tooltip: "Fix the errors above to proceed",
		tokenExpired: "Your password reset link expired or has already been used.",
	},
	buttons: {
		signIn: "Connexion",
		forgotPassword: "Mot de passe oublié?",
		sendInstructions: "Envoyer Les Instructions",
		returnSignIn: "Retourner à la page de connexion",
	},
	errors: {
		wrongUsernamePassword:
			"Nous n'avons pas trouvé de compte qui correspond à ce nom d'utilisateur et ce mot de passe. S'il-vous-plait, essayez de nouveau.",
		emailRequired: "Le courriel est requis.",
		invalidEmail: "Le courriel saisi n'est pas valide.",
		passwordMatch: "Passwords don't match",
		password8Characters: "Must be at least eight characters",
		passwordComplexity: "Must contain a mix of upper and lower case letters, numbers, and special characters",
		passwordContainsPii: "Must not contain your name, user name, or email address",
		passwordCommon: "Must not be a commonly used password",
		required: "{{fieldName}} can't be empty",
	},
};
