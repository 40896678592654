import { Plugin } from "@ckeditor/ckeditor5-core";
import Collection from "@ckeditor/ckeditor5-utils/src/collection";

import ViewModel from "@ckeditor/ckeditor5-ui/src/model";
import { addListToDropdown, createDropdown } from "@ckeditor/ckeditor5-ui/src/dropdown/utils";

const rollCallPlaceHolders = [
	{
		label: "[Present] - Gets replaced with the list of those present",
		value: "[Present]",
	},
	{
		label: "[Late] - Gets replaced with the list of those late",
		value: "[Late]",
	},
	{
		label: "[Absent] - Gets replaced with the list of those absent",
		value: "[Absent]",
	},
	{
		label: "[CouncilPresent] - Gets replaced with the list of those present",
		value: "[CouncilPresent]",
	},
	{
		label: "[CouncilLate] - Gets replaced with the list of those late",
		value: "[CouncilLate]",
	},
	{
		label: "[CouncilAbsent] - Gets replaced with the list of those absent",
		value: "[CouncilAbsent]",
	},
	{
		label: "[StaffPresent] - Gets replaced with the list of those present",
		value: "[StaffPresent]",
	},
	{
		label: "[StaffLate]  - Gets replaced with the list of those late",
		value: "[StaffLate]",
	},
	{
		label: "[StaffAbsent] - Gets replaced with the list of those absent",
		value: "[StaffAbsent]",
	},
];

export default class InsertRollCall extends Plugin {
	init() {
		const { editor } = this;

		editor.ui.componentFactory.add("insertRollCall", (locale) => {
			const dropdownView = createDropdown(locale);
			dropdownView.buttonView.set({
				icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <path fill="black" fillRule="evenodd" d="M9.067 1.617c2.446 0 4.307 1.613 4.412 4.388l.004.248V8.82c0 1.66-.44 3.114-1.3 4.129l-.166.185v.547c0 .231.014.31.075.398.18.26.71.512 1.755.674l.232.034.013.001c1.453.22 2.486.846 3.123 1.758.456.652.635 1.303.664 1.807l.004.147v1.467a.75.75 0 0 1-.648.743l-.102.007H1a.75.75 0 0 1-.743-.649l-.007-.101V18.5l.009-.115c.075-.482.303-1.152.782-1.826.676-.95 1.674-1.594 3.013-1.772 1.283-.172 1.865-.438 2.016-.69.03-.052.043-.106.046-.246V13.17c-.91-.961-1.406-2.397-1.46-4.04l-.006-.31V6.253c0-2.927 1.898-4.636 4.417-4.636zm0 1.5c-1.662 0-2.831 1-2.912 2.92l-.005.216V8.82c0 1.526.442 2.786 1.215 3.473a.75.75 0 0 1 .244.454l.008.106v.901c0 .466-.044.749-.258 1.11-.44.739-1.414 1.184-3.107 1.41-.909.12-1.545.531-1.988 1.154a3.279 3.279 0 0 0-.501 1.085l-.013.055v.649h14.633V18.5c0-.24-.1-.668-.397-1.094-.398-.57-1.064-.974-2.112-1.133-1.574-.211-2.535-.646-3.015-1.34-.246-.355-.324-.656-.34-1.069l-.002-.184v-.863a.75.75 0 0 1 .251-.56c.73-.65 1.16-1.77 1.21-3.156l.005-.281V6.253c0-2.061-1.193-3.136-2.916-3.136zm7.333-1.5c2.203 0 4.05 1.45 4.05 3.903v2.131a4.93 4.93 0 0 1-1.467 3.478v.39c0 .198.01.238.07.308.177.201.64.414 1.49.584 1.037.207 1.837.718 2.41 1.433.464.576.696 1.152.782 1.573l.015.15v1.466a.75.75 0 0 1-.75.75h-3.667a.75.75 0 0 1 0-1.5h2.917v-.63a2.5 2.5 0 0 0-.466-.87c-.36-.449-.856-.765-1.534-.9-1.145-.23-1.89-.573-2.323-1.066-.36-.41-.444-.77-.444-1.3V10.8a.75.75 0 0 1 .264-.57 3.428 3.428 0 0 0 1.203-2.583V5.52c0-1.534-1.107-2.403-2.565-2.403a2.513 2.513 0 0 0-1.638.564.75.75 0 1 1-.948-1.162 4.003 4.003 0 0 1 2.601-.902z" /></svg>',
				tooltip: true,
				label: "Add Roll Call Placeholder",
			});

			dropdownView.set({
				panelPosition: "se",
			});

			dropdownView.extendTemplate({
				attributes: {
					class: ["ck-placeholders-dropdown"],
				},
			});

			const items = new Collection();
			rollCallPlaceHolders.forEach((placeholder) => {
				items.add({
					type: "button",
					model: new ViewModel({
						withText: true,
						label: placeholder.label,
						commandParam: placeholder.value,
					}),
				});
			});

			addListToDropdown(dropdownView, items);

			dropdownView.on("execute", (evt) => {
				console.log("execute");
				editor.model.change((writer) => {
					const textElement = writer.createText(evt.source.commandParam);
					editor.model.insertContent(textElement, editor.model.document.selection);
				});
			});

			return dropdownView;
		});
	}
}
