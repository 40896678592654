/* eslint-disable no-plusplus */
import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import request from "superagent";

import LeftNavigationListItem from "atlas/components/Navigation/LeftNavigationListItem";
import LeftNavBar from "atlas/components/Layout/LeftNavBar";
import { API_HOST } from "config/env";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { UserContext } from "contexts/User/UserContext";
import { FOLDER_TYPE_CUSTOM } from "utils/enums/DocumentFolderTypes";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { sortTextFieldCaseInsensitive, sortBooleanField, sortNumericField } from "utils/sort";
import { getPolicies } from "redux/policyGovernance/actions";

const sortDefault = (a, b) => {
	let sort = sortBooleanField(a, b, "folder", true);
	if (sort === 0) {
		sort = sortNumericField(a, b, "folderType");
	}

	return sort !== 0 ? sort : sortTextFieldCaseInsensitive(a, b, "title");
};

const LeftNavigation = (props) => {
	const { open, setOpen, closeOnAction, reload = {} } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const { organizationName, lite, salesSite, requestToSpeak, policyUrl, policyEnabled, communityMeetings } = useContext(SettingsContext);
	const {
		boardAdmin,
		boardStaff,
		requestToSpeakAdmin,
		boardMember,
		portalAdmin,
		userAdmin,
		workflowAdmin,
		documentLibrary: { viewInternal, viewRecycleBin },
		policySite,
		systemAdministrator,
		publicUser,
	} = useContext(UserContext).user;
	const { viewRecycleBin: viewRecycleBinForPolicy } = policySite || {};
	const [menus, setMenus] = useState({});
	const [customFolders, setCustomFolders] = useState(null);
	const [customFoldersForPolicy, setCustomFoldersForPolicy] = useState(null);
	const dispatch = useDispatch();
	const pendingAgendaItemApprovalsCount = useSelector((state) => state.appReducer.pendingAgendaItemApprovalsCount);
	const requestsToSpeakCount = useSelector((state) => state.appReducer.requestsToSpeakCount);

	// Users that are only board members cannot add agenda items
	const canAddAgendaItems = !publicUser && (boardStaff || boardAdmin || systemAdministrator || !boardMember);

	const loadCustomFolders = () => {
		request
			.get(`${API_HOST}/api/documents`)
			.query({ types: [FOLDER_TYPE_CUSTOM].join(",") })
			.then((res) => {
				setCustomFolders(res.body.sort(sortDefault));
			})
			.catch(() => {});
	};

	const loadCustomFoldersForPolicy = () => {
		if (policyEnabled) {
			dispatch(getPolicies())
				.then((res) => {
					setCustomFoldersForPolicy(res.sort(sortDefault));
				})
				.catch(() => {});
		}
	};

	const openPage = (url) => {
		navigate(url);

		if (open && closeOnAction) {
			setOpen(!open);
		}
	};

	const openNewTab = (url) => {
		window.open(url);

		if (open && closeOnAction) {
			setOpen(!open);
		}
	};

	const handleKeyboardNavigation = (e) => {
		e.stopPropagation();

		const currentIndex = parseInt(e.target.getAttribute("data-menuindex"), 10);

		if (e.key === "ArrowUp") {
			const prevElement = document.querySelectorAll(`[data-menuindex="${currentIndex - 1}"]`);
			if (prevElement.length) prevElement[0].focus();
			e.preventDefault();
		}
		if (e.key === "ArrowDown") {
			const nextElement = document.querySelectorAll(`[data-menuindex="${currentIndex + 1}"]`);
			if (nextElement.length) nextElement[0].focus();
			e.preventDefault();
		}
	};

	const closeMenu = () => {
		setMenus({});
	};

	const getDocumentLibraryOptions = (customFolders) => {
		const options = [
			{
				id: "document-library-public",
				label: t("menu.public"),
				ariaLabel: t("menu.tooltips.documentLibraryPublic"),
				navigateUrl: `${API_HOST}/home/documents/public`,
				actionFunction: () => {
					openPage("/documents/public");
				},
				"data-cy": "document-library-public",
			},
		];
		if (!lite.enabled) {
			options.push({
				id: "document-library-internal",
				label: t("menu.internal"),
				ariaLabel: t("menu.tooltips.documentLibraryInternal"),
				navigateUrl: `${API_HOST}/home/documents/internal`,
				actionFunction: () => {
					openPage("/documents/internal");
				},
				"data-cy": "document-library-internal",
				hidden: !viewInternal,
			});

			if (customFolders) {
				customFolders.forEach((customFolder) => {
					const url = `/documents/custom/${customFolder.guid}`;

					options.push({
						id: `document-library-custom-${customFolder.guid}`,
						label: customFolder.title,
						ariaLabel: t("menu.tooltips.documentLibraryCustom", { title: customFolder.title }),
						navigateUrl: `${API_HOST}/home${url}`,
						actionFunction: () => {
							openPage(url);
						},
						"data-cy": `document-library-custom-${customFolder.guid}`,
					});
				});
			}
		}
		options.push({
			id: "document-library-recycle-bin",
			label: t("menu.recycleBin"),
			ariaLabel: t("menu.tooltips.documentLibraryRecycleBin"),
			navigateUrl: `${API_HOST}/home/documents/recyclebin`,
			actionFunction: () => {
				openPage("/documents/recyclebin");
			},
			"data-cy": "document-library-recycle-bin",
			hidden: !viewRecycleBin,
		});

		customFolders;

		return options;
	};

	const getPolicySiteOptions = (customFolders) => {
		const options = [];
		if (customFoldersForPolicy) {
			customFoldersForPolicy.forEach((customFolder) => {
				const url = `/policy/policybook/${customFolder.guid}`;

				options.push({
					id: `document-library-custom-${customFolder.guid}`,
					label: customFolder.title,
					ariaLabel: t("menu.tooltips.policyCustom", { title: customFolder.title }),
					navigateUrl: `${API_HOST}/home${url}`,
					actionFunction: () => {
						openPage(url);
					},
					"data-cy": `policy-custom-${customFolder.guid}`,
				});
			});
		}
		options.push({
			id: "policy-site-recycle-bin",
			label: t("menu.recycleBin"),
			ariaLabel: t("menu.tooltips.policyRecycleBin"),
			navigateUrl: `${API_HOST}/home/policy/recyclebin`,
			actionFunction: () => {
				openPage("/policy/recyclebin");
			},
			"data-cy": "policy-site-recycle-bin",
			hidden: !viewRecycleBinForPolicy,
		});

		customFolders;

		return options;
	};

	const getSettingsOptions = () => {
		const options = [];
		if (!communityMeetings) {
			if (userAdmin) {
				options.push({
					id: "users",
					label: t("menu.users"),
					ariaLabel: t("menu.tooltips.users"),
					navigateUrl: `${API_HOST}/home/users`,
					actionFunction: () => {
						openPage("/users");
					},
					newTab: true,
					"data-cy": "users",
				});
				if (policyEnabled) {
					options.push({
						id: "policy-settings",
						label: t("menu.policySettings"),
						ariaLabel: t("menu.tooltips.policySettings"),
						navigateUrl: `${API_HOST}/home/policy/settings`,
						actionFunction: () => {
							openPage("/policy/settings");
						},
						newTab: true,
						"data-cy": "policy-settings",
					});
				}
				options.push({
					id: "general-settings",
					label: t("menu.generalSettings"),
					ariaLabel: t("menu.tooltips.generalSettings"),
					navigateUrl: `${API_HOST}/home/generalsettings`,
					actionFunction: () => {
						openPage("/generalsettings");
					},
					newTab: true,
					"data-cy": "general-settings",
				});
			}
			if (systemAdministrator || portalAdmin) {
				options.push({
					id: "portal-styles",
					label: t("menu.portalStyles"),
					ariaLabel: t("menu.tooltips.portalStyles"),
					navigateUrl: `${API_HOST}/home/settings?tab=portalStyles`,
					actionFunction: () => {
						openPage("/settings?tab=portalStyles");
					},
					newTab: true,
					"data-cy": "portal-styles",
				});
			}
			if (systemAdministrator || (salesSite && userAdmin)) {
				options.push({
					id: "features",
					label: t("menu.features"),
					ariaLabel: t("menu.tooltips.features"),
					navigateUrl: `${API_HOST}/home/settings?tab=features`,
					actionFunction: () => {
						openPage("/settings?tab=features");
					},
					newTab: true,
					"data-cy": "features",
				});
			}
		} else {
			if (userAdmin) {
				options.push({
					id: "users",
					label: t("menu.users"),
					ariaLabel: t("menu.tooltips.users"),
					navigateUrl: `${API_HOST}/home/users`,
					actionFunction: () => {
						openPage("/users");
					},
					newTab: true,
					"data-cy": "users",
				});

				options.push({
					id: "boards",
					label: t("menu.boards"),
					ariaLabel: t("menu.tooltips.boards"),
					navigateUrl: `${API_HOST}/home/boards`,
					actionFunction: () => {
						openPage("/boards");
					},
					newTab: true,
					"data-cy": "boards",
				});

				options.push({
					id: "meeting-templates",
					label: t("menu.meetingTemplates"),
					ariaLabel: t("menu.tooltips.meetingTemplates"),
					navigateUrl: `${API_HOST}/home/meetingtemplates`,
					actionFunction: () => {
						openPage("/meetingtemplates");
					},
					newTab: true,
					"data-cy": "meeting-templates",
				});

				if (boardAdmin || userAdmin) {
					options.push({
						id: "voting",
						label: t("menu.voting"),
						ariaLabel: t("menu.tooltips.voting"),
						navigateUrl: `${API_HOST}/home/settings?tab=voting`,
						actionFunction: () => {
							openPage("/settings?tab=voting");
						},
						newTab: true,
						"data-cy": "voting",
					});
				}

				if (userAdmin && !lite.enabled) {
					options.push({
						id: "request-to-speak",
						label: t("menu.requestToSpeakSettings"),
						ariaLabel: t("menu.tooltips.requestToSpeakSettings"),
						navigateUrl: `${API_HOST}/home/settings?tab=requestToSpeak`,
						actionFunction: () => {
							openPage("/settings?tab=requestToSpeak");
						},
						newTab: true,
						"data-cy": "request-to-speak",
					});
				}
			}

			if (workflowAdmin && !lite.enabled) {
				options.push({
					id: "workflows",
					label: t("menu.workflows"),
					ariaLabel: t("menu.tooltips.workflows"),
					navigateUrl: `${API_HOST}/home/workflows`,
					actionFunction: () => {
						openPage("/workflows");
					},
					newTab: true,
					"data-cy": "workflows",
				});
			}

			if (userAdmin && !lite.enabled) {
				options.push({
					id: "cover-report-templates",
					label: t("menu.coverReportTemplates"),
					ariaLabel: t("menu.tooltips.coverReportTemplates"),
					navigateUrl: `${API_HOST}/home/workflows?tab=1`,
					actionFunction: () => {
						openPage("/workflows?tab=1");
					},
					newTab: true,
					"data-cy": "cover-report-templates",
				});
			}

			if (userAdmin) {
				if (policyEnabled) {
					options.push({
						id: "policy-settings",
						label: t("menu.policySettings"),
						ariaLabel: t("menu.tooltips.policySettings"),
						navigateUrl: `${API_HOST}/home/policy/settings`,
						actionFunction: () => {
							openPage("/policy/settings");
						},
						newTab: true,
						"data-cy": "policy-settings",
					});
				}

				options.push({
					id: "document-library-settings",
					label: t("menu.documentLibrarySettings"),
					ariaLabel: t("menu.tooltips.documentLibrarySettings"),
					navigateUrl: `${API_HOST}/home/documents/customfolders`,
					actionFunction: () => {
						openPage("/documents/customfolders");
					},
					newTab: true,
					"data-cy": "document-library-settings",
				});

				if (!lite.enabled) {
					options.push({
						id: "subscribers",
						label: t("menu.subscribers"),
						ariaLabel: t("menu.tooltips.subscribers"),
						navigateUrl: `${API_HOST}/home/subscribers`,
						actionFunction: () => {
							openPage("/subscribers");
						},
						newTab: true,
						"data-cy": "subscribers",
					});
				}

				options.push({
					id: "general-settings",
					label: t("menu.generalSettings"),
					ariaLabel: t("menu.tooltips.generalSettings"),
					navigateUrl: `${API_HOST}/home/generalsettings`,
					actionFunction: () => {
						openPage("/generalsettings");
					},
					newTab: true,
					"data-cy": "general-settings",
				});
			}

			if (portalAdmin) {
				options.push({
					id: "portal-styles",
					label: t("menu.portalStyles"),
					ariaLabel: t("menu.tooltips.portalStyles"),
					navigateUrl: `${API_HOST}/home/settings?tab=portalStyles`,
					actionFunction: () => {
						openPage("/settings?tab=portalStyles");
					},
					newTab: true,
					"data-cy": "portal-styles",
				});
			}

			if (systemAdministrator || (salesSite && userAdmin)) {
				options.push({
					id: "features",
					label: t("menu.features"),
					ariaLabel: t("menu.tooltips.features"),
					navigateUrl: `${API_HOST}/home/settings?tab=features`,
					actionFunction: () => {
						openPage("/settings?tab=features");
					},
					newTab: true,
					"data-cy": "features",
				});
			}

			if (systemAdministrator) {
				options.push({
					id: "folder-upload",
					label: t("menu.folderUpload"),
					ariaLabel: t("menu.tooltips.folderUpload"),
					navigateUrl: `${API_HOST}/home/settings?tab=folderUpload`,
					actionFunction: () => {
						openPage("/settings?tab=folderUpload");
					},
					newTab: true,
					"data-cy": "folder-upload",
				});
			}
		}
		return options;
	};

	useEffect(() => {
		if (communityMeetings) {
			loadCustomFolders();
		}
	}, [reload.customFolders]);

	useEffect(() => {
		loadCustomFoldersForPolicy();
	}, [reload.policies]);

	const navItemProps = {
		button: true,
		tabIndex: 0,
		onKeyDown: handleKeyboardNavigation,
	};

	let index = 0;

	return (
		<LeftNavBar open={open} setOpen={setOpen} openTooltip={t("menu.open")} organizationName={organizationName}>
			{communityMeetings && boardAdmin && !publicUser ? (
				<LeftNavigationListItem
					selected={location.pathname === "/"}
					menuIndex={index++}
					open={open}
					iconName="home"
					text={t("menu.currentMeetings")}
					navigateUrl="/home/"
					onClick={() => openPage("/")}
					data-cy="currentMeetings"
					{...navItemProps}
				/>
			) : null}
			{communityMeetings && !boardAdmin && !publicUser && (boardMember || boardStaff) ? (
				<LeftNavigationListItem
					selected={location.pathname === "/"}
					menuIndex={index++}
					open={open}
					iconName="home"
					text={t("menu.meetings")}
					navigateUrl="/home/"
					onClick={() => openPage("/")}
					data-cy="meetings"
					{...navItemProps}
				/>
			) : null}
			{communityMeetings && boardAdmin && !publicUser ? (
				<LeftNavigationListItem
					selected={location.pathname === "/meetings"}
					menuIndex={index++}
					open={open}
					iconName="all-meetings"
					text={t("menu.allMeetings")}
					navigateUrl="/home/meetings"
					onClick={() => openPage("/meetings")}
					data-cy="allMeetings"
					{...navItemProps}
				/>
			) : null}
			{communityMeetings && canAddAgendaItems && !lite.enabled ? (
				<LeftNavigationListItem
					id="nav-agendaitems"
					selected={
						location.pathname === "/agendaitems" || location.pathname === "/agendaitems" || location.pathname.startsWith("/agendaitems/")
					}
					menuIndex={index++}
					open={open}
					iconName="agenda-items"
					text={t("menu.agendaItems")}
					navigateUrl="/home/agendaitems"
					onClick={() => openPage("/agendaitems")}
					data-cy="agendaItems"
					badgeProps={{ content: pendingAgendaItemApprovalsCount, color: "error" }}
					{...navItemProps}
				/>
			) : null}
			{communityMeetings && requestToSpeak && !lite.enabled && !publicUser && (systemAdministrator || requestToSpeakAdmin) ? (
				<LeftNavigationListItem
					id="nav-requesttospeak"
					selected={location.pathname.startsWith("/requesttospeak")}
					menuIndex={index++}
					open={open}
					iconName="request-to-speak"
					text={t("menu.requestToSpeak")}
					navigateUrl="/home/requesttospeak"
					onClick={() => openPage("/requesttospeak")}
					data-cy="requestToSpeak"
					badgeProps={{ content: requestsToSpeakCount, color: "error" }}
					{...navItemProps}
				/>
			) : null}
			{communityMeetings && !lite.enabled && !publicUser ? (
				<LeftNavigationListItem
					id="nav-goals"
					selected={location.pathname === "/goals" || location.pathname === "/goal" || location.pathname.startsWith("/goal/")}
					menuIndex={index++}
					open={open}
					iconName="goal"
					text={t("menu.goals")}
					navigateUrl="/home/goals"
					onClick={() => openPage("/goals")}
					data-cy="goals"
					{...navItemProps}
				/>
			) : null}
			<LeftNavigationListItem
				menuIndex={index++}
				open={open}
				iconName="public-site"
				text={t("menu.publicSite")}
				newTab
				navigateUrl={`${API_HOST}/Portal`}
				onClick={() => {
					telemetryAddEvent("Left menu - Public site");
					openNewTab(!communityMeetings ? `${API_HOST}/Portal/Policy.aspx` : `${API_HOST}/Portal`);
				}}
				data-cy="publicSite"
				{...navItemProps}
			/>
			{communityMeetings && (
				<LeftNavigationListItem
					id="document-library-navigation"
					menuIndex={index++}
					open={open}
					iconName="resource-center"
					text={t("menu.documentLibrary")}
					onClick={() => {
						setMenus({
							documentLibrary: true,
						});
					}}
					menuOptions={getDocumentLibraryOptions(customFolders)}
					menuOpen={menus.documentLibrary}
					closeMenu={closeMenu}
					data-cy="document-library"
					{...navItemProps}
				/>
			)}

			{policyEnabled ? (
				<LeftNavigationListItem
					menuIndex={index++}
					open={open}
					iconName="policy-site"
					text={t("menu.policy")}
					onClick={() => {
						telemetryAddEvent("Left menu - Policy");

						setMenus({
							policySite: true,
						});
					}}
					menuOpen={menus.policySite}
					closeMenu={closeMenu}
					menuOptions={getPolicySiteOptions(customFolders)}
					data-cy="policySite"
					{...navItemProps}
				/>
			) : (
				policyUrl && (
					<LeftNavigationListItem
						menuIndex={index++}
						open={open}
						iconName="policy-site"
						text={t("menu.policySite")}
						newTab
						navigateUrl={policyUrl}
						onClick={() => {
							openNewTab(policyUrl);
						}}
						data-cy="policySite"
						{...navItemProps}
					/>
				)
			)}

			{systemAdministrator || userAdmin || boardAdmin || workflowAdmin ? (
				<LeftNavigationListItem
					id="settings-navigation"
					menuIndex={index++}
					open={open}
					iconName="settings"
					text={t("menu.settings")}
					onClick={() => {
						setMenus({
							settings: true,
						});
					}}
					menuOptions={getSettingsOptions()}
					menuOpen={menus.settings}
					closeMenu={closeMenu}
					data-cy="settings"
					{...navItemProps}
				/>
			) : null}
		</LeftNavBar>
	);
};

LeftNavigation.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	closeOnAction: PropTypes.bool.isRequired,
	organizationName: PropTypes.string,
	policyUrl: PropTypes.string,
};

LeftNavigation.defaultProps = {
	organizationName: "",
	policyUrl: "",
};

export default LeftNavigation;
