import {
	GET_TIMERS_FULFILLED,
	GET_TIMERS_PENDING,
	GET_VOTING_DATA_FULFILLED,
	GET_VOTING_DATA_PENDING,
	GET_VOTING_SETTINGS_FULFILLED,
	GET_VOTING_SETTINGS_PENDING,
	PERSIST_ROLLCALL_FULFILLED,
	PERSIST_ROLLCALL_PENDING,
	POST_BOXCAST_INTERMISSION_FULFILLED,
	POST_BOXCAST_INTERMISSION_PENDING,
	UPDATE_ONLINE_VOTERS,
	UPDATE_TIMERS,
	ADOPT_PUBLISH_PREVIOUS_MINUTES_PENDING,
	ADOPT_PUBLISH_PREVIOUS_MINUTES_FULFILLED,
	OPEN_DRAFT_ADOPT_MINUTES_PENDING,
	OPEN_DRAFT_ADOPT_MINUTES_FULFILLED,
} from "./types";
import request from "superagent";
import { API_HOST } from "config/env";

export function getTimers() {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: GET_TIMERS_PENDING,
				payload: request
					.get(`${API_HOST}/api/users/timers`)
					.withCredentials()
					.then((response) => response.body)
					.then((json) => {
						dispatch({
							type: GET_TIMERS_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject("Error checking voting data");
						// TODO Create exception handlers
						// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
					}),
			}),
		);
	};
}

export function getVotingSettings() {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: GET_VOTING_SETTINGS_PENDING,
				payload: request
					.get(`${API_HOST}/api/settings`)
					.query({ type: "voting" })
					.withCredentials()
					.then((response) => response.body)
					.then((json) => {
						dispatch({
							type: GET_VOTING_SETTINGS_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject("Error getting voting settings");
						// TODO Create exception handlers
						// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
					}),
			}),
		);
	};
}

export function getVotingData(meetingId, updateData) {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: GET_VOTING_DATA_PENDING,
				payload: request
					.get(`${API_HOST}/api/voting/votingdata/${meetingId}?page=admin`)
					.withCredentials()
					.then((response) => response.body)
					.then((json) => {
						dispatch({
							type: GET_VOTING_DATA_FULFILLED,
							payload: json,
							updateData,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject("Error checking voting data");
						// TODO Create exception handlers
						// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
					}),
			}),
		);
	};
}

export function toggleBoxcastIntermission(meetingId, inCamera) {
	const data = { MeetingId: meetingId, InCamera: inCamera };
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: POST_BOXCAST_INTERMISSION_PENDING,
				payload: request
					.post(`${API_HOST}/api/toggleboxcastintermission`)
					.send(data)
					.withCredentials()
					.then((response) => {
						if (response.body && response.body.success) {
							dispatch({
								type: POST_BOXCAST_INTERMISSION_FULFILLED,
							});
							resolve(true);
						} else {
							resolve(false);
						}
					})
					.catch((exception) => {
						reject("Error toggling box cast intermission");
						// TODO Create exception handlers
						// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
					}),
			}),
		);
	};
}
export function putRollCall(rollCall, meetingId) {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: PERSIST_ROLLCALL_PENDING,
				payload: request
					.put(`${API_HOST}/api/meeting/${meetingId}/rollcall`)
					.send(rollCall)
					.withCredentials()
					.then((response) => {
						if (response.body) {
							dispatch({
								type: PERSIST_ROLLCALL_FULFILLED,
							});
							resolve(true);
						} else {
							resolve(false);
						}
					})
					.catch((exception) => {
						reject("Error toggling box cast intermission");
						// TODO Create exception handlers
						// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
					}),
			}),
		);
	};
}

export function updateOnlineVoters(onlineVoters) {
	return (dispatch) =>
		dispatch({
			type: UPDATE_ONLINE_VOTERS,
			onlineVoters,
		});
}

export function updateTimers(timers) {
	return (dispatch) =>
		dispatch({
			type: UPDATE_TIMERS,
			timers,
		});
}

export function adoptPublishPreviousMinutes(previousMeetingId, guid) {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: ADOPT_PUBLISH_PREVIOUS_MINUTES_PENDING,
				payload: request
					.post(`${API_HOST}/api/meeting/${previousMeetingId}/adopt`)
					.withCredentials()
					.send({ progressGuid: guid, agenda: false })
					.then((response) => {
						dispatch({
							type: ADOPT_PUBLISH_PREVIOUS_MINUTES_FULFILLED,
						});
						resolve(response);
					})
					.catch((err) => {
						dispatch({
							type: ADOPT_PUBLISH_PREVIOUS_MINUTES_FULFILLED,
						});
						reject("Error adopting minutes");
					}),
			}),
		);
	};
}

export function openDraftAdoptMinutesToSign(previousMeetingId, guid) {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: OPEN_DRAFT_ADOPT_MINUTES_PENDING,
				payload: request
					.post(`${API_HOST}/api/meeting/${previousMeetingId}/createdrafttosign`)
					.withCredentials()
					.send({ progressGuid: guid, agenda: false })
					.then((response) => {
						dispatch({
							type: OPEN_DRAFT_ADOPT_MINUTES_FULFILLED,
						});
						resolve(response);
					})
					.catch((err) => {
						dispatch({
							type: OPEN_DRAFT_ADOPT_MINUTES_FULFILLED,
						});
						reject("Error getting minutes draft to sign");
					}),
			}),
		);
	};
}
