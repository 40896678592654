import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';

import { useWidthDown, useWidthUp } from '../../utils/useWidth';
import clsx from 'clsx';

const useStyles = makeStyles({
	box: {
		height: (props) => (props.fullHeight ? '100%' : undefined),
	},
	container: {
		padding: (props) => {
			const { noPaddingDown, noPaddingUp, padding, widthDownNoPadding, widthUpNoPadding } = props;

			if ((noPaddingDown && widthDownNoPadding) || (noPaddingUp && widthUpNoPadding)) {
				return '0';
			}

			return padding || '24px';
		},
		paddingTop: (props) => {
			const { noPaddingTopDown, noPaddingTopUp, widthDownNoPaddingTop, widthUpNoPaddingTop } = props;

			if ((noPaddingTopDown && widthDownNoPaddingTop) || (noPaddingTopUp && widthUpNoPaddingTop)) {
				return '0';
			}

			return undefined;
		},
		maxWidth: (props) => props.maxWidth,
		margin: (props) => (props.maxWidth ? '0 auto' : undefined),
		height: (props) => (props.fullHeight ? '100%' : undefined),
		paddingLeft: (props) => (props.paddingLeft ? '0px' : undefined),
	},
	contentTitle: {
		position: 'absolute',
		top: '-4000px',
		left: '0px',
	},
});

const ComponentContainer = (props) => {
	const {
		id,
		noPaddingDown,
		noPaddingUp,
		noPaddingTopDown,
		noPaddingTopUp,
		padding,
		maxWidth,
		fullHeight,
		onlyScrollbar,
		paddingLeft = false,
		containerClass,
		children,
	} = props;
	const widthUpNoPadding = useWidthUp(noPaddingUp);
	const widthDownNoPadding = useWidthDown(noPaddingDown);
	const widthUpNoPaddingTop = useWidthUp(noPaddingTopUp);
	const widthDownNoPaddingTop = useWidthDown(noPaddingTopDown);
	const contentRef = useRef(null);
	const title = useSelector((state) => state.appReducer.title);
	const { box, container, contentTitle } = useStyles({
		noPaddingDown,
		noPaddingUp,
		noPaddingTopDown,
		noPaddingTopUp,
		padding,
		maxWidth,
		fullHeight,
		widthUpNoPadding,
		widthDownNoPadding,
		widthUpNoPaddingTop,
		widthDownNoPaddingTop,
		paddingLeft,
	});

	useEffect(() => {
		if (contentRef.current) {
			contentRef.current.focus();
		}
	}, []);

	useEffect(() => {
		if (contentRef.current && title) {
			contentRef.current.innerHTML = title;
		}
	}, [title]);

	return (
		<Box id={id} className={clsx(box, containerClass)}>
			<span id="cont" className={contentTitle} ref={contentRef} tabIndex={-1} />
			{onlyScrollbar ? <>{children}</> : <div className={container}>{children}</div>}
		</Box>
	);
};

ComponentContainer.propTypes = {
	noPaddingDown: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
	noPaddingUp: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
	noPaddingTopDown: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
	noPaddingTopUp: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
	padding: PropTypes.string,
	paddingLeft: PropTypes.string,
	maxWidth: PropTypes.string,
	onlyScrollbar: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

ComponentContainer.defaultProps = {
	noPaddingDown: undefined,
	noPaddingUp: undefined,
	noPaddingTopDown: undefined,
	noPaddingTopUp: undefined,
	padding: undefined,
	paddingLeft: undefined,
	maxWidth: undefined,
	onlyScrollbar: false,
};

export default ComponentContainer;
