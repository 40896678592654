export default {
	at: "at",
	dragImageFileOrClickToSelect: "Drag image file or click to select",
	locale: "en-US",
	otherLanguage: "Français",
	otherLanguageFourLetters: "fr-CA",
	showNavigation: "Show navigation",
	error: "Error",
	errorMessage: "Community encountered a problem...",
	backToPrevious: "Back to previous",
	productName: "Diligent Community",
	whatsNew: "What's new",
	selectAll: "Select all",
	noAccess: "No access",
	approvals: {
		title: "Approvals",
		blockTitle: "Group",
		tooltips: {
			collapseBlock: "Collapse the list of approvers",
			expandBlock: "Expand the list of approvers",
		},
	},
	buttons: {
		add: "Add",
		adopt: "Adopt",
		approve: "Approve",
		breadcrumbMenu: "Show all breadcrumbs",
		cancel: "Cancel",
		close: "Close",
		confirm: "Confirm",
		copy: "Copy",
		create: "Create",
		delete: "Delete",
		dismiss: "Dismiss",
		download: "Download",
		duplicate: "Duplicate",
		edit: "Edit",
		move: "Move",
		off: "Off",
		ok: "OK",
		on: "On",
		open: "Open",
		permanentlyDelete: "Permanently delete",
		preview: "Preview",
		rename: "Rename",
		replace: "Replace",
		reset: "Reset",
		restore: "Restore",
		save: "Save",
		saving: "Saving",
		send: "Send",
		showPassword: "Show password",
		signAdopt: "Sign & adopt",
		adopt: "Adopt",
		undo: "Undo",
		updateAdopt: "Update Adopted",
		upload: "Upload",
		view: "View",
		back: "Back",
		saveAndBack: "Save & back",
		publishSection: "Publish section name",
		editDraft: "Edit Draft",
		addToAgenda: "Add to Agenda",
		policyUpdate: "Update",
		editDraftInWord: "Edit Draft in Word",
		editInWord: "Edit in Word",
		editInPowerPoint: "Edit in Powerpoint",
		openDraftPolicy: "Open Draft Policy",
	},
	entities: {
		deleted: "{{entity}} (Deleted)",
		external: "{{entity}} (External)",
		inactive: "{{entity}} (Inactive)",
		required: "{{entity}} (required)",
	},
	enums: {
		statuses: {
			shared: "Shared",
			notShared: "Not Shared",
			notPublished: "Not Published",
			outOfSync: "Out Of Sync",
			draft: "Draft",
			pending: "Pending",
			denied: "Denied",
			approved: "Approved",
			published: "Published",
			archived: "Archived",
			adopted: "Adopted",
			scheduled: "Scheduled",
			sharedApproved: "Shared approved",
			sharedAdopted: "Shared adopted",
			addedToAgenda: "Added to agenda",
			publishedApproved: "Published approved",
			publishedAdopted: "Published adopted",
			sharedDraft: "Shared draft",
			publishedDraft: "Published draft",
			release: "Release {{date}}",
			internal: "Internal",
		},
		boolean: {
			no: "No",
			yes: "Yes",
		},
	},
	filter: {
		buttons: {
			clearFilters: "Clear filters",
		},
		notifications: {
			filterApplied: "User filter applied",
		},
		search: "Search",
		title: "Filter",
		tooltips: {
			clearFilters: "Clear all filtering",
		},
	},
	menu: {
		agendaItems: "Agenda items",
		allMeetings: "All meetings",
		boards: "Meeting groups",
		close: "Close menu",
		coverReportTemplates: "Cover report templates",
		currentMeetings: "Current meetings",
		documentLibrary: "Document Library",
		documentLibrarySettings: "Document library settings",
		features: "Features",
		feedback: "Send feedback",
		folderUpload: "Folder upload",
		generalSettings: "General settings",
		goals: "Goals",
		help: "Help",
		internal: "Internal",
		meetings: "Meetings",
		meetingTemplates: "Meeting templates",
		open: "Open menu",
		options: "Options",
		overview: "Overview",
		policySettings: "Policy settings",
		policySite: "Policy site",
		policy: "Policies",
		portalStyles: "Public site settings",
		previous: "Previous",
		profile: "Edit profile",
		public: "Public",
		publicSite: "Public site",
		recycleBin: "Recycle Bin",
		requestToSpeak: "Request to speak",
		requestToSpeakSettings: "Request to speak",
		restore: "Restore",
		search: "Search",
		settings: "Settings",
		signOut: "Sign out",
		signIn: "Sign in",
		support: "Contact support",
		subscribers: "Subscribers",
		view: "View",
		policyBook: "Policy book",
		compare: "Compare with this version",
		tooltips: {
			boards: "Open the meeting group list",
			documentLibraryCustom: 'Open the custom document library "{{title}}"',
			policyCustom: 'Open the custom policy "{{title}}"',
			documentLibraryInternal: "Open the internal document library",
			documentLibraryPublic: "Open the public document library",
			documentLibraryRecycleBin: "Open the document library recycle bin",
			policyRecycleBin: "Open the policy recycle bin",
			documentLibrarySettings: "Add custom document library folders",
			features: "Open the feature settings",
			folderUpload: "Open the folder upload",
			generalSettings: "Open the general settings",
			meetingTemplates: "Open the meeting template list",
			policySettings: "Open the policy settings",
			portalStyles: "Open the public site settings",
			requestToSpeakSettings: "Open the request to speak settings",
			users: "Open the user list",
			voting: "Open the voting settings",
			workflows: "Open the agenda item workflow template list",
			subscribers: "Open the subscribers list",
			coverReportTemplates: "Open the cover report template list",
		},
		users: "Users",
		voting: "Voting",
		workflows: "Agenda item workflows",
	},
	menuOptions: {
		moveUp: "Move up",
		moveDown: "Move down",
	},
	options: {
		none: "None",
		today: "Today",
		thisWeek: "This Week",
		thisMonth: "This Month",
		lastMonth: "Last Month",
		nextMonth: "Next Month",
		custom: "Custom...",
		unlimited: "Unlimited",
	},
	search: {
		dateRange: "Date range",
		meetingDateRange: "Meeting date range",
		fileType: "File type",
		from: "From",
		noAttachments: {
			title: "Matching attachments not found",
		},
		noResults: {
			title: "Search results not found",
		},
		legendDialog: {
			title: "Legend",
			row1: "Agenda",
			row2: "Minutes",
			row3: "Goals",
			row4: "Request to Speak",
			icon1: "Draft",
			icon2: "Preview",
			icon3: "Published",
			icon4: "Adopted",
			buttons: {
				legend: "Legend",
			},
		},
		options: {
			agendas: "Agendas",
			agendasCount: "Agendas ({{count}})",
			policies: "Policies ({{count}})",
			agendaItems: "Agenda items",
			agendaItemsCount: "Agenda items ({{count}})",
			all: "All",
			allCount: "All ({{count}})",
			goals: "Goals",
			goalsCount: "Goals ({{count}})",
			image: "Image",
			minutes: "Minutes",
			minutesCount: "Minutes ({{count}})",
			other: "Other",
			pdf: "PDF",
			portalItems: "Public site",
			portalItemsCount: "Public site ({{count}})",
			ppt: "Powerpoint",
			requestsToSpeak: "RTS",
			requestsToSpeakCount: "RTS ({{count}})",
			word: "Word",
		},
		placeholder: "Search",
		title: "Search",
		to: "To",
		tooltips: {
			agendaItem: "Agenda item",
			agendaPreview: "Agenda preview",
			agendaPublished: "Published agenda",
			document: "Document",
			excel: "Excel",
			folder: "Folder",
			goal: "Goal",
			image: "Image",
			minutesAdopted: "Adopted minutes",
			minutesPreview: "Minutes preview",
			minutesPublished: "Published minutes",
			pdf: "PDF",
			portalItem: "Public site",
			portalItems: "Public site text",
			powerpoint: "PowerPoint",
			requestToSpeak: "Request to speak",
			word: "Word",
		},
	},
	sendFeedback: {
		buttons: {
			send: "Send",
		},
		errors: {
			feedback: "A description is required",
		},
		label: "Tell us about your experience",
		message: "We appreciate you taking the time to help us improve Community!",
		successMessage: "Feedback sent successfully.",
		title: "Send feedback",
	},
	supportRequestDialog: {
		buttons: {
			send: "Send",
		},
		errors: {
			feedback: "A description is required",
			urgency: "An urgency selection is required",
		},
		issueTitle: "What do you need help with?",
		issueLabel: "Tell us about your issue",
		urgencyTitle: "What's the urgency of your issue?",
		urgencyResponses: {
			high: "High - 30 minute response or call 1 (866) 877-1172",
			medium: "Medium - 1 day response",
			low: "Low - 1 week response",
			none: "No response necessary",
		},
		title: "Your Support team are here for you 24/7!",
		successMessage: "Support request sent successfully.",
	},
	switch: {
		off: "Off",
		on: "On",
	},
	tabs: {
		scrollLeft: "Scroll the tabs to the left",
		scrollRight: "Scroll the tabs to the right",
	},
	uploadErrorDialog: {
		title: "Errors",
		line1: "Upload failed:",
		dialogLabel: "File Upload Failure",
	},
	workflows: {
		progress: {
			label: "Approval progress",
		},
		toolTips: {
			approver: "{{name}}",
			approverApproved: "{{name}} - Approved",
			approverDenied: "{{name}} - Rejected",
			scrollNext: "Scroll forward",
			scrollPrevious: "Scroll back",
		},
	},
	fileUploadFailureDialog: {
		failureMessage: "Attachments can be documents (PDF, Word, Powerpoint) or images (JPEG, GIF, PNG, BMP).",
		fileListSingular: "This file was not attached: ",
		fileListPlural: "These files were not attached: ",
		dialogLabel: "File Upload Failure",
		buttonText: "Got it",
		failureMessageForPolicy: "Policy documents must to be in word format (.docx) to upload.",
		fileListSingularForPolicy: "This file was not uploaded: ",
		fileListPluralForPolicy: "These files were not uploaded: ",
	},
	tooltips: {
		closeButton: "Close",
		dismissNotice: "Dismiss this notice",
		fixErrors: "Fix the errors above to proceed",
		help: "Help",
		openMenu: "Open Menu",
		whatsNew: "Open window with new announcements",
		clickSearch: "Click to search",
		clearSearch: "Click to clear search",
		signalR: {
			connecting: "Connecting",
			connected: "Connected",
			reconnecting: "Reconnecting",
			slow: "Connection slow",
			disconnected: "Disconnected",
		},
	},
	editor: {
		delete: "Delete",
		inlineFile: {
			buttons: {
				selectFile: "Select File...",
			},
			labels: {
				editNoFiles: "Select file to upload",
				noUrl: "This link has no URL",
			},
			tooltips: {
				edit: "Edit Attachment",
				remove: "Remove Link",
				open: "Open attachment in new tab",
				editConfirm: "Save",
				editConfirmNoFiles: "Select an attachment to proceed",
				editCancel: "Cancel",
			},
		},
		tooltips: {
			selectFile: "Select file",
		},
	},
	shortCutKeys: {
		keyboardShortCutkey: "ctrl+/",
	},
};
