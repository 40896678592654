import {
	ADD_MOTION,
	ADD_TEXT,
	CHECK_FILE_UPLOADS,
	DELETE_MOTION,
	GET_AGENDA_ITEMS_FULFILLED,
	GET_AGENDA_ITEMS_PENDING,
	GET_MEETING_GROUPS_FULFILLED,
	GET_MEETING_GROUPS_PENDING,
	GET_MEMBERS_FULFILLED,
	GET_MEMBERS_PENDING,
	GET_MINUTES_ITEMS_FULFILLED,
	GET_MINUTES_ITEMS_PENDING,
	GET_PLACEHOLDER_VALUES_FULFILLED,
	GET_PLACEHOLDER_VALUES_PENDING,
	PERSIST_FILE_FULFILLED,
	PERSIST_FILE_PENDING,
	PERSIST_MINUTES_FULFILLED,
	PERSIST_MINUTES_PENDING,
	PERSIST_MINUTES_FAILED,
	PERSIST_VOTE_FULFILLED,
	PERSIST_VOTE_PENDING,
	QUEUE_FILE_UPLOADS,
	REMOVE_TEXT,
	RESET_MEETING,
	SEND_FOR_VOTE_FULFILLED,
	SEND_FOR_VOTE_PENDING,
	SET_ACTIVE,
	SET_SELECTED,
	SET_PRESENTING,
	STOP_VOTE_FULFILLED,
	STOP_VOTE_PENDING,
	TIMESTAMP_ITEM_FULFILLED,
	TIMESTAMP_ITEM_PENDING,
	UPDATE_MEETING,
	UPDATE_MEETING_DATE,
	UPDATE_MEETING_VOTING_DATA,
	UPDATE_MINUTES_ITEMS,
	UPDATE_MOTION,
	UPDATE_VOTE,
	START_TIEBREAKER_VOTE_PENDING,
	START_TIEBREAKER_VOTE_FULFILLED,
} from "./types";
import request from "superagent";
import { API_HOST } from "config/env";

export function getMeetingAgendaItems(id) {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: GET_AGENDA_ITEMS_PENDING,
				payload: request
					.get(`${API_HOST}/api/meeting/${id}/getagendaitems`)
					.withCredentials()
					.then((response) => response.body)
					.then((json) => {
						dispatch({
							type: GET_AGENDA_ITEMS_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject("Error getting agenda items");
						// TODO Create exception handlers
						// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
					}),
			}),
		);
	};
}

export function getMeetingMembers(id) {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: GET_MEMBERS_PENDING,
				payload: request
					.get(`${API_HOST}/api/meeting/${id}/members?simple=true`)
					.withCredentials()
					.then((response) => response.body)
					.then((json) => {
						dispatch({
							type: GET_MEMBERS_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject("Error getting meeting members");
						// TODO Create exception handlers
						// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
					}),
			}),
		);
	};
}

export function getMeetingGroups() {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: GET_MEETING_GROUPS_PENDING,
				payload: request
					.get(`${API_HOST}/api/boards`)
					.withCredentials()
					.then((response) => response.body)
					.then((json) => {
						dispatch({
							type: GET_MEETING_GROUPS_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject("Error getting meeting groups");
						// TODO Create exception handlers
						// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
					}),
			}),
		);
	};
}

export function getMeetingMinutesItems(id) {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: GET_MINUTES_ITEMS_PENDING,
				payload: request
					.get(`${API_HOST}/api/meeting/${id}/getminutesitems`)
					.withCredentials()
					.then((response) => response.body)
					.then((json) => {
						dispatch({
							type: GET_MINUTES_ITEMS_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject("Error getting minutes items");
						console.error(exception);
						// TODO Create exception handlers
						// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
					}),
			}),
		);
	};
}

export function getPlaceholdersValues(meetingId, placeholders) {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: GET_PLACEHOLDER_VALUES_PENDING,
				payload: request
					.post(`${API_HOST}/api/meeting/${meetingId}/placeholders`)
					.send({ placeholders })
					.withCredentials()
					.then((response) => response.body)
					.then((json) => {
						dispatch({
							type: GET_PLACEHOLDER_VALUES_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject("Error loading placeholdersValues");
						// TODO Create exception handlers
						// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
					}),
			}),
		);
	};
}

export function sendFiletoAPI(fileData, agendaItems) {
	return (dispatch) => {
		return new Promise((resolve) =>
			dispatch({
				type: PERSIST_FILE_PENDING,
				payload: request
					.post(`${API_HOST}/api/documents/uploadattachments`)
					.withCredentials()
					.send(fileData)
					.then((response) => {
						if (response.status === 200) {
							dispatch({
								type: PERSIST_FILE_FULFILLED,
								attachments: response.body.Attachments,
							});
							resolve(response.body.Attachments, agendaItems);
						} else {
							resolve(false);
						}
					}),
			}),
		);
	};
}

export function timestampItem(item) {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		const state = getState();
		// if (state.meetingsReducer.meeting.isReadOnly) return;

		const {
			meetingsReducer: {
				meeting: { id },
			},
		} = state;
		return new Promise((resolve, reject) =>
			dispatch({
				type: TIMESTAMP_ITEM_PENDING,
				payload: request
					.put(`${API_HOST}/api/meeting/${id}/timestampitem`)
					.withCredentials()
					.send({ itemGuid: item.guid, autoTimestamp: true })
					.then((response) => {
						if (response.status === 200 && response.body?.timestamp) {
							dispatch({
								type: TIMESTAMP_ITEM_FULFILLED,
								guid: response.body?.itemGuid,
								timestamp: response.body.timestamp,
							});
							resolve(true);
						} else {
							resolve(false);
						}
					}),
				// .catch((exception) => {
				// 	reject("Error uploading file to api");
				// 	// TODO Create exception handlers
				// 	// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
				// }),
			}),
		);
	};
}

export function persistMinutes() {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		const state = getState();
		// if (state.meeting.isReadOnly) return;
		const {
			meetingsReducer: { meeting: { id } = {}, meetingId, persistObject },
		} = state;

		return new Promise((resolve, reject) =>
			dispatch({
				type: PERSIST_MINUTES_PENDING,
				payload: request
					.put(`${API_HOST}/api/meeting/${id || meetingId}/saveminutesitems`)
					.withCredentials()
					.timeout({
						response: 300000, // Wait 30 seconds for the server to start sending,
						deadline: 350000, // but allow 35 seconds for the data to finish sending.
					})
					.send(persistObject)
					.then((response) => {
						if (response.status === 200) {
							dispatch({
								type: PERSIST_MINUTES_FULFILLED,
								meeting: response.body.meeting,
							});
							resolve(true);
						} else {
							dispatch({
								type: PERSIST_MINUTES_FAILED,
								persistObject,
							});
							resolve(false);
						}
					})
					.catch((exception) => {
						if (exception.status !== 403) {
							dispatch({
								type: PERSIST_MINUTES_FAILED,
								persistObject,
							});
						}
						reject(exception);
					}),
			}),
		);
	};
}

export function persistVote(motionGuid, userId, vote) {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		const state = getState();
		const {
			meetingsReducer: {
				meeting: { id },
			},
		} = state;
		const votingData = {
			guid: motionGuid,
			userId,
		};

		return new Promise((resolve, reject) =>
			dispatch({
				type: PERSIST_VOTE_PENDING,
				votingData,
				payload: request
					.post(`${API_HOST}/api/voting/vote/${motionGuid}`)
					.withCredentials()
					.send({ meetingId: id, userId, vote })
					.then((res) => {
						if (res.status === 200) {
							dispatch({
								type: PERSIST_VOTE_FULFILLED,
								votingData,
							});
							resolve(true);
						} else {
							resolve(false);
						}
					}),
				// .catch((exception) => {
				// 	reject("Error uploading file to api");
				// 	// TODO Create exception handlers
				// 	// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
				// }),
			}),
		);
	};
}

export function sendForVote(motion) {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		const state = getState();
		const {
			meetingsReducer: {
				meeting: { id },
			},
		} = state;

		return new Promise((resolve, reject) =>
			dispatch({
				type: SEND_FOR_VOTE_PENDING,
				payload: request
					.post(`${API_HOST}/api/voting/start/${motion.guid}?meetingId=${id}`)
					.withCredentials()
					.send({})
					.then((res) => {
						if (res.status === 200) {
							dispatch({
								type: SEND_FOR_VOTE_FULFILLED,
							});
							resolve({ id, guid: motion.guid });
						} else {
							resolve(false);
						}
					}),
				// .catch((exception) => {
				// 	reject("Error uploading file to api");
				// 	// TODO Create exception handlers
				// 	// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
				// }),
			}),
		);
	};
}

export function stopVote(motionGuid) {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		const state = getState();
		const {
			meetingsReducer: {
				meeting: { id },
			},
		} = state;

		return new Promise((resolve, reject) =>
			dispatch({
				type: STOP_VOTE_PENDING,
				payload: request
					.post(`${API_HOST}/api/voting/stop/${motionGuid}?meetingId=${id}`)
					.withCredentials()
					.send({})
					.then((res) => {
						if (res.status === 200) {
							dispatch({
								type: STOP_VOTE_FULFILLED,
								motionGuid,
							});
							resolve(true);
						} else {
							resolve(false);
						}
					}),
				// .catch((exception) => {
				// 	reject("Error uploading file to api");
				// 	// TODO Create exception handlers
				// 	// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
				// }),
			}),
		);
	};
}

export function finishVote(motionGuid) {
	// eslint-disable-next-line no-unused-vars
	return (dispatch, getState) => {
		const state = getState();
		const {
			meetingsReducer: {
				meeting: { id },
			},
		} = state;

		return new Promise((resolve, reject) =>
			dispatch({
				type: STOP_VOTE_PENDING,
				payload: request
					.post(`${API_HOST}/api/voting/finish/${motionGuid}?meetingId=${id}`)
					.withCredentials()
					.send({})
					.then((res) => {
						if (res.status === 200) {
							dispatch({
								type: STOP_VOTE_FULFILLED,
							});
							resolve(true);
						} else {
							resolve(false);
						}
					}),
				// .catch((exception) => {
				// 	reject("Error uploading file to api");
				// 	// TODO Create exception handlers
				// 	// reject(handleException(dispatch, exception, { key: "defaultError.getAgendaItems" }));
				// }),
			}),
		);
	};
}

export function startTieBreakerVote(motionGuid) {
	return (dispatch, getState) => {
		const state = getState();
		const {
			meetingsReducer: {
				meeting: { id },
			},
		} = state;

		return new Promise((resolve, reject) =>
			dispatch({
				type: START_TIEBREAKER_VOTE_PENDING,
				payload: request
					.post(`${API_HOST}/api/voting/startTieBreakerVote/${motionGuid}?meetingId=${id}`)
					.withCredentials()
					.send({})
					.then((res) => {
						if (res.status === 200) {
							dispatch({
								type: START_TIEBREAKER_VOTE_FULFILLED,
							});
							resolve(true);
						} else {
							resolve(false);
						}
					}),
			}),
		);
	};
}

export function updateMeetingVotingData(itemInProgress, votingSettings, quorumNotMetLabel) {
	return (dispatch) =>
		dispatch({
			type: UPDATE_MEETING_VOTING_DATA,
			itemInProgress,
			votingSettings,
			quorumNotMetLabel,
		});
}

export function queueFileUploads(itemGuid, fileUploads) {
	return (dispatch) =>
		dispatch({
			type: QUEUE_FILE_UPLOADS,
			itemGuid,
			fileUploads,
		});
}

export function checkFileUpload(itemGuid, fileGuid) {
	return (dispatch) =>
		dispatch({
			type: CHECK_FILE_UPLOADS,
			itemGuid,
			fileGuid,
		});
}

export function updateMeeting(meeting) {
	return (dispatch) =>
		dispatch({
			type: UPDATE_MEETING,
			meeting,
		});
}

export function updateMeetingDate(meetingDate) {
	return (dispatch) =>
		dispatch({
			type: UPDATE_MEETING_DATE,
			meetingDate,
		});
}

export function updateMinutesItems(fieldData, editorFields) {
	return (dispatch) =>
		dispatch({
			type: UPDATE_MINUTES_ITEMS,
			fieldData,
			editorFields,
		});
}

export function addText(guid) {
	return (dispatch) =>
		dispatch({
			type: ADD_TEXT,
			guid,
		});
}

export function removeText(guid) {
	return (dispatch) =>
		dispatch({
			type: REMOVE_TEXT,
			guid,
		});
}

export function addMotion(motion, guid) {
	return (dispatch) =>
		dispatch({
			type: ADD_MOTION,
			motion,
			guid,
		});
}

export function deleteMotion(guidToDelete) {
	return (dispatch) =>
		dispatch({
			type: DELETE_MOTION,
			guidToDelete,
		});
}

export function updateMotion(motion, updatedFields) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: UPDATE_MOTION,
				motion,
				updatedFields,
			});
			resolve(true);
		});
	};
}

export function updateVote(motionGuid, userId, vote) {
	return (dispatch) =>
		dispatch({
			type: UPDATE_VOTE,
			motionGuid,
			userId,
			vote,
		});
}

export function setActive(guid, focus, field) {
	return (dispatch) =>
		dispatch({
			type: SET_ACTIVE,
			guid,
			focus,
			field,
		});
}

export function setPresenting(presenting) {
	return (dispatch) =>
		dispatch({
			type: SET_PRESENTING,
			presenting,
		});
}

export function setSelected(guid) {
	return (dispatch) =>
		dispatch({
			type: SET_SELECTED,
			guid,
		});
}

export function resetMeeting() {
	return (dispatch) =>
		dispatch({
			type: RESET_MEETING,
		});
}
