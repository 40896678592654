import React, { useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

import tableOfContentsStyle from "assets/jss/components/tableOfContentsStyle";
import Icon from "atlas/components/Icon/Icon";
import telemetryAddEvent from "utils/telemetryAddEvent";
import TOCAttachments from "./TOCAttachments";
import TOCPrimaryText from "./TOCPrimaryText";
import useAttachmentsHighlight from "../PdfViewerNew/utils/useAttachmentsHighlight";

const useTableOfContentsStyles = makeStyles(tableOfContentsStyle);

const TOCDecision = (props) => {
	const {
		resolution,
		toggleTableOfContent = () => {},
		isConsentSection,
		isPublicCommentSection,
		isMemberOnlySection,
		isSmallDevice,
		isHeadingAction,
		parserOptions,
		isClosedMeeting,
		idPrefix = "outline",
		button,
		selected,
		setActive,
		telemetryPage,
		setDataForAttachmentDocument,
		setDataForOutlineItem,
		isPdfSidebar,
		selectedId,
		openGoalsPopup,
	} = props;
	const dispatch = useDispatch();
	const tableOfContentsClasses = useTableOfContentsStyles({ lineLimit: 2 });
	const [clickedOnAttachment, setClickedOnAttachment, clickedOnAttachmentInSidePanel] = useAttachmentsHighlight(false);

	return (
		<>
			<ListItem
				id={`${idPrefix}-${resolution.guid}`}
				data-cy={`${idPrefix}-${resolution.guid}`}
				className={clsx(tableOfContentsClasses.listItem, {
					[tableOfContentsClasses.closedChanged]: resolution.original && resolution.original.closed && !resolution.fields.Closed.Value,
				})}
				button={button}
				role=""
				component={"li"}
				disableGutters
				divider
				classes={{
					root: clsx("outline-item-secondary", {
						"outline-item-consent-recommendation": isConsentSection,
						"outline-item-public-comment-recommendation": isPublicCommentSection && !isConsentSection,
						"outline-heading-recommendation": isHeadingAction && !isPublicCommentSection && !isConsentSection,
						"outline-item-recommendation": !isHeadingAction && !isPublicCommentSection && !isConsentSection,
						"member-only-section": isMemberOnlySection,
						"consent-section-outline": isConsentSection,
						"public-comment-section-outline-pdfsidebar": isPublicCommentSection && isPdfSidebar,
						"public-comment-section-outline": isPublicCommentSection && !isPdfSidebar,
						"closed-meeting": isClosedMeeting,
						pdfSideBar: isPdfSidebar,
						hyperlinkAttachment: isPdfSidebar && selectedId && selectedId.includes(resolution.guid) && !clickedOnAttachment,
						[tableOfContentsClasses.selected]: selected && selected.includes(resolution.guid),
					}),
				}}
				onClick={
					button && isPdfSidebar
						? (e) => {
								setDataForOutlineItem(resolution);
								setClickedOnAttachment(false);
								dispatch(setActive(resolution.guid, true));
						  }
						: button
						? () => {
								telemetryAddEvent(`${telemetryPage} - Outline navigation`);

								dispatch(setActive(resolution.guid, true));
								if (isSmallDevice) {
									toggleTableOfContent();
								}
						  }
						: undefined
				}
				selected={selected && selected.includes(resolution.guid)}
			>
				<ListItemIcon style={{ minWidth: "0", top: "4px!important" }}>
					<Icon name="recommendation" />
				</ListItemIcon>
				<ListItemText
					className={clsx({
						[tableOfContentsClasses.limitLines]: true,
					})}
					primaryTypographyProps={{ variant: "h5" }}
					primary={
						<TOCPrimaryText
							text={resolution.fields.Name.Value}
							parserOptions={parserOptions}
							showTruncationTooltip
							truncationThreshold={isPdfSidebar ? 23 : 50}
							isPdfSidebar={isPdfSidebar}
						></TOCPrimaryText>
					}
				/>
			</ListItem>

			<TOCAttachments
				attachments={resolution.attachments}
				item={resolution}
				itemGuid={resolution.guid}
				isMemberOnlySection={isMemberOnlySection}
				isConsentSection={isConsentSection}
				isPublicCommentSection={isPublicCommentSection}
				toggleTableOfContent={toggleTableOfContent}
				telemetryPage={telemetryPage}
				setDataForAttachmentDocument={setDataForAttachmentDocument}
				isPdfSidebar={isPdfSidebar}
				setActive={setActive}
				clickedOnAttachmentInSidePanel={clickedOnAttachmentInSidePanel}
				isRecommendationLevel
				selectedId={selectedId}
				openGoalsPopup={openGoalsPopup}
			/>
		</>
	);
};

export default TOCDecision;
