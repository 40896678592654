import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import { Box } from "@mui/material";
import SendFeedbackDialog from "components/Dialogs/SendFeedbackDialog";
import SupportRequestDialog from "components/Dialogs/SupportRequestDialog";
import { useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { UserContext } from "contexts/User/UserContext";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { defaultFont, blackColor } from "atlas/assets/jss/shared";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";

const useStyles = makeStyles({
	menu: {
		...defaultFont,
		minWidth: "160px",
		fontSize: "16px",
		lineHeight: "1.25",
		fontWeight: "normal",
		letterSpacing: "normal",
		color: blackColor[1],
		whiteSpace: "nowrap",
		"& ul": {
			padding: "0",
		},
		"& li": {
			height: "40px",
			padding: "10px 12px",
		},
	},
});

const HelpMenu = () => {
	const [openHelpMenu, setOpenHelpMenu] = useState(false);
	const [helpAnchor, setHelpAnchor] = useState(null);
	const [showSupportRequestDialog, setShowSupportRequestDialog] = useState(false);
	const [showSendFeebackDialog, setShowSendFeebackDialog] = useState(false);
	const { t } = useTranslation();
	const { lite } = useContext(SettingsContext);
	const { boardAdmin } = useContext(UserContext).user;
	const dispatch = useDispatch();
	const classes = useStyles();

	const closeMenus = () => {
		setHelpAnchor(null);
	};

	const handleToggleHelpMenu = (e) => {
		closeMenus(null);
		setHelpAnchor(e.currentTarget);
		setOpenHelpMenu((prevOpen) => !prevOpen);
	};

	const handleCloseHelpMenu = () => {
		closeMenus();
		setOpenHelpMenu(false);
	};

	const openSupportRequestDialog = () => {
		telemetryAddEvent(`Policy Staff Editor - Open support`);
		setShowSupportRequestDialog(true);
		closeMenus();
	};

	const closeSupportRequestDialog = () => setShowSupportRequestDialog(false);

	const openSendFeedbackDialog = () => {
		telemetryAddEvent(`Policy Staff Editor - Open feedback`);
		setShowSendFeebackDialog(true);
		closeMenus();
	};

	const closeSendFeedbackDialog = () => setShowSendFeebackDialog(false);

	const helpMenuOptions = [
		{
			label: t("menu.help"),
			actionFunction: () => {
				telemetryAddEvent(`Policy Staff Editor - Help menu`);
				closeMenus();
				return window.open(`/help/${lite.enabled ? "Essentials" : ""}${boardAdmin ? "Admin" : "Member"}Help.pdf`);
			},
			external: true,
			dataCy: "helpMenuOption",
		},
		{
			label: t("menu.support"),
			actionFunction: openSupportRequestDialog,
			dataCy: "contactSupportMenuOption",
		},
		{
			label: t("menu.feedback"),
			actionFunction: openSendFeedbackDialog,
			dataCy: "sendFeedbackMenuOption",
		},
	];

	return (
		<>
			<SendFeedbackDialog show={showSendFeebackDialog} onClose={closeSendFeedbackDialog} dispatch={dispatch} />
			<SupportRequestDialog show={showSupportRequestDialog} onClose={closeSupportRequestDialog} />
			<Box ml={2}>
				<AccessibleIconButton
					id="help"
					aria-label={t("menu.help")}
					onClick={handleToggleHelpMenu}
					iconName="help"
					tooltipText={t("menu.help")}
					ref={helpAnchor}
					dataCy="helpIcon"
					isHeader
				/>
			</Box>
			{helpAnchor && (
				<NonModalMenu
					id="helpMenu"
					className={classes.menu}
					anchorEl={helpAnchor}
					keepMounted
					open={openHelpMenu}
					onClose={handleCloseHelpMenu}
					options={helpMenuOptions}
					position="bottom-end"
				/>
			)}
		</>
	);
};

export default HelpMenu;
