import { checkAttachments } from "../../views/MeetingEditor/functions/utils";
import checkVotingFinished, { getVotingResults } from "../../views/LiveMeeting/utils/votingUtils";
import { createMeetingElement, updateOrderAndNumbering, addTableWidth } from "utils/meetingElement";
import {
	ADD_MOTION,
	ADD_TEXT,
	CHECK_FILE_UPLOADS,
	DELETE_MOTION,
	GET_AGENDA_ITEMS_FULFILLED,
	GET_AGENDA_ITEMS_PENDING,
	GET_MEETING_GROUPS_FULFILLED,
	GET_MEETING_GROUPS_PENDING,
	GET_MEMBERS_FULFILLED,
	GET_MEMBERS_PENDING,
	GET_MINUTES_ITEMS_FULFILLED,
	GET_MINUTES_ITEMS_PENDING,
	GET_PLACEHOLDER_VALUES_FULFILLED,
	GET_PLACEHOLDER_VALUES_PENDING,
	PERSIST_FILE_FULFILLED,
	PERSIST_FILE_PENDING,
	PERSIST_MINUTES_FULFILLED,
	PERSIST_MINUTES_PENDING,
	PERSIST_MINUTES_FAILED,
	PERSIST_VOTE_FULFILLED,
	PERSIST_VOTE_PENDING,
	QUEUE_FILE_UPLOADS,
	REMOVE_TEXT,
	RESET_MEETING,
	SEND_FOR_VOTE_FULFILLED,
	SEND_FOR_VOTE_PENDING,
	SET_ACTIVE,
	SET_SELECTED,
	SET_PRESENTING,
	STOP_VOTE_FULFILLED,
	STOP_VOTE_PENDING,
	TIMESTAMP_ITEM_FULFILLED,
	TIMESTAMP_ITEM_PENDING,
	UPDATE_MEETING,
	UPDATE_MEETING_DATE,
	UPDATE_MEETING_VOTING_DATA,
	UPDATE_MINUTES_ITEMS,
	UPDATE_MOTION,
	UPDATE_VOTE,
	START_TIEBREAKER_VOTE_PENDING,
	START_TIEBREAKER_VOTE_FULFILLED,
} from "./types";
import { normalizeLinks } from "utils/processHtml";
import { customNumberingConversion, customNumberingSelected } from "utils/customNumberingConversions";

const checkItemsForPersistObject = (persistObject, items) => {
	return persistObject.every((obj2) => items.some((obj1) => obj1.guid === obj2.guid));
};

const getVotingTimeout = () => Date.now() + 60000; // One minute timeout

const getUpdatedVotesBeingSaved = (votesBeingSaved = [], votingData = null, addVotingData = false) => {
	const now = Date.now();

	const updatedVotesBeingSaved = votesBeingSaved.filter(
		(voteData) => (votingData && (voteData.guid !== votingData.guid || voteData.userId !== votingData.userId)) || voteData.expires < now,
	);
	if (addVotingData) {
		updatedVotesBeingSaved.push({ ...votingData, expires: getVotingTimeout() });
	}

	return updatedVotesBeingSaved;
};

export const meetingsReducer = (
	state = {
		active: undefined,
		agendaItems: undefined,
		agendaNumbering: undefined,
		meeting: undefined,
		meetingActive: false,
		meetingDate: undefined,
		meetingGroups: undefined,
		members: undefined,
		minutesItems: undefined,
		minutesNumbering: undefined,
		minutesUpdated: false,
		presenting: false,
		requestsToSpeak: undefined,
		selected: undefined,
		showOutline: true,
		updated: false,
		updating: false,
		uploadQueue: [],
		savingVote: false,
		finishVoteItem: null,
		finishVoteDisposition: null,
		shouldFinishVote: false,
	},
	action,
) => {
	switch (action.type) {
		case ADD_TEXT: {
			const { minutesItems } = state;

			const minutesItem = minutesItems.find((item) => item.guid === action.guid);

			if (!minutesItem) break;

			minutesItem.fields.Text.Value = minutesItem.fields.Text.Value || "<p></p>";

			state = {
				...state,
				minutesItems,
			};

			break;
		}

		case CHECK_FILE_UPLOADS: {
			const { itemGuid, fileGuid } = action;

			break;
		}

		case REMOVE_TEXT: {
			const { minutesItems } = state;

			const minutesItem = minutesItems.find((item) => item.guid === action.guid);

			minutesItem.fields.Text.Value = null;

			state = {
				...state,
				minutesItems,
			};

			break;
		}

		case ADD_MOTION: {
			const { motion, guid } = action;

			const { minutesItems, meeting } = state;

			let newMotion = {
				fields: {
					Name: { Value: "" },
					Text: { Value: "" },
					Order: { Value: 0 },
				},
			};
			let parentItem = motion;
			if (motion.itemType === 8) {
				parentItem = minutesItems.find((minuteItem) => minuteItem.guid === motion.attributes.relationshipGuid);
			}

			const index = minutesItems.findIndex((item) => {
				return item.guid === motion.guid;
			});

			minutesItems.splice(
				index + 1,
				0,
				createMeetingElement(
					{
						guid: guid,
						itemType: 8,
						order: parseInt(newMotion.fields.Order.Value, 10) + 1,
						name: newMotion.fields.Name.Value,
						text: newMotion.fields.Text.Value,
						closed: parentItem.fields.Closed.Value,
						consent: parentItem.fields.Consent.Value,
						publicComment: parentItem.fields.PublicComment.Value,
						parentGuid: parentItem.guid,
						agendaItemGuid: parentItem.agendaItemGuid,
						recordedVote: meeting.alwaysUseRecordedVote,
					},
					false,
				),
			);

			//get parent item other motions
			const motions = minutesItems.filter((item) => item.itemType === 8 && item.attributes.relationshipGuid === parentItem.guid);
			let shouldPersistObject = false;
			const newPersistObject = { id: meeting.id, items: [] };
			if (motions && motions.length > 1) {
				let newOrder = 0;
				motions.forEach((motion) => {
					motion.fields.Order.Value = newOrder.toString();
					newOrder++;
					newPersistObject.items.push(motion);
				});
				shouldPersistObject = true;
			}

			state = {
				...state,
				minutesItems: [...minutesItems],
				persistObject: shouldPersistObject ? newPersistObject : { ...state.persistObject },
				shouldPersistMinutes: shouldPersistObject,
			};

			break;
		}

		case DELETE_MOTION: {
			const guidToDelete = action.guidToDelete;

			const { minutesItems, meeting } = state;
			const persistObject = { id: meeting.id, items: [], itemIdsToDelete: [guidToDelete] };

			const index = minutesItems.findIndex((item) => item.guid === guidToDelete);

			if (index >= 0) {
				minutesItems.splice(index, 1);
			}

			state = {
				...state,
				minutesItems: [...minutesItems],
				persistObject: persistObject,
				shouldPersistMinutes: true,
			};
			break;
		}

		case UPDATE_MOTION: {
			const { motion, updatedFields } = action;

			const { minutesItems, meeting } = state;

			const persistObject = { id: meeting.id, items: [] };

			const minuteItem = minutesItems.find((minuteItem) => minuteItem.guid === motion.guid);
			minuteItem.fields = {
				...motion.fields,
				...updatedFields,
			};

			persistObject.items.push(minuteItem);

			state = {
				...state,
				persistObject: persistObject,
				shouldPersistMinutes: true,
			};
			break;
		}

		case PERSIST_VOTE_PENDING: {
			const { votingData } = action;
			const { votesBeingSaved = [] } = state;

			state = {
				...state,
				savingVote: true,
				savedVote: false,
				votesBeingSaved: getUpdatedVotesBeingSaved(votesBeingSaved, votingData, true),
			};
			break;
		}

		case PERSIST_VOTE_FULFILLED: {
			const { votingData } = action;
			const { votesBeingSaved = [] } = state;

			state = {
				...state,
				savingVote: false,
				savedVote: true,
				votesBeingSaved: getUpdatedVotesBeingSaved(votesBeingSaved, votingData),
			};
			break;
		}

		case SEND_FOR_VOTE_PENDING: {
			// No state change
			break;
		}

		case SEND_FOR_VOTE_FULFILLED: {
			// No state change
			break;
		}

		case STOP_VOTE_PENDING: {
			// No state change
			break;
		}

		case STOP_VOTE_FULFILLED: {
			const { motionGuid } = action;
			const { votesBeingSaved } = state;

			state = {
				...state,
				savingVote: false,
				votesBeingSaved:
					votesBeingSaved && votesBeingSaved.length > 0
						? votesBeingSaved.filter((voteData) => voteData.guid !== motionGuid)
						: votesBeingSaved,
				finishVoteItem: null,
				finishVoteDisposition: null,
				shouldFinishVote: false,
			};
			break;
		}

		case START_TIEBREAKER_VOTE_PENDING: {
			// No state change
			break;
		}

		case START_TIEBREAKER_VOTE_FULFILLED: {
			// No state change
			break;
		}

		case UPDATE_VOTE: {
			const { motionGuid, userId, vote } = action;
			const { minutesItems, votesBeingSaved = [] } = state;

			const minuteItem = minutesItems.find((minuteItem) => minuteItem.guid === motionGuid);

			if (minuteItem.fields.Voting && minuteItem.fields.Voting.Value) {
				minuteItem.fields.Voting.Value.forEach((motionVoting) => {
					if (motionVoting.UserId === userId && motionVoting.Vote !== vote) {
						motionVoting.Vote = vote;
					}
				});
			}

			state = {
				...state,
				votesBeingSaved: getUpdatedVotesBeingSaved(votesBeingSaved, { guid: motionGuid, userId }, true),
			};
			break;
		}

		case GET_AGENDA_ITEMS_PENDING: {
			state = {
				...state,
				updated: false,
				updating: true,
			};
			break;
		}
		case GET_AGENDA_ITEMS_FULFILLED: {
			const { items, meeting, requestsToSpeak, agendaNumbering } = action.payload;

			if (meeting && meeting.rollCall) {
				// Give each user a number to use for the avatar background
				let number = 0;
				const numberCache = {};
				meeting.rollCall.users.forEach((user) => {
					if (typeof numberCache[user.userId] === "undefined") {
						numberCache[user.userId] = number;
						number++;
					}
					user.number = numberCache[user.userId];
				});
			}

			state = {
				...state,
				agendaItems: items,
				agendaNumbering,
				meeting: meeting,
				meetingActive: meeting.startTimeStamp <= Math.floor(Date.now() / 1000),
				requestsToSpeak,
				updated: true,
				updating: false,
			};
			break;
		}

		case GET_MEETING_GROUPS_PENDING: {
			state = {
				...state,
				updating: true,
				updated: false,
			};
			break;
		}
		case GET_MEETING_GROUPS_FULFILLED: {
			state = {
				...state,
				meetingGroups: action.payload.boards,
				updated: true,
				updating: false,
			};
			break;
		}

		case GET_MEMBERS_PENDING: {
			state = {
				...state,
				updating: true,
				updated: false,
			};
			break;
		}
		case GET_MEMBERS_FULFILLED: {
			state = {
				...state,
				members: action.payload,
				updated: true,
				updating: false,
			};
			break;
		}

		case GET_MINUTES_ITEMS_PENDING: {
			state = {
				...state,
				updating: true,
				minutesUpdated: false,
			};
			break;
		}

		case GET_MINUTES_ITEMS_FULFILLED: {
			const { meeting, items, additionalUsers, numbering, header, footer, customNumbering } = action.payload;
			const minutesItem = items.find((item) => item.agendaItemGuid === meeting.activeGuid);
			const persistObject = { id: meeting.id, items: [] };
			persistObject.customNumbering = customNumbering;

			// Set any missing or incorrect numbering and save it
			updateOrderAndNumbering(
				persistObject,
				items.filter((item) => !item.attributes?.relationshipGuid && !item.deleted),
				undefined,
				items,
			);

			items.forEach((item) => {
				item.fields.Name.Value = item.fields.Name.Value.replaceAll("</a><", "</a>&nbsp;<");
			});

			state = {
				...state,
				meetingId: meeting.id, // Ensure that persistMinutes has the meeting ID available without breaking anything that assumes that the meeting object comes from getagendaitems
				header,
				footer,
				additionalUsers,
				minutesItems: items,
				minutesNumbering: numbering,
				persistObject: persistObject,
				minutesUpdated: true,
				presenting: meeting?.live,
				selected: minutesItem ? minutesItem.guid : meeting.activeGuid,
				updating: false,
				shouldPersistMinutes: persistObject.items.length > 0,
			};
			break;
		}

		case GET_PLACEHOLDER_VALUES_PENDING: {
			state = {
				...state,
			};
			break;
		}

		case GET_PLACEHOLDER_VALUES_FULFILLED: {
			state = {
				...state,
				placeholders: action.payload,
			};
			break;
		}

		case PERSIST_FILE_PENDING: {
			state = {
				...state,
				uploading: true,
			};
			break;
		}
		case PERSIST_FILE_FULFILLED: {
			const { attachments } = action;
			const { uploadQueue } = state;
			let newUploadQueue = uploadQueue;
			const itemIdsToUpdate = [];
			forEach((attachment) => {
				// Complete file upload
				const itemGuid = attachment.itemGuid;
				const fileGuid = attachment.guid;
				for (let i = 0; i < newUploadQueue.length; i++) {
					if (newUploadQueue[i].itemGuid === itemGuid && newUploadQueue[i].fileGuid === fileGuid) {
						newUploadQueue[i].complete = true;
						newUploadQueue[i].error = null;
					}
				}
				itemIdsToUpdate.push(attachment.itemGuid);
			}, attachments);

			// Manage tooltip
			let tooltip = null;
			for (let i = 0; i < newUploadQueue.length; i++) {
				if (!newUploadQueue[i].complete) {
					if (tooltip) {
						tooltip += ` / ${newUploadQueue[i].file.name}`;
					} else {
						tooltip = newUploadQueue[i].file.name;
					}
				}
			}
			state = {
				...state,
				uploading: false,
				itemIdsToUpdate,
				uploadQueue: newUploadQueue,
				uploadingTooltip: tooltip,
			};
			break;
		}

		case PERSIST_MINUTES_PENDING: {
			state = {
				...state,
				shouldPersistMinutes: false,
				persisting: true,
				persisted: false,
			};
			break;
		}

		case PERSIST_MINUTES_FULFILLED: {
			const { meeting = {} } = state;
			state = {
				...state,
				meeting: Object.assign(meeting, action.meeting), // Avoid creating a new object to prevent unnecessary re-renders
				persisting: false,
				persisted: true,
			};
			break;
		}

		case PERSIST_MINUTES_FAILED: {
			state = {
				...state,
				persisting: false,
				persisted: false,
			};
			break;
		}

		case QUEUE_FILE_UPLOADS: {
			const { itemGuid, fileUploads } = action;
			const { uploadQueue } = state;
			const newUploadQueue = uploadQueue;
			for (let i = 0; i < fileUploads.length; i++) {
				newUploadQueue.push({
					itemGuid,
					fileGuid: fileUploads[i].guid,
					file: fileUploads[i],
					complete: false,
					error: null,
				});
			}

			// Manage tooltip
			let tooltip = null;
			for (let i = 0; i < newUploadQueue.length; i++) {
				if (!newUploadQueue[i].complete) {
					if (tooltip) {
						tooltip += ` / ${newUploadQueue[i].file.name}`;
					} else {
						tooltip = newUploadQueue[i].file.name;
					}
				}
			}
			state = {
				...state,
				uploading: false,
				uploadQueue: newUploadQueue,
				uploadingTooltip: tooltip,
			};
		}

		case SET_ACTIVE: {
			const { active } = state;
			const { guid, focus, field } = action;
			let newActive = active;
			if (guid) {
				newActive = guid;
			} else {
				newActive = undefined;
			}
			state = {
				...state,
				active: newActive,
				focus,
				field,
			};
			break;
		}

		case SET_PRESENTING: {
			state = {
				...state,
				presenting: action.presenting,
			};
			break;
		}

		case SET_SELECTED: {
			const { guid } = action;

			state = {
				...state,
				selected: guid,
			};
			break;
		}

		case TIMESTAMP_ITEM_PENDING: {
			break;
		}
		case TIMESTAMP_ITEM_FULFILLED: {
			const { timestamp, guid } = action;

			const { minutesItems } = state;
			const timeStampedItem = minutesItems.find((timeStampedItem) => timeStampedItem.guid === guid);

			if (timeStampedItem) {
				timeStampedItem.fields.TimeStamp = { Value: timestamp };
			}
			state = {
				...state,
				minutesItems: minutesItems,
			};

			break;
		}

		case UPDATE_MEETING: {
			state = {
				...state,
				meeting: action.meeting,
			};
			break;
		}

		case UPDATE_MEETING_DATE: {
			state = {
				...state,
				meetingDate: action.meetingDate,
			};
			break;
		}

		case UPDATE_MEETING_VOTING_DATA: {
			const { itemInProgress, votingSettings, quorumNotMetLabel } = action;
			const { minutesItems, meeting, votesBeingSaved = [] } = state;

			const persistObject = { id: meeting.id, items: [] };

			const currentMinuteItem = minutesItems.find((item) => item.guid === itemInProgress.guid);
			if (currentMinuteItem.fields.Voting && currentMinuteItem.fields.Voting.Value) {
				currentMinuteItem.fields.Voting.Value.forEach((motionVoting) => {
					// Only update a vote if it's not being saved
					if (!votesBeingSaved.find((voteData) => voteData.guid === currentMinuteItem.guid && voteData.userId === motionVoting.UserId)) {
						const serverVotingData = itemInProgress.itemVotingData.find((itemVotingData) => itemVotingData.UserId === motionVoting.UserId);
						if (serverVotingData && motionVoting.Vote !== serverVotingData.Vote) {
							motionVoting.Vote = serverVotingData.Vote;
							currentMinuteItem.forceUpdate = new Date().getTime();
						}
					}
				});
			} else {
				currentMinuteItem.fields.Voting = { Value: itemInProgress.itemVotingData };
				currentMinuteItem.forceUpdate = new Date().getTime();
			}
			let persistMinutes = false;
			let finishVote = false;
			let disposition = "";
			if (votingSettings && checkVotingFinished(currentMinuteItem, meeting.rollCall)) {
				const selectedRollCall = meeting.otherRollCallTypes
					? meeting.otherRollCallTypes.find((r) => r.id == currentMinuteItem.fields.SelectedRollCallId)
					: null;
				const votingResults = getVotingResults(currentMinuteItem, selectedRollCall ? selectedRollCall : meeting.rollCall, meeting);
				disposition = votingResults.quorumMet
					? votingResults.votePassed
						? votingSettings.votingLabels.carried
						: votingSettings.votingLabels.failed
					: quorumNotMetLabel;
				if (currentMinuteItem.fields.Disposition.Value != disposition) {
					currentMinuteItem.fields.Disposition.Value = disposition;
					currentMinuteItem.forceUpdate = new Date().getTime(); // Force the motion component to update it's fields
				}

				persistObject.items.push(currentMinuteItem);
				persistMinutes = true;
				finishVote = true;
			}
			state = {
				...state,
				persistObject: persistObject,
				shouldPersistMinutes: persistMinutes,
				finishVoteItem: currentMinuteItem,
				finishVoteDisposition: disposition,
				shouldFinishVote: finishVote,
			};
			break;
		}

		case UPDATE_MINUTES_ITEMS: {
			const fieldData = action.fieldData;
			const editorFields = action.editorFields;
			const { meeting, minutesItems, header, footer } = state;
			let newFooter = footer;
			let newHeader = header;
			const persistObject = { id: null, items: [] };
			if (meeting) {
				persistObject.id = meeting.id;
				fieldData.forEach((field) => {
					const editorField = editorFields.find((f) => f.name === field.fieldName);
					const guid = field.fieldName.replace("-text", "");
					if (guid === "toc-header" || guid === "toc-footer") {
						if (guid === "toc-header") {
							if (header.replace(/ \/>/g, ">") === field.fieldData) return;
							newHeader = field.fieldData;
							persistObject.header = addTableWidth(field, field.fieldData);
							return;
						}
						if (guid === "toc-footer") {
							if (footer.replace(/ \/>/g, ">") === field.fieldData) return;
							newFooter = field.fieldData;
							persistObject.footer = addTableWidth(field, field.fieldData);
							return;
						}
					} else {
						const minutesItem = minutesItems.find((item) => item.guid === guid);
						const persistItem = persistObject.items.find((item) => item.guid === guid);
						if (minutesItem) {
							if (field.fieldName.includes("-text")) {
								if (
									!editorField ||
									(!editorField.deleted &&
										minutesItem.fields.Text.Value &&
										normalizeLinks(minutesItem.fields.Text.Value.replace(/ \/>/g, ">")) === normalizeLinks(field.fieldData)) ||
									(!editorField.deleted && minutesItem.fields.Text.Value === null && field.fieldData === "") ||
									(editorField.deleted && minutesItem.fields.Text.Value === null)
								)
									return;
								minutesItem.fields.Text.Value = editorField.deleted ? null : addTableWidth(field, field.fieldData);
								checkAttachments(minutesItem);
								if (persistItem) {
									persistItem.fields.Text.Value = editorField.deleted ? null : addTableWidth(field, field.fieldData);
								} else {
									persistObject.items.push(minutesItem);
								}
								return;
							}
							if (
								(minutesItem.fields.Name.Value &&
									normalizeLinks(minutesItem.fields.Name.Value.replace(/ \/>/g, ">")) === normalizeLinks(field.fieldData)) ||
								(!minutesItem.fields.Name.Value && !field.fieldData)
							)
								return;
							minutesItem.fields.Name.Value = addTableWidth(field, field.fieldData);
							checkAttachments(minutesItem);
							if (persistItem) {
								persistItem.fields.Name.Value = addTableWidth(field, field.fieldData);
							} else {
								persistObject.items.push(minutesItem);
							}
							return;
						}
					}
				});
			}

			state = {
				...state,
				header: newHeader,
				footer: newFooter,
				persistObject: persistObject,
				shouldPersistMinutes:
					typeof persistObject.header !== "undefined" || typeof persistObject.footer !== "undefined" || persistObject.items.length > 0,
			};
			break;
		}

		case RESET_MEETING: {
			state = {
				active: undefined,
				agendaItems: undefined,
				agendaNumbering: undefined,
				meeting: undefined,
				meetingActive: undefined,
				meetingDate: undefined,
				meetingGroups: undefined,
				members: undefined,
				minutesItems: undefined,
				minutesNumbering: undefined,
				minutesUpdated: false,
				requestsToSpeak: undefined,
				updated: false,
				updating: false,
			};
			break;
		}
		default: {
			break;
		}
	}

	return state;
};

export default meetingsReducer;
