/* eslint-disable no-param-reassign */
import React from "react";
import compact from "lodash/fp/compact";
import forEach from "lodash/fp/forEach";
import { useDroppable } from "@dnd-kit/core";

import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";

import AgendaItemTypesEnum from "utils/enums/AgendaItemTypes";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import DropPlaceholder from "atlas/components/DragAndDrop/DropPlaceholder";
import Droppable from "atlas/components/DragAndDrop/Droppable";
import TOCHeading from "./TOCHeading";
import TOCAgendaItem from "./TOCAgendaItem";
import TOCRecommendation from "./TOCRecommendation";
import { findItemByID } from "../functions/utils";

const useStyles = makeStyles(() => ({
	checkbox: {
		margin: "-8px 0 -8px -9px",
	},
}));

export const TOP_DROP_PLACEHOLDER = "top";

const TableOfContents = (props) => {
	const {
		items,
		active,
		isSmallDevice,
		toggleTableOfContent,
		isClosedMeeting,
		idPrefix,
		buttons,
		checkbox,
		lineLimit,
		hideHeadings,
		hideItems,
		hideRecommendations,
		hideAttachments,
		canDrag,
		dragPlaceholders = [],
		canDrop,
		dropId,
		dropPlaceholder,
		handleCancelMoveCopy,
		telemetryPage,
	} = props;
	const { setNodeRef } = canDrop
		? useDroppable({
				id: dropId,
		  })
		: {};
	const classes = useStyles();

	if (!items) {
		return <CircularProgressIndicator />;
	}

	let isConsentSection = false;
	let isPublicCommentSection = false;
	let isMemberOnlySection = false;

	const itemList = () => {
		const agendaItems = [];

		const parserOptions = {
			replace: (node) => {
				if (!node.attribs) return;
				if (["img", "br"].includes(node.name) && node.attribs.style) {
					node.attribs.style = "";
				}
				if (node.name === "p") {
					node.attribs.style = "margin-top: 0; margin-bottom: 0;";
				}
				if (node.name === "a" && node.attribs.href.indexOf("/document/") >= 0 && node.attribs.href.indexOf("/home/document/") < 0) {
					node.attribs.href = node.attribs.href.replace("/document/", "/home/document/");
				}
			},
		};

		if (canDrop && dropPlaceholder === TOP_DROP_PLACEHOLDER) {
			agendaItems.push(
				<Droppable
					dropComponent={DropPlaceholder}
					key={`placeholder-after-${TOP_DROP_PLACEHOLDER}`}
					dropId={`${item.guid}-placeholder-top`}
					component="li"
				></Droppable>,
			);
		}

		forEach((item) => {
			if (item.deleted) return null;

			isConsentSection = item && item.fields && item.fields.Consent && item.fields.Consent.Value;
			isPublicCommentSection = item && item.fields && item.fields.PublicComment && item.fields.PublicComment.Value;
			isMemberOnlySection = item && item.fields && item.fields.Closed && item.fields.Closed.Value;
			const checkboxSettings = checkbox
				? {
						checked: !!checkbox.selectedItems.find((selectedItem) => selectedItem === item.guid),
						onCheckChange: checkbox.onCheckChange,
						className: classes.checkbox,
				  }
				: undefined;

			if (item.itemType === AgendaItemTypesEnum().HEADING.value && !hideHeadings) {
				agendaItems.push(
					<TOCHeading
						isSmallDevice={isSmallDevice}
						isConsentSection={isConsentSection}
						isPublicCommentSection={isPublicCommentSection}
						isMemberOnlySection={isMemberOnlySection}
						toggleTableOfContent={toggleTableOfContent}
						isSubHeading={Boolean(item.attributes.relationshipGuid)}
						active={active}
						key={item.guid}
						section={item}
						parserOptions={parserOptions}
						isClosedMeeting={isClosedMeeting}
						idPrefix={idPrefix}
						button={buttons}
						checkbox={checkboxSettings}
						lineLimit={lineLimit}
						hideAttachments={hideAttachments}
						canDrag={canDrag}
						dragPlaceholder={dragPlaceholders.includes(item.guid)}
						canDrop={canDrop}
						handleCancelMoveCopy={handleCancelMoveCopy}
						telemetryPage={telemetryPage}
					/>,
				);
			}

			if (item.itemType === AgendaItemTypesEnum().ITEM.value && !hideItems) {
				agendaItems.push(
					<TOCAgendaItem
						isConsentSection={isConsentSection}
						isPublicCommentSection={isPublicCommentSection}
						isMemberOnlySection={isMemberOnlySection}
						isSmallDevice={isSmallDevice}
						toggleTableOfContent={toggleTableOfContent}
						active={active}
						key={item.guid}
						item={item}
						parserOptions={parserOptions}
						isClosedMeeting={isClosedMeeting}
						idPrefix={idPrefix}
						button={buttons}
						checkbox={checkboxSettings}
						lineLimit={lineLimit}
						hideAttachments={hideAttachments}
						canDrag={canDrag}
						dragPlaceholder={dragPlaceholders.includes(item.guid)}
						canDrop={canDrop}
						handleCancelMoveCopy={handleCancelMoveCopy}
						telemetryPage={telemetryPage}
					/>,
				);
			}

			if (item.itemType === AgendaItemTypesEnum().RECOMMENDATION.value && !hideRecommendations) {
				const parent = findItemByID(item.attributes.relationshipGuid, items);
				agendaItems.push(
					<TOCRecommendation
						isSmallDevice={isSmallDevice}
						isConsentSection={isConsentSection}
						isPublicCommentSection={isPublicCommentSection}
						isMemberOnlySection={isMemberOnlySection}
						toggleTableOfContent={toggleTableOfContent}
						isHeadingAction={parent && parent.itemType === AgendaItemTypesEnum().HEADING.value}
						active={active}
						key={item.guid}
						recommendation={item}
						parserOptions={parserOptions}
						isClosedMeeting={isClosedMeeting}
						idPrefix={idPrefix}
						button={buttons}
						checkbox={checkboxSettings}
						lineLimit={lineLimit}
						hideAttachments={hideAttachments}
						canDrag={canDrag}
						dragPlaceholder={dragPlaceholders.includes(item.guid)}
						canDrop={canDrop}
						handleCancelMoveCopy={handleCancelMoveCopy}
						telemetryPage={telemetryPage}
					/>,
				);
			}

			if (canDrop && dropPlaceholder === item.guid) {
				agendaItems.push(
					<Droppable
						dropComponent={DropPlaceholder}
						key={`placeholder-after-${item.guid}`}
						dropId={`${item.guid}-placeholder`}
						component="li"
					></Droppable>,
				);
			}
		}, items);

		return compact(agendaItems);
	};

	return (
		<List className="toc-list" ref={setNodeRef}>
			{itemList()}
		</List>
	);
};

export default TableOfContents;
