import {
	closeButton,
	compareZoomIn,
	compareZoomOut,
	enterFullScreen,
	exitFullScreen,
	freeHandToolButton,
	highlightButton,
	menuButton,
	notesPanelButton,
	panToolButton,
	selectToolButton,
	stickyToolNotes,
	toggleNotesButton,
	viewControlsSettings,
	viewControlsSettingsMemberAgenda,
	zoomIn,
	zoomOut,
} from "../Assets/PdftronIcons";
import { deleteSignature, loadSignature, saveSignature } from "../PdfAPICalls";
import { displaySuccessNotification } from "views/Policy/utils/getNotification";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { AnnotationEdit } from "../Assets/AnnotationEdit";
import { Highlight } from "../Assets/Highlight";
import { Underline } from "../Assets/Underline";
import { Freehand } from "../Assets/Freehand";
import { StickyNotes } from "../Assets/StickyNotes";

let instanceLocal = "";
let userLocal = "";
let enableSignaturesLocal = "";
let dispatchLocal, ti18n;
let cssEnabledFullScreen = false;
let isAgendaMobileEnabled = null;

//This method is called when user creates a new signature and save it.
const saveUserSignature = (annotations) => {
	if (enableSignaturesLocal && annotations) {
		annotations.forEach((annotation) => {
			const dataURL = getAnnotationAsDataUrl(annotation);
			saveSignature(userLocal, dataURL, annotation).then((saveSignatureResponse) => {
				if (saveSignatureResponse.status === 200) {
					displaySuccessNotification(ti18n("notifications.signatureSaved"), dispatchLocal);
				}
			});
		});
	}
};

//This method will be triggered when user deletes any signature
const deleteUserSignature = (annotation) => {
	const dataURL = getAnnotationAsDataUrl(annotation);
	deleteSignature(userLocal, dataURL).then((deleteSignatureResponse) => {
		if (deleteSignatureResponse.status === 200) {
			displaySuccessNotification(ti18n("notifications.signatureDeleted"), dispatchLocal);
		}
	});
};

//This method is called to convert image data of signature to dataurl to save in backend.
const getAnnotationAsDataUrl = (annotation) => {
	const { documentViewer } = instanceLocal.Core;
	let dataURL = "";
	if (annotation.elementName === "ink") {
		const canvas = document.createElement("canvas");
		const pageMatrix = documentViewer.getDocument().getPageMatrix(annotation.PageNumber);
		canvas.height = annotation.Height;
		canvas.width = annotation.Width;
		const ctx = canvas.getContext("2d");
		ctx.translate(-annotation.X, -annotation.Y);
		annotation.draw(ctx, pageMatrix);
		dataURL = canvas.toDataURL("image/png");
	} else {
		dataURL = annotation.ImageData;
	}
	return dataURL;
};

//This method is called to load signatures for the respective user
const loadUserSignatures = async () => {
	if (enableSignaturesLocal) {
		const { documentViewer } = instanceLocal.Core;
		instanceLocal.UI.setMaxSignaturesCount(3);
		const signatureTool = documentViewer.getTool("AnnotationCreateSignature");
		const signatures = [];
		await loadSignature(userLocal).then((signatureResponse) => {
			Array.isArray(signatureResponse) &&
				signatureResponse.forEach((data) => {
					if (data.signature !== "") {
						signatures.push(data.signature);
					}
				});
		});
		if (signatures.length > 0) {
			await signatureTool.importSignatures(signatures);
		}
		signatureTool.addEventListener("signatureSaved", saveUserSignature);
		signatureTool.addEventListener("signatureDeleted", deleteUserSignature);
	}
};

export const setSignatureButtonUI = (pdfViewIframe, enableSignatures, instance, userId, dispatch, t) => {
	instanceLocal = instance;
	userLocal = userId;
	enableSignaturesLocal = enableSignatures;
	dispatchLocal = dispatch;
	ti18n = t;
	const signatureButton = pdfViewIframe.querySelector("div[data-element='toolsOverlay']");
	const { UI } = instance;
	UI.setHeaderItems((header) => {
		const items = header.getItems();
		const searchButton = items.find((item) => item.dataElement === "searchButton");
		const toolsOverlayButton = items.find((item) => item.dataElement === "toolsOverlay");
		if (searchButton && !toolsOverlayButton) {
			items.splice([items.indexOf(searchButton) - 2], 0, {
				type: "customElement",
				dataElement: "toolsOverlay",
				render: () => signatureButton,
				hidden: ["small-mobile", "mobile"],
			});
			header.update([...items]);
		}
	});
	loadUserSignatures();
};

const closeCustomDropdown = (pdfViewIframe) => {
	let customAnnotationDropdown = pdfViewIframe.querySelector(`[data-element="customAnnotationOptions"]`);
	if (customAnnotationDropdown && !customAnnotationDropdown.className.includes("closed")) {
		customAnnotationDropdown.className += " closed";
	}
};

export const setCustomAnnotationClick = (pdfViewIframe, instance) => {
	let documentContainer = pdfViewIframe.querySelector(`[data-element="documentContainer"]`);
	pdfViewIframe.addEventListener("click", (e) => {
		if (e.target.dataset.element !== "customAnnotationButton") {
			closeCustomDropdown(pdfViewIframe);
		}
	});
	documentContainer.addEventListener("click", () => {
		closeCustomDropdown(pdfViewIframe);
	});
	window.onclick = () => {
		closeCustomDropdown(pdfViewIframe);
		let filterModal = pdfViewIframe.querySelector("div[class='Modal FilterAnnotModal open']");
		if (filterModal) {
			instance.UI.closeElements(["filterModal"]);
		}
	};
};

export const getTitleForFullScreen = () => {
	return document.fullscreenElement ? "Exit Full Screen" : "Enter Full Screen";
};

export const getImgForFullScreen = () => {
	return document.fullscreenElement ? exitFullScreen : enterFullScreen;
};

export const onCustomFullScreenChangeHandler = (instance, refCompare, isMemberAgenda) => {
	const { UI } = instance;
	if (refCompare && isMemberAgenda) {
		UI.updateElement("compare-full-screen", {
			img: getImgForFullScreen(),
			title: getTitleForFullScreen(),
		});
	}
	UI.updateElement("customFullScreenButton", {
		label: getTitleForFullScreen(),
		img: getImgForFullScreen(),
	});
	if (UI.isElementOpen("menuOverlay")) {
		UI.closeElements(["menuOverlay"]);
	}
};

//This method is used in Agenda Items screen if screen goes on full screen mode then header needs to be rearranged
export const reArrangeHeaderForFullScreenMode = (instance, refFullscreenMode, t) => {
	const { UI } = instance;
	UI.setHeaderItems((header) => {
		const items = header.getItems();
		refFullscreenMode && items.splice(-1);
		const openFullScreenButton = items.find((item) => item.dataElement === "view-full-screen");
		const selectToolButton = items.find((item) => item.toolName === "AnnotationEdit");
		refFullscreenMode
			? items.splice(
					items.indexOf(selectToolButton) + 1,
					0,
					{
						type: "spacer",
					},
					{
						dataElement: "view-full-screen",
						type: "actionButton",
						label: t("buttons.fullScreen"),
						onClick: () => {
							customToggleFullScreen(document.getElementById("viewer"), instance, t);
						},
					},
			  )
			: items.splice(items.indexOf(openFullScreenButton) - 1, 2);
		!refFullscreenMode &&
			items.splice(items.length, 0, {
				dataElement: "close-full-screen",
				type: "actionButton",
				img: closeButton,
				onClick: () => {
					customToggleFullScreen(document.getElementById("viewer"), instance, t);
				},
			});

		header.update([...items]);
	});
};

//This method is used to update icons of header items.
export const updateToolBarIcons = (UI) => {
	UI.updateElement("viewControlsButton", {
		img: viewControlsSettingsMemberAgenda,
	});
	UI.updateElement("selectToolButton", {
		img: selectToolButton,
	});
	UI.updateElement("menuButton", {
		img: menuButton,
	});
	UI.updateElement("notesPanelButton", {
		img: notesPanelButton,
	});
	UI.updateElement("highlightToolGroupButton", {
		img: highlightButton,
	});
	UI.updateElement("freeHandToolGroupButton", {
		img: freeHandToolButton,
	});
	UI.updateElement("stickyToolGroupButton", {
		img: stickyToolNotes,
	});
	UI.updateElement("toggleNotesButton", {
		img: toggleNotesButton,
		title: "Annotations",
	});
};

export const updateZoomIconsForCompareMode = (UI) => {
	UI.updateElement("zoomOutButton", {
		img: compareZoomOut,
	});
	UI.updateElement("zoomInButton", {
		img: compareZoomIn,
	});
};

export const updateZoomIcons = (UI) => {
	UI.updateElement("zoomOutButton", {
		img: zoomOut,
	});
	UI.updateElement("zoomInButton", {
		img: zoomIn,
	});
};

export const getFileNameForComparePolicy = (selectedCompareDoc) => {
	const { meetingId, meetingName, policyName } = selectedCompareDoc;
	let name = meetingId === 0 ? "Current Published Version" : meetingName;
	return `${name}-${policyName}`;
};

export const getImgForCssFullScreen = () => {
	return cssEnabledFullScreen ? exitFullScreen : enterFullScreen;
};

export const getTitleForCssFullScreen = () => {
	return cssEnabledFullScreen ? "Exit Full Screen" : "Enter Full Screen";
};

export const onCSSEnabledFullScreenChangeHandler = (instance) => {
	const { UI } = instance;
	if (cssEnabledFullScreen) {
		UI.updateElement("customFullScreenButton", {
			label: "Exit Full Screen",
			img: exitFullScreen,
		});
		UI.updateElement("compare-full-screen", {
			title: "Exit Full Screen",
			img: exitFullScreen,
		});
	} else {
		UI.updateElement("customFullScreenButton", {
			label: "Enter Full Screen",
			img: enterFullScreen,
		});
		UI.updateElement("compare-full-screen", {
			title: "Enter Full Screen",
			img: enterFullScreen,
		});
	}
	if (UI.isElementOpen("menuOverlay")) {
		UI.closeElements(["menuOverlay"]);
	}
};

const setCSSForFullScreen = (wrapper) => {
	wrapper.style.position = "fixed";
	wrapper.style.top = "0";
	wrapper.style.left = "0";
	wrapper.style.zIndex = "5001";
	wrapper.style.width = "100%";
};

const removeCSSForFullScreen = (wrapper) => {
	wrapper.style.position = "relative";
	wrapper.style.top = "unset";
	wrapper.style.left = "unset";
	wrapper.style.zIndex = "1201";
};

export const customToggleFullScreen = (wrapper, instance, tAgenda = false, isMobileAgenda = false) => {
	if (document.fullscreenElement || cssEnabledFullScreen) {
		telemetryAddEvent(`Agenda View - Full Screen disabled`);
		if (cssEnabledFullScreen) {
			cssEnabledFullScreen = false;
			removeCSSForFullScreen(wrapper);
			onCSSEnabledFullScreenChangeHandler(instance);
			if (tAgenda) {
				reArrangeHeaderForFullScreenMode(instance, true, tAgenda);
			}
			if (isAgendaMobileEnabled) {
				isAgendaMobileEnabled.style.display = "block";
				isAgendaMobileEnabled = null;
			}
		} else if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		}
	} else if (wrapper) {
		telemetryAddEvent(`Agenda View - Full Screen enabled`);
		if (wrapper.requestFullscreen) {
			wrapper.requestFullscreen();
		} else if (wrapper.webkitRequestFullscreen) {
			wrapper.webkitRequestFullscreen();
		} else if (wrapper.mozRequestFullScreen) {
			wrapper.mozRequestFullScreen();
		} else if (wrapper.msRequestFullscreen) {
			wrapper.msRequestFullscreen();
		} else if (!cssEnabledFullScreen) {
			cssEnabledFullScreen = true;
			setCSSForFullScreen(wrapper);
			onCSSEnabledFullScreenChangeHandler(instance);
			if (tAgenda) {
				reArrangeHeaderForFullScreenMode(instance, false, tAgenda);
			}
			if (isMobileAgenda) {
				isAgendaMobileEnabled = document.getElementById("agendaFullScreenContainer");
				isAgendaMobileEnabled.style.display = "none";
			}
		}
	}
};

export const setCustomUIElements = (instance, customizationOptions, printInternal) => {
	const { UI } = instance;
	const items = UI.settingsMenuOverlay.getItems();
	const { fullScreenCustom = null, downloadCustom = null, printCustom = null } = customizationOptions;
	UI.disableElements(["fullScreenButton"]);
	if (downloadCustom) {
		const { onClickUrl = null, customLabel = null } = downloadCustom;
		UI.updateElement("downloadButton", {
			...(onClickUrl && {
				onClick: (e) => {
					window.open(onClickUrl, "_blank");
					e.stopPropagation();
				},
			}),
			...(customLabel && { label: customLabel }),
		});
	}
	if (printCustom?.printButton1) {
		const { customlabel, onClickUrl } = printCustom.printButton1;
		UI.updateElement("printButton", {
			onClick: (e) => {
				window.open(onClickUrl, "_blank");
				e.stopPropagation();
			},
			label: customlabel,
		});
	}
	if (fullScreenCustom) {
		const { customWrapper, isViewerId } = fullScreenCustom;
		items.unshift({
			type: "actionButton",
			className: "Button ActionButton row",
			img: enterFullScreen,
			onClick: () => {
				if (customWrapper) {
					customToggleFullScreen(customWrapper, instance);
				} else if (isViewerId) {
					customToggleFullScreen(document.getElementById(isViewerId), instance);
				} else {
					customToggleFullScreen(document.getElementById("viewer"), instance);
				}
				UI.closeElements(["menuOverlay"]);
			},
			dataElement: "customFullScreenButton",
			label: "Enter Full Screen",
		});
	}
	if (printCustom?.printButton2) {
		const { customlabel, onClickUrl } = printCustom.printButton2;
		const customPrintButton = {
			type: "actionButton",
			className: "Button ActionButton row",
			img: "icon-header-print-line",
			onClick: () => {
				if (onClickUrl === "internal" && printInternal) {
					printInternal();
				} else {
					UI.print();
				}
			},
			dataElement: "customPrintButton",
			label: customlabel,
		};
		items.push(customPrintButton);
	}
	UI.settingsMenuOverlay.update(items);
};

export const toolModes = {
	default: {
		toolIcon: AnnotationEdit,
		toolName: "Pan",
	},
	AnnotationCreateTextHighlight: {
		toolIcon: Highlight,
		toolName: "Highlight",
	},
	AnnotationCreateTextUnderline: {
		toolIcon: Underline,
		toolName: "Underline",
	},
	AnnotationCreateFreeHand: {
		toolIcon: Freehand,
		toolName: "Freehand",
	},
	AnnotationCreateSticky: {
		toolIcon: StickyNotes,
		toolName: "Note",
	},
};

export const setCustomSearchAnnotations = (instance, annotationPresent = false) => {
	const { UI } = instance;
	const items = UI.settingsMenuOverlay.getItems();
	const isSearchPresent = items.find((item) => item.dataElement === "customSearchButton");
	let customSearchButton = [],
		customAnnotationButton = [];
	if (!isSearchPresent) {
		UI.disableElements(["searchButton"]);
		customSearchButton = {
			type: "actionButton",
			className: "Button ActionButton row",
			img: "icon-header-search",
			onClick: () => {
				UI.openElements(["searchPanel"]);
				UI.closeElements(["menuOverlay"]);
			},
			dataElement: "customSearchButton",
			label: "Search",
		};
		if (annotationPresent) {
			UI.disableElements(["toggleNotesButton"]);
			customAnnotationButton = {
				type: "actionButton",
				className: "Button ActionButton row",
				img: toggleNotesButton,
				onClick: () => {
					UI.openElements(["notesPanel"]);
					UI.closeElements(["menuOverlay"]);
				},
				dataElement: "customAnnotationButton",
				label: "Annotations",
			};
		}
		let overlayItems = [customSearchButton, ...(annotationPresent ? [customAnnotationButton] : []), ...items];
		UI.settingsMenuOverlay.update(overlayItems);
	}
};

export const removeCustomSearchAnnotations = (instance, annotationPresent) => {
	const { UI } = instance;
	let items = UI.settingsMenuOverlay.getItems();
	const customSearchButton = items.find((item) => item.dataElement === "customSearchButton");
	if (customSearchButton) {
		items = annotationPresent ? items.slice(2) : items.slice(1);
		UI.settingsMenuOverlay.update(items);
		UI.enableElements(["searchButton"]);
		annotationPresent && UI.enableElements(["toggleNotesButton"]);
	}
};

export const setDrawerModeForCustomAnnotationMenu = (instance, webviewerRef) => {
	const pdfViewIframe = instance.current.UI.iframeWindow.document;
	let annotationElement = pdfViewIframe.querySelector(
		"div[data-element='customAnnotationMenu'] > div > div[data-element='customAnnotationOptions']",
	);
	if (annotationElement) {
		const isCustomAnnotationMobile = webviewerRef.current !== 0 && webviewerRef.current <= 640;
		if (isCustomAnnotationMobile && !annotationElement.className.includes(" mobile")) {
			annotationElement.className += " mobile";
		} else if (!isCustomAnnotationMobile && annotationElement.className.includes(" mobile")) {
			annotationElement.className = annotationElement.className.replace(" mobile", "");
		}
	}
};

//This method is for updating compare-mode icons in header
export const updateLeftSideIcons = (UI) => {
	UI.updateElement("viewControlsButton", {
		img: viewControlsSettings,
	});
	UI.updateElement("selectToolButton", {
		img: selectToolButton,
	});
	UI.updateElement("panToolButton", {
		img: panToolButton,
	});
};
