/* eslint-disable no-param-reassign */
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import compact from "lodash/fp/compact";
import forEach from "lodash/fp/forEach";

import List from "@mui/material/List";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { useWidthDown } from "atlas/utils/useWidth";
import { HEADINGS, MEETING_ITEMS, DECISIONS } from "utils/enums/ItemTypes";
import { findItemByID } from "views/MeetingEditor/functions/utils";
import TOCDecision from "./TOCDecision";
import TOCHeading from "./TOCHeading";
import TOCMeetingItem from "./TOCMeetingItem";
import { StaffUserContext } from "../PdfViewerNew/PdfWithSideBar";

export const TOP_DROP_PLACEHOLDER = "top";

const TableOfContents = (props) => {
	const {
		items,
		isClosed,
		buttons,
		toggleTableOfContent = () => {},
		telemetryPage,
		selected,
		setActive,
		isPdfSidebar,
		setDataForAttachmentDocument,
		openGoalsPopup,
		setDataForOutlineItem,
		selectedId,
		showClosedItemDescription,
	} = props;
	const { t } = useTranslation();
	const widthDownMd = useWidthDown("md");

	if (!items) {
		return <CircularProgressIndicator />;
	}

	let isConsentSection = false;
	let isPublicCommentSection = false;
	let isMemberOnlySection = false;

	const isStaffUser = useContext(StaffUserContext);
	const showContent = (isMemberOnlySection, isRecommendation) => {
		if (isPdfSidebar) {
			if ((isStaffUser && isMemberOnlySection && isRecommendation) || (isStaffUser && isMemberOnlySection && !showClosedItemDescription)) {
				return false;
			}
			return true;
		}
		return true;
	};

	const itemList = () => {
		const tocItems = [];

		const parserOptions = {
			replace: (node) => {
				if (!node.attribs) return;
				if (["img", "br"].includes(node.name) && node.attribs.style) {
					node.attribs.style = "";
				}
				if (node.name === "p") {
					node.attribs.style = "margin-top: 0; margin-bottom: 0;";
				}
				if (node.name === "a" && node.attribs.href.indexOf("/document/") >= 0 && node.attribs.href.indexOf("/home/document/") < 0) {
					node.attribs.href = node.attribs.href.replace("/document/", "/home/document/");
				}
			},
		};

		const parserOptionsForPdf = (options) => ({
			replace: (node) => {
				if (!node.attribs) return;
				if (["img", "br"].includes(node.name) && node.attribs.style) {
					node.attribs.style = "";
				}
				if (node.name === "p") {
					node.attribs.style = "margin-top: 0; margin-bottom: 0;";
				}
				if (isPdfSidebar) {
					if (options && options.tooltip === true) {
						if (node.name === "span") {
							node.attribs.style = "color:white";
						}
					}
					if (node.name === "a" && node.attribs) {
						if (isStaffUser && node.attribs.class && node.attribs.class.indexOf("inlineFile closed") !== -1) {
							return <></>;
						} else {
							return (
								<a className="" target="" style={{ textDecoration: "none", color: "inherit" }}>
									{node.children[0].data}
								</a>
							);
						}
					}
					if (node.name === "br") {
						return <> </>;
					}
				}
			},
		});

		forEach((item) => {
			if (item.deleted) {
				return null;
			}

			isConsentSection = Boolean(item?.fields?.Consent?.Value);
			isPublicCommentSection = Boolean(item?.fields?.PublicComment?.Value);
			isMemberOnlySection = Boolean(item?.fields?.Closed?.Value);

			if (HEADINGS.includes(item.itemType)) {
				tocItems.push(
					<TOCHeading
						isSmallDevice={widthDownMd}
						isConsentSection={isConsentSection}
						isPublicCommentSection={isPublicCommentSection}
						isMemberOnlySection={isMemberOnlySection}
						isClosedMeeting={isClosed}
						toggleTableOfContent={toggleTableOfContent}
						isSubHeading={Boolean(item.attributes.relationshipGuid)}
						key={item.guid}
						section={item}
						parserOptions={isPdfSidebar ? parserOptionsForPdf : parserOptions}
						button={buttons}
						selected={selected}
						setActive={setActive}
						telemetryPage={telemetryPage}
						setDataForAttachmentDocument={setDataForAttachmentDocument}
						openGoalsPopup={openGoalsPopup}
						setDataForOutlineItem={setDataForOutlineItem}
						isPdfSidebar={isPdfSidebar}
						selectedId={selectedId}
					/>,
				);
			}

			if (MEETING_ITEMS.includes(item.itemType)) {
				showContent(isMemberOnlySection) &&
					tocItems.push(
						<TOCMeetingItem
							isConsentSection={isConsentSection}
							isPublicCommentSection={isPublicCommentSection}
							isMemberOnlySection={isMemberOnlySection}
							isClosedMeeting={isClosed}
							isSmallDevice={widthDownMd}
							toggleTableOfContent={toggleTableOfContent}
							key={item.guid}
							item={item}
							parserOptions={isPdfSidebar ? parserOptionsForPdf : parserOptions}
							button={buttons}
							selected={selected}
							setActive={setActive}
							telemetryPage={telemetryPage}
							setDataForAttachmentDocument={setDataForAttachmentDocument}
							setDataForOutlineItem={setDataForOutlineItem}
							isPdfSidebar={isPdfSidebar}
							selectedId={selectedId}
							openGoalsPopup={openGoalsPopup}
						/>,
					);
			}

			if (DECISIONS.includes(item.itemType)) {
				const parent = findItemByID(item.attributes.relationshipGuid, items);
				showContent(isMemberOnlySection, true) &&
					tocItems.push(
						<TOCDecision
							isSmallDevice={widthDownMd}
							isConsentSection={isConsentSection}
							isPublicCommentSection={isPublicCommentSection}
							isMemberOnlySection={isMemberOnlySection}
							toggleTableOfContent={toggleTableOfContent}
							isHeadingAction={parent && HEADINGS.includes(parent.itemType)}
							key={item.guid}
							resolution={item}
							parserOptions={isPdfSidebar ? parserOptionsForPdf : parserOptions}
							isClosedMeeting={isClosed}
							button={buttons}
							selected={selected}
							setActive={setActive}
							telemetryPage={telemetryPage}
							setDataForAttachmentDocument={setDataForAttachmentDocument}
							setDataForOutlineItem={setDataForOutlineItem}
							isPdfSidebar={isPdfSidebar}
							selectedId={selectedId}
							openGoalsPopup={openGoalsPopup}
						/>,
					);
			}
		}, items);

		return compact(tocItems);
	};

	return (
		<List className="toc-list">
			{!isPdfSidebar && (
				<TOCHeading
					section={{
						guid: "toc-header",
						fields: {
							Indent: { Value: "0" },
							Order: { Value: " " },
							Name: { Value: t("agendaMenu:meetingHeader") },
						},
					}}
					notReallyAnItem
					isSmallDevice={widthDownMd}
				/>
			)}
			{itemList()}
			{!isPdfSidebar && (
				<TOCHeading
					section={{
						guid: "toc-footer",
						fields: {
							Indent: { Value: "0" },
							Order: { Value: " " },
							Name: { Value: t("agendaMenu:meetingFooter") },
						},
					}}
					notReallyAnItem
					isSmallDevice={widthDownMd}
				/>
			)}
		</List>
	);
};

export default TableOfContents;
