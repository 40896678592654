import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { API_HOST } from "config/env";

import makeStyles from "@mui/styles/makeStyles";
import { Box, IconButton, ListItem, ListItemSecondaryAction, Typography, Link } from "@mui/material";

import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import Icon from "atlas/components/Icon/Icon";
import { whiteColor, blackColor } from "atlas/assets/jss/shared";
import telemetryAddEvent from "utils/telemetryAddEvent";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import LiveMeetingRadioButton from "views/LiveMeeting/components/LiveMeetingRadioButton";
import LiveMeetingAttachments from "views/LiveMeeting/components/LiveMeetingAttachments";
import TimeStampItem from "views/LiveMeeting/components/TimeStampItem";
import minutesStyle from "assets/jss/components/minutesStyle";
import Motion from "./Motion";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { displayErrorNotification, displaySuccessNotification } from "views/Policy/utils/getNotification";
import { publishPolicyAttachments } from "redux/policyGovernance/actions";
import { getVotingResults } from "views/LiveMeeting/utils/votingUtils";
import { formatDateForPolicy } from "views/Policy/utils/getFormattedDate";
import LiveMeetingPolicyPublishDialog from "./LiveMeetingPolicyPublishDialog";

const useStyle = makeStyles(minutesStyle);

const LiveMeetingItem = (props) => {
	const {
		item,
		isClosedMeeting,
		isMemberOnlyHeading,
		isConsentHeading,
		isPublicCommentHeading,
		isHeading,
		isItem,
		isResolution,
		isSubHeading,
		addBottomBorder,
		videoExists,
		meeting,
		rollCall,
		otherRollCallTypes,
		additionalUsers,
		minutesItems,
		selected,
		presenting,
		active,
		votingData,
		onlineVoters,
		votingSettings,
		adoptUpdating,
		policyData = [],
		handleSelect,
		elementsRef,
		elementsIndex,
		removeText,
		addText,
		editorFieldTextDeleted,
		forceUpdate,
		showSignIn,
		handleUpdateMotion,
		handleAddMotion,
		adoptPublishPreviousMinutes,
		isPolicyMotion,
		signalRClient,
	} = props;
	const { t } = useTranslation("agendaMenu");
	const { fields, attachments } = item;
	const classes = useStyle({ isSubHeading, addTopBorder: !isMemberOnlyHeading && !isConsentHeading });
	const [openMenu, setOpenMenu] = useState(false);
	const [selectedMenuOption, setSelectedMenuOption] = useState({});
	const [dialogs, setDialogs] = useState({});
	const { policyEnabled } = useContext(SettingsContext);
	const dispatch = useDispatch();

	//checkbox
	const isSelected = selected === item.guid;

	const addBottomBorderToThis = addBottomBorder && item.attachments.length === 0;

	let showAdoptPublishPreviousMinutes = null;
	let minutesToAdoptMeetingId = null;
	if (item && isItem && item.fields && item.fields.Consent && !item.fields.Consent.Value && item.itemType == 4) {
		showAdoptPublishPreviousMinutes = item.itemToAdoptPreviousMinutes;
		minutesToAdoptMeetingId = item.minutesToAdoptMeetingId;
	}
	let votingPassed = false;
	if (isResolution) {
		const votingResults = getVotingResults(item, rollCall, meeting);
		if (votingResults) {
			votingPassed = votingResults.votePassed;
		}
	}

	const publishPolicyMenuOptions = [
		{
			id: "publish-rescind",
			label: t("policyrescind"),
			ariaLabel: t("policyrescind"),
			actionFunction: (e) => {
				handleToggleMenuForPolicy(e, "rescind");
			},
			"data-cy": "publish-rescind",
		},
	];

	const handleToggleMenuForPolicy = (e, type) => {
		e.stopPropagation();
		e.preventDefault();
		let obj = {
			adoptedDate: formatDateForPolicy(meeting?.date),
			policyGuids: policyData.map((policy) => policy.sourcePolicyGuid),
		};
		if (type === "publish") {
			setDialogs({ publishPolicy: { data: obj, policies: policyData } });
		} else {
			telemetryAddEvent(`Policy - Minutes Builder Adopt and Publish (Rescind)`);
			obj = { ...obj, isPublishAndRescind: true };
			dispatch(publishPolicyAttachments(obj))
				.then((res) => {
					displaySuccessNotification(t("successMsg.publishrescind"), dispatch);
				})
				.catch((err) => {
					if (err.status === 500) {
						displayErrorNotification(t("errorMsg.publishrescind"), dispatch);
					} else {
						displayErrorNotification(t("errorMsg.commonMsg"), dispatch);
					}
				});
		}
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const isMotionEmpty = () =>
		item.fields.Name.Value === "" &&
		item.fields.Text.Value === "" &&
		item.fields.MovedBy.Value === 0 &&
		item.fields.SecondedBy.Value === 0 &&
		item.fields.Disposition.Value === "";

	return (
		!item.deleted && (
			<>
				{dialogs.publishPolicy && <LiveMeetingPolicyPublishDialog policyData={dialogs.publishPolicy} onClose={closeDialogs} />}
				{isHeading && isMemberOnlyHeading && !isSubHeading && (
					<li className={clsx(classes.headerListItem, classes.memberOnlyHeader)} data-cy={`agenda-header-member-only-${item.guid}`}>
						<Icon name="locked" color={whiteColor} size="16px" />
						<span className={classes.headerTextWithIcon}>{t("memberOnlySection")}</span>
					</li>
				)}
				{isHeading && isConsentHeading && !isSubHeading && (
					<li className={clsx(classes.headerListItem, classes.consentHeader)} data-cy={`agenda-header-consent-${item.guid}`}>
						<span>{t("consentSection")}</span>
					</li>
				)}
				{isHeading && isPublicCommentHeading && !isSubHeading && (
					<li className={clsx(classes.headerListItem, classes.publicCommentHeader)} data-cy={`agenda-header-public-comment-${item.guid}`}>
						<span>{t("publicCommentSection")}</span>
					</li>
				)}
				<div
					id={`agenda-${item.guid}`}
					className={clsx(classes.agendaListItem, {
						[classes.borderDefault]: !isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && !addBottomBorderToThis,
						[classes.borderDefaultBottomBorder]:
							!isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && addBottomBorderToThis,
						[classes.borderMemberOnly]: isMemberOnlyHeading,
						[classes.borderConsent]: isConsentHeading,
						[classes.borderPublicComment]: isPublicCommentHeading,
						[classes.selected]: isSelected,
					})}
					data-cy={`minutes-${item.guid}`}
				>
					<LiveMeetingRadioButton
						value={item.guid}
						label={fields.Name.Value}
						selected={isSelected}
						handleSelect={handleSelect}
						isMinutesV2
					/>
					<div
						className={clsx(classes.item, classes.liveMeetingItem, classes.rightBorder, {
							[classes.itemHeading]: isHeading,
							[classes.itemItem]: isItem,
							[classes.itemRecommendation]: isResolution,
							[classes.bottomBorder]: addBottomBorderToThis,
							[classes.itemSelected]: isSelected && (isMemberOnlyHeading || isConsentHeading || isPublicCommentHeading),
						})}
					>
						<div className={clsx(classes.textContainer)}>
							{(isHeading || isItem) && fields.Number.Value.length > 0 && <div className={classes.bullet}>{fields.Number.Value}</div>}
							{isResolution && (
								<div className={classes.motionIcon} data-icon="recommendation">
									<Icon name="recommendation" color={blackColor[1]} />
								</div>
							)}
							<div className={classes.itemText}>
								<Box
									className={clsx(classes.editorFieldContent, classes.fieldContentMargin)}
									data-fieldname={item.guid}
									ref={(el) => (elementsRef.current[elementsIndex] = el)}
								/>
								{isResolution && (
									<Motion
										key={`motion${item.guid}`}
										motion={item}
										meeting={meeting}
										rollCall={rollCall}
										otherRollCallTypes={otherRollCallTypes}
										additionalUsers={additionalUsers}
										minutesItems={minutesItems}
										selected={isSelected ? selected : undefined}
										alwaysUseRecordedVote={meeting.alwaysUseRecordedVote}
										votingData={votingData}
										onlineVoters={onlineVoters}
										votingSettings={votingSettings}
										adoptUpdating={adoptUpdating}
										forceUpdate={forceUpdate}
										showSignIn={showSignIn}
										handleUpdateMotion={handleUpdateMotion}
										adoptPublishPreviousMinutes={adoptPublishPreviousMinutes}
										isSelected={isSelected || (active && active.includes(item.guid))}
										signalRClient={signalRClient}
									/>
								)}
								{!meeting.isReadOnly && (active === item.guid || active === `${item.guid}-text`) && editorFieldTextDeleted && (
									<Box
										className="description-container"
										mr={6}
										mt={1}
										tabIndex={0}
										onClick={() => {
											addText(item.guid);
										}}
										onKeyPress={(e) => {
											if (e.key === "Enter" || e.key === " ") {
												addText(item.guid);
												e.preventDefault();
												e.stopPropagation();
											}
										}}
									>
										<Typography component="div">{t("addDescription")}</Typography>
									</Box>
								)}

								<ListItem
									disableGutters
									className={clsx(classes.descriptionContainer, { [classes.editorFieldHide]: editorFieldTextDeleted })}
								>
									<Box
										className={classes.editorFieldDescriptionContent}
										data-fieldname={`${item.guid}-text`}
										ref={(el) => (elementsRef.current[elementsIndex + 1] = el)}
									/>
									<ListItemSecondaryAction
										classes={{ root: "section-overflow-trash" }}
										className={active === `${item.guid}-text` && !editorFieldTextDeleted ? "" : classes.editorFieldHide}
										onClick={() => {
											removeText(item.guid);
										}}
									>
										<IconButton classes={{ root: "content-well-icon-button" }} size="large">
											<Icon name="trash" color={blackColor[1]}></Icon>
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
								{(isSelected || (active && active.includes(item.guid))) && (
									<Box
										className={classes.buttons}
										mt={
											!meeting.isReadOnly && editorFieldTextDeleted
												? isSelected || active === item.guid || active === `${item.guid}-text`
													? 2
													: 0
												: 2
										}
									>
										<div className={clsx(classes.buttonsLeft, classes.startAlign)}>
											<ButtonWithTooltip
												className={classes.thinButton}
												primary
												variant="outlined"
												title={t("tooltips.addNewMotion")}
												onClick={() => {
													if (!item || (item && !isMotionEmpty())) {
														handleAddMotion(item);
													}
												}}
												dataCy={`add-new-motion-${item.guid}`}
												aria-label={t("tooltips.addNewMotion")}
											>
												{`+ ${t("motions.panel.title")}`}
											</ButtonWithTooltip>
											{policyEnabled &&
												!meeting.isReadOnly &&
												(active === item.guid || active === `${item.guid}-text`) &&
												isPolicyMotion &&
												votingPassed && (
													<>
														<ButtonWithTooltip
															className={classes.thinButton}
															variant="outlined"
															color="primary"
															primary
															onClick={(e) => {
																handleToggleMenuForPolicy(e, "publish");
															}}
															title={"Publish"}
															data-cy="publishPolicy"
															style={{ marginLeft: "5px" }}
														>
															{"Publish"}
														</ButtonWithTooltip>
														<ButtonWithTooltip
															variant="outlined"
															className={classes.thinButton}
															color="primary"
															primary
															title={"Select Policy publish option"}
															aria-controls="split-button-menu"
															aria-expanded="true"
															aria-label="select publish action"
															aria-haspopup="menu"
															tooltipPlacement="bottom"
															onClick={(e) => {
																setOpenMenu(!openMenu);
																setSelectedMenuOption({ ["publish"]: e.currentTarget });
															}}
															style={{ marginLeft: "3px" }}
														>
															<Icon name="expand-down" color={""} size="15px" />
														</ButtonWithTooltip>

														{openMenu && selectedMenuOption.publish && (
															<NonModalMenu
																id="publishunpublishmenuoption"
																anchorEl={selectedMenuOption.publish}
																keepMounted
																open={true}
																onClose={() => {}}
																options={publishPolicyMenuOptions}
																position="bottom-end"
															/>
														)}
													</>
												)}
											{showAdoptPublishPreviousMinutes && (
												<div style={{ margin: "0 8px" }}>
													<div>
														<ButtonWithTooltip
															className={clsx(classes.button, classes.adoptPublish, classes.thinButton)}
															primary
															variant="outlined"
															title={t("meetings:tooltips.adoptPublish")}
															onClick={() => {
																adoptPublishPreviousMinutes(minutesToAdoptMeetingId);
															}}
															data-cy="adopt-publish"
														>
															{t("meetings:buttons.adoptPublish")}
														</ButtonWithTooltip>
													</div>
													<div>
														<Link
															className={clsx("cursor-pointer", classes.signAdoptLink)}
															underline="always"
															href={`${API_HOST}/home/meeting/adopt/${minutesToAdoptMeetingId}/minutes?liveMeeting=${meeting.id}`}
														>
															{t("meetings:buttons.goToSignAdopt")}
														</Link>
													</div>
												</div>
											)}
										</div>
									</Box>
								)}
							</div>
						</div>
						{videoExists && <TimeStampItem item={item} isSelected={isSelected} presenting={presenting} isMinuteV2 />}
					</div>
				</div>

				<LiveMeetingAttachments
					attachments={attachments}
					item={item}
					isClosedMeeting={isClosedMeeting}
					isMemberOnlyHeading={isMemberOnlyHeading}
					isConsentHeading={isConsentHeading}
					isPublicCommentHeading={isPublicCommentHeading}
					addBottomBorder={addBottomBorder}
					selected={selected && !isSelected ? selected : undefined}
					noMargin
					handleSelect={handleSelect}
				/>
			</>
		)
	);
};

LiveMeetingItem.defaultProps = {
	isClosedMeeting: false,
	isMemberOnlyHeading: false,
	isConsentHeading: false,
	isPublicCommentHeading: false,
	isSubHeading: false,
	addBottomBorder: false,
	hasNotes: false,
	handleSelect: undefined,
};

export default React.memo(LiveMeetingItem);
