import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useDroppable } from "@dnd-kit/core";

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, ListItem, ListItemIcon, ListItemText } from "@mui/material";

import { blackColor } from "atlas/assets/jss/shared";
import tableOfContentsStyle from "assets/jss/components/tableOfContentsStyle";
import dragAndDropStyle from "atlas/assets/jss/components/dragAndDropStyle";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import Icon from "atlas/components/Icon/Icon";
import { Check } from "components/Icons";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import { EditorFunctionsContext } from "contexts/EditorFunctions/EditorFunctionsContext";
import telemetryAddEvent from "utils/telemetryAddEvent";
import TOCAttachments from "./TOCAttachments";
import TOCPrimaryText from "components/Meeting/TOCPrimaryText";

const useTableOfContentsStyles = makeStyles(tableOfContentsStyle);
const useDragAndDropStyles = makeStyles(dragAndDropStyle);

const TOCRecommendation = (props) => {
	const {
		active = {},
		recommendation,
		toggleTableOfContent,
		isConsentSection,
		isPublicCommentSection,
		isMemberOnlySection,
		isSmallDevice,
		isHeadingAction,
		parserOptions,
		isClosedMeeting,
		idPrefix = "outline-agenda",
		button,
		checkbox: { className: checkboxClassName, checked, onCheckChange } = {},
		checkbox,
		lineLimit,
		hideAttachments,
		canDrag,
		dragPlaceholder,
		dragPresentational,
		canDrop,
		handleCancelMoveCopy,
		telemetryPage,
	} = props;
	const { t } = useTranslation("agendaMenu");
	const { setActiveItem = () => {} } = useContext(EditorFunctionsContext) || {};
	const tableOfContentsClasses = useTableOfContentsStyles({ lineLimit });
	const dragAndDropClasses = useDragAndDropStyles();
	const ListItemComponent = canDrag ? Draggable : ListItem;
	const dragProps = canDrag ? { dragId: recommendation.guid, dragComponent: ListItem } : {};
	const { setNodeRef } = canDrop
		? useDroppable({
				id: recommendation.guid,
		  })
		: {};

	return (
		<>
			<ListItemComponent
				id={!dragPresentational ? `${idPrefix}-${recommendation.guid}` : undefined}
				className={clsx(tableOfContentsClasses.listItem, {
					[dragAndDropClasses.dragPlaceholder]: dragPlaceholder,
					[tableOfContentsClasses.copyMoved]: recommendation.copied,
					[tableOfContentsClasses.closedChanged]:
						recommendation.original && recommendation.original.closed && !recommendation.fields.Closed.Value,
				})}
				button={button}
				component={dragPresentational ? "div" : "li"}
				disableGutters
				divider={!dragPresentational}
				classes={{
					root: clsx("outline-item-secondary", {
						"outline-item-consent-recommendation": isConsentSection,
						"outline-item-public-comment-recommendation": isPublicCommentSection && !isConsentSection,
						"outline-heading-recommendation": isHeadingAction && !isPublicCommentSection && !isConsentSection,
						"outline-item-recommendation": !isHeadingAction && !isPublicCommentSection && !isConsentSection,
						"member-only-section": isMemberOnlySection,
						"consent-section-outline": isConsentSection,
						"public-comment-section-outline": isPublicCommentSection,
						"closed-meeting": isClosedMeeting,
					}),
				}}
				onClick={
					button
						? () => {
								telemetryAddEvent(`${telemetryPage} - Outline navigation`);

								setActiveItem(recommendation.guid);
								if (isSmallDevice) {
									toggleTableOfContent();
								}
						  }
						: undefined
				}
				selected={active.selected === recommendation.guid}
				{...dragProps}
				ref={setNodeRef}
			>
				{(canDrag || dragPresentational) && <DragHandle />}
				{checkbox && (
					<Checkbox
						className={checkboxClassName}
						checkedIcon={<Check fontSize="small" color="primary" />}
						checked={checked}
						onChange={(e) => onCheckChange(e, recommendation)}
					/>
				)}
				<ListItemIcon>
					<Icon name="recommendation" />
				</ListItemIcon>
				<ListItemText
					className={clsx({
						[tableOfContentsClasses.limitLines]: Boolean(lineLimit),
					})}
					primaryTypographyProps={{ variant: "h5" }}
					primary={
						<TOCPrimaryText
							text={recommendation.fields.Name.Value}
							parserOptions={parserOptions}
							showTruncationTooltip={Boolean(lineLimit) && !dragPresentational}
							truncationThreshold={50}
						></TOCPrimaryText>
					}
				/>
				{recommendation.copied && (
					<div className={tableOfContentsClasses.cancelCopyMove}>
						<AccessibleIconButton
							iconName="remove"
							iconColor={blackColor[1]}
							tooltipText={t("meetings:copyMoveItemDialog.tooltips.cancelCopyMove")}
							onClick={() => handleCancelMoveCopy(recommendation.guid)}
							dataCy={`cancel-${recommendation.guid}`}
						/>
					</div>
				)}
			</ListItemComponent>
			{!hideAttachments && (
				<TOCAttachments
					attachments={recommendation.attachments}
					item={recommendation}
					itemGuid={recommendation.guid}
					isMemberOnlySection={isMemberOnlySection}
					isConsentSection={isConsentSection}
					isPublicCommentSection={isPublicCommentSection}
					toggleTableOfContent={toggleTableOfContent}
					telemetryPage={telemetryPage}
				/>
			)}
		</>
	);
};

export default TOCRecommendation;
