export default {
	title: "Settings: General",
	subtitle: {
		boxcastSettings: "Boxcast settings",
		defaultLetterheadTemplate: "Default letterhead template",
		organization: "Organization ",
		organizationEmailSettings: "Organization email settings",
		policyURL: "Policy URL",
		setTimeZone: "Set time zone",
		youTubeSettings: "YouTube settings",
	},
	headings: {
		organizationName: "Organization name",
		organizationAddress: "Organization address",
		contactUsLink: "Contact us link",
		sendEmailYourself: "Send yourself a test email",
		policyURL: "Policy URL",
		siteTimeZone: "Site time zone",
		setNotificationHour: "Notification hour",
		setNotificationPeriod: "Notification period",
		apiKey: "API Key",
		apiSectet: "API Secret",
	},
	buttons: {
		addCredentials: "Add credentials",
		save: "Save",
		send: "Send",
		testCredentials: "Test credentials",
		updateCredentials: "Update credentials",
		uploadTemplate: "Upload Template",
		validate: "Validate credentials",
	},
	tooltips: {
		add: "Add Credentials",
		defaultLetterheadTemplateMenu: "View the default letterhead template menu options",
		deleteLetterheadTemplate: "Delete the default letterhead template",
		replaceLetterheadTemplate: "Replace the default letterhead template",
		save: "Save settings",
		sendText: "Send Email",
		test: "Test Credentials",
		update: "Update Credentials",
		uploadTemplate: "Upload a default letterhead template",
		validate: "Validate Credentials",
	},
	errorMessage: {
		organizationName: "Enter valid organization name",
		organizationAddress: "Enter valid organization address",
		emailBcc: "Enter valid email address",
		policyURL: "Enter valid link",
		contactUsUrl: "Enter valid url",
		testEmailTo: "Enter valid email address",
	},
	snackbar: {
		success: {
			defaultLetterheadTemplateDelete: "Successfully deleted the default letterhead template",
			defaultLetterheadTemplateUpload: "Successfully uploaded the default letterhead template",
			emailSend: "Email Send Successfully",
			save: "Settings saved successfully",
			youtube: "Valid YouTube credentials exist",
		},
		error: {
			defaultLetterheadTemplateDelete: "Unable to delete the default letterhead template",
			defaultLetterheadTemplateUpload: "Unable to upload the default letterhead template",
			youtube: "Invalid Youtube credentials",
		},
	},
	editor: {
		title: "Email disclaimer",
	},
	hour: "Notification hour",
	boxcast: "Validate the credentials to configure the boxcast credentials",
	youtube: {
		step1: "- Visit your",
		step2: "- Download the JSON file",
		step3: "- Upload JSON credential below",
		googleConsole: "Google Developer Console",
	},
};
